import React, { useEffect, useState } from "react";
import _ from "lodash";
import { IMAGE_URL } from "../../../core/Constant";
import { getUserId } from "../../../core/UtilMethods";
import moment from "moment";

const formatDate = (date) => moment(date).format("MMM D, h:mm A");

export const TicketReplyCardlet = ({
  replyData = {},
}) => {
  const currentUserId = getUserId();
 
  const { reply, createdAt, reply_by } = replyData;
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  
  useEffect(() => {
    setIsCurrentUser(currentUserId == reply_by);
  }, [replyData]);

  return isCurrentUser ? (
    <div className="col-start-6 col-end-13 rounded-lg">
      <div className="flex items-center justify-start flex-row-reverse">
        <div className="relative bg-darkGray text-sm py-2 px-4 shadow rounded-l">
          <span className="text-textGrey font-medium font-DM text-[14px]">
            {reply}
          </span>
          <span
            className="text-textGrey font-DM text-[10px] float-right mt-1 ml-2 opacity-70"
          >
            {formatDate(createdAt)}
          </span>
        </div>
      </div>
    </div>
  ) : (
    <div className="col-start-1 col-end-8 rounded-lg">
      <div className="flex flex-row items-center">
        <div className="relative text-sm bg-lightGray py-2 px-4 shadow rounded-l">
          <span className="text-textGrey font-medium font-DM text-[14px]">
            {reply}
          </span>
          <span
            className="text-textGrey font-DM text-[10px] float-right mt-1 ml-2 opacity-70"
          >
            {formatDate(createdAt)}
          </span>
        </div>
      </div>
    </div>
  );
};
