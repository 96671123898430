export const UpdateCVIcon = ({ isSelected = false }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0164 2.30431C14.0164 1.21631 13.1204 0.320312 12.0324 0.320312H6.91238C6.59238 0.320312 6.33638 0.384313 6.14438 0.512313C5.95238 0.576313 5.69638 0.704313 5.56838 0.832313L2.62438 3.71231C2.43238 3.84031 2.30438 4.03231 2.17638 4.28831C2.04838 4.54431 1.98438 4.80031 1.98438 5.12031V13.6323C1.98438 14.7203 2.88037 15.6163 3.96838 15.6163H12.0324C13.1204 15.6163 14.0164 14.7203 14.0164 13.6323V2.30431ZM8.00038 4.60831C8.96038 4.60831 9.72838 5.37631 9.72838 6.33631C9.72838 7.29631 8.96038 8.06431 8.00038 8.06431C7.04038 8.06431 6.27238 7.29631 6.27238 6.33631C6.27238 5.37631 7.04038 4.60831 8.00038 4.60831ZM10.3044 12.1603H6.14438C5.05638 12.1603 4.67238 11.6483 4.92838 11.0083C5.24838 10.1763 5.88838 9.53631 6.65637 9.21631C6.78438 9.15231 6.91238 9.15231 7.04038 9.21631C7.68038 9.47231 8.44838 9.40831 9.02438 9.21631C9.15238 9.15231 9.28038 9.15231 9.40838 9.21631C10.1764 9.60031 10.8164 10.2403 11.1364 11.0723C11.3284 11.5843 10.8804 12.1603 10.3044 12.1603Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
    </svg>
  );
};
