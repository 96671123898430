import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DatePickerComponent = ({selectedDate, onChnage = () =>{}, label='label',  isdisabled = false,error = false,
errortxt = "",}) => {
  // const renderYearContent = (year) => {
  //   const tooltipText = `Tooltip for year: ${year}`;
  //   return <span title={tooltipText}>{year}</span>;
  // };
  return (
    <div className="input-field-wrap">
       <label className="input-label-txt">{label}</label>
      <DatePicker
       dateFormat="yyyy/MM/dd"
      className={ isdisabled
        ? "input-field-disabled"
        : error
        ? "error-input-field"
        : "input-field-basic"}
        selected={selectedDate}
        onChange={onChnage}
        // renderYearContent={renderYearContent}
      />
    </div>
  );
};
