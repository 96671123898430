import React from "react";
import { IMAGES } from "../../../assets";
import { ButtonComponent, ChipCardlet, FavouriteButton } from "../../atoms";
import { Avatar } from "flowbite-react";

export const RecruiterCandidateCard = ({text = "label", onClose, close = false }) => {
    return(
    
        <div class="rounded-xl bg-white  overflow-hidden px-4 py-6">

        <div className="flex flex-row justify-between items-center my-2">
            <div className="flex flex-row items-center">
                <Avatar style={{ width: '54px', height: '54px' }} src="https://docs.material-tailwind.com/img/face-5.jpg" alt="avatar" />
                <div class={'flex flex-col ml-2'}>
                    <span class="text-darkBlue font-DM text-[16px]">Senior UX Designer</span>
                    <span class="text-textlightColor font-DM text-[11px]">UBIsoft.inc, Toronto, Canada</span>
                </div>
            </div>
            <FavouriteButton favourite={false} />
        </div>
        <div className="flex flex-row flex-wrap m4-4 flex-grow-0">
            <ChipCardlet text="UI/UX" />
            <ChipCardlet text="UI/UX" />
            <ChipCardlet text="Design Thinking" />
            <ChipCardlet text="Design Thinking" />
            <ChipCardlet text="Design Thinking" />
            <ChipCardlet text="UI/UX" />
            <ChipCardlet text="UI/UX" />
            <ChipCardlet text="UI/UX" />
            <ChipCardlet text="UI/UX" />
        </div>
        <div class='w-full'>
            <div class="flex items-center flex-row w-full mt-[10px] justify-between max-h-[25px]">
                <span class="text-primaryColor font-DM text-[12px]">Remote Only</span>
                <div class='max-h-[35px]'>
                    <ButtonComponent width="w-[147px]" height="h-[33px]" text="View now" fontsize="text-[10px]" />
                </div>
            </div>
        </div>
    </div>)
}