import { useEffect } from "react";
import _ from "lodash";
import { IMAGE_URL } from "../../../core/Constant";

export const ResumeCard = ({details}) => {
  useEffect(()=>{
    console.log("first", details)
  },[])

  const onClickdownload = () => {
    window.open(IMAGE_URL + _.get(details,'link'));
  }
  return (
    <div className="w-full cursor-pointer" onClick={onClickdownload}>
      <div
        className="relative bg-no-repeat bg-cover bg-center  border-2 border-solid border-darkGray rounded-md  h-80"
        style={{
          backgroundImage: `url(${IMAGE_URL + _.get(details,'thumbnail_image')})`,
        }}
      >
        {/* <div class="absolute right-0 p-3">
          <FavouriteButton favourite={false} />
        </div> */}
      </div>
      <div>
        <p class="text-labelColor font-DM text-md text-center font-semibold line-clamp-2 pt-1 ps-1 pe-1">
          {_.get(details,'name')}
        </p>
      </div>
    </div>
  );
};
