export const setToken = (value) => {
  localStorage.setItem("token", value);
};

export const setUserId = (value) => {
  localStorage.setItem("user_id", value);
};

export const setUserType = (value) => {
  localStorage.setItem("user_type", value);
};

export const setCurrentUserDetails = (value) => {
  localStorage.setItem("current_user_details", value);
};

export const setLoginCount = (count) => {
  localStorage.setItem("login_count", count);
};

export const setLinkdinUser = (value) => {
  localStorage.setItem("linkdin_token", value);
};
export const setOnboardingDetails = (value) => {
  localStorage.setItem("onboarding_details", value);
};
export const setOnboardingStep = (value) => {
  localStorage.setItem("onboard_step", value);
};

export const getToken = () => {
  return localStorage.getItem("token");
};
export const getUserId = () => {
  return localStorage.getItem("user_id");
};
export const getUserType = () => {
  return localStorage.getItem("user_type");
};

export const getCurrentUserDetails = () => {
  return JSON.parse(localStorage.getItem("current_user_details"));
};

export const getLoginCount = () => {
  return localStorage.getItem("login_count");
};

export const getLinkdinUser = () => {
  return localStorage.getItem("linkdin_token");
};

export const getOnboardingDetails = () => {
  return localStorage.getItem("onboarding_details");
};
export const getOnboardingStep = () => {
  return localStorage.getItem("onboard_step");
};
