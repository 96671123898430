import React, { useState } from "react";
import { CreateJobFormOne } from "../../../components/organism/Forms/CreateJobFormOne";
import { CreateJobFormTwo } from "../../../components/organism/Forms/CreateJobFormTwo";
import { TabComponent } from "../../../components/molecule/Tabs/TabComponent";
import { Actions } from "../../../core/modules/Actions";
import { connect, useSelector } from "react-redux";
import { onToastNotify } from "../../../core/Constant";

const CreateJobScreen = ({ getskillList }) => {
    const [activeTab, setActiveTab] = useState('Job Details');
    const createJob = useSelector((state) => state.home.get('createJob'));

    const tabList = ['Job Details', 'Job Description'];

    const validateFormOne = () => {
        const isValid = createJob?.title && createJob?.work_type && createJob?.location && createJob?.job_type;
        return isValid;
    };

    const handleTabChange = (tab) => {
        if (activeTab == 'Job Details' && tab == 'Job Description') {
            if (validateFormOne()) {
                setActiveTab(tab);
            } else {
                  onToastNotify("Please fill the all details and click the continue button.");
            }
        } else {
            setActiveTab(tab);
        }
    };

    const tabContent = {
        'Job Details': <CreateJobFormOne setActiveTab={handleTabChange}  />,  
        'Job Description': <CreateJobFormTwo setActiveTab={setActiveTab}/>,
    };

    return (
        <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
            <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12">
                <div className="bg-white p-4">
                    <p className="text-2xl text-darkBlue font-semibold font-DM">Create your job post</p>
                    <TabComponent 
                        tabList={tabList} 
                        tabContent={tabContent} 
                        activeTab={activeTab} 
                        setActiveTab={handleTabChange} 
                    />
                </div>
            </div>
        </div>
    );
};

export default connect(
    state => ({
      resetPasswordState: state.auth.get("resetPasswordState"),
    }),
    {
        getskillList: Actions.common.getskillList,
  
  
    },
  )(CreateJobScreen);
