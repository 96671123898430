export const JobOffers = ({ isSelected = false }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_2467)">
        <path
          d="M9.55501 4.51562C9.20336 4.51562 8.91797 4.23023 8.91797 3.87858V2.92301H6.36979V3.87858C6.36979 4.23023 6.0844 4.51562 5.73275 4.51562C5.3811 4.51562 5.0957 4.23023 5.0957 3.87858V2.92301C5.0957 2.22035 5.66713 1.64893 6.36979 1.64893H8.91797C9.62063 1.64893 10.1921 2.22035 10.1921 2.92301V3.87858C10.1921 4.23023 9.90666 4.51562 9.55501 4.51562Z"
          fill={isSelected ? '#4283E5':'#2B3674'}
        />
        <path
          d="M8.09634 10.1726C7.98168 10.2172 7.81604 10.2491 7.64404 10.2491C7.47204 10.2491 7.30641 10.2172 7.15352 10.1599L-0.000488281 7.77734V12.638C-0.000488281 13.6063 0.783076 14.3899 1.75138 14.3899H13.5367C14.505 14.3899 15.2886 13.6063 15.2886 12.638V7.77734L8.09634 10.1726Z"
          fill={isSelected ? '#4283E5':'#2B3674'}
        />
        <path
          d="M15.2886 5.31193V6.77076L7.79693 9.26798C7.74597 9.28709 7.69501 9.29346 7.64404 9.29346C7.59308 9.29346 7.54212 9.28709 7.49115 9.26798L-0.000488281 6.77076V5.31193C-0.000488281 4.34362 0.783076 3.56006 1.75138 3.56006H13.5367C14.505 3.56006 15.2886 4.34362 15.2886 5.31193Z"
          fill={isSelected ? '#4283E5':'#2B3674'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2467">
          <rect
            width="15.2891"
            height="15.2891"
            fill="white"
            transform="translate(-0.000488281 0.375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
