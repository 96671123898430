import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonComponent,
  DatePickerComponent,
  DropdownComponentCandidate,
  TagComponent,
  TextFieldComponent,
} from "../../../components/atoms";
import { TypeAheadSearch } from "../../../components/atoms/Inputs/TypeAhead/TypeAheadSearch";
import { convertStringDate, getText } from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";
import Swal from "sweetalert2";

export default ({}) => {
  const dispatch = useDispatch();
  // const [typeAheadError, setTypeAheadError] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [error, setError] = useState(false);
  // const [years, setYears] = useState([]);

  const [startYear, setStartYear] = useState(new Date());
  const [endYear, setEndYear] = useState(new Date());
  const [jobsList, setJobsList] = useState([]);

  const [studyfield, setStudyfield] = useState("");
  const [school, setSchool] = useState("");
  const [course, setCourse] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  // const [markPresentDate, setMarkPresentDate] = useState(false);
  const [first_name, setFirstName] = useState("");

  const candidateDetails = useSelector((state) =>
    state.profile.get("candidateDetails")
  );

  const jobType = [
    { option: "Full-Time", value: "Full-Time" },
    { option: "Part-Time", value: "Part-Time" },
    { option: "Contract", value: "Contract" },
    { option: "Freelance", value: "Freelance" },
    { option: "Internship", value: "Internship" },
  ];
  const skillList = useSelector((state) => state.common.get("skillData"));
  const jobsTitiles = useSelector((state) => state.common.get("jobRoleList"));

  useEffect(() => {
    dispatch(Actions.profile.getCandiateDetails());
    dispatch(Actions.onboardingProcess.getAllLocations());
    dispatch(Actions.common.getskillList());
    dispatch(Actions.common.getjobRoleList());
  }, [dispatch]);

  useEffect(() => {
    // const yearslist = getListOfYears();
    // setYears(yearslist);

    const titles = _.map(jobsTitiles, (skill) => {
      return {
        option: _.get(skill, "name"),
        value: _.get(skill, "id"),
      };
    });
    // setJobsList(titles);
    console.log("candidateDetails", candidateDetails);
    const selectedSkills = _.map(
      _.get(candidateDetails, "UserSkills"),
      (item) => {
        return {
          id: _.get(item, "skill_id"),
          name: _.get(item, "skill_name"),
        };
      }
    );
    setSelectedSkills(selectedSkills);
    setFirstName(_.get(candidateDetails, "first_name"));
    setStudyfield(_.get(candidateDetails, `UserEducation[${candidateDetails?.UserEducation?.length - 1}].study_feild`));
    setSelectedJobType(_.get(candidateDetails, "candidateDetails.job_type"));
    setSchool(_.get(candidateDetails, `UserEducation[${candidateDetails?.UserEducation?.length - 1}].institution`));
    console.log(
      "candidateDetails...",
      _.get(candidateDetails, `UserEducation[${candidateDetails.UserEducation?.length - 1}].start_date`)
    );
    if (
      _.get(candidateDetails, `UserEducation[${candidateDetails.UserEducation?.length - 1}].start_date`) !== undefined &&
      _.get(candidateDetails, `UserEducation[${candidateDetails.UserEducation?.length - 1}].end_date`) !== undefined
    ) {
      setStartYear(
        convertStringDate(
          new Date(_.get(candidateDetails, `UserEducation[${candidateDetails.UserEducation?.length - 1}].start_date`))
        )
      );
      setEndYear(
        convertStringDate(
          new Date(_.get(candidateDetails, `UserEducation[${candidateDetails.UserEducation?.length - 1}].end_date`))
        )
      );
    } else {
    }

    setCourse(_.get(candidateDetails, `UserEducation[${candidateDetails.UserEducation?.length - 1}].degree`));
  }, [jobsTitiles, skillList, candidateDetails]);

  const selectedItem = (item) => {
    _.forEach(candidateDetails,((arrItem) => {
      if(_.get(arrItem,'skill_id') == _.get(item,'id')){
        setSelectedSkills([...selectedSkills, {skill_id:_.get(item,'id'), name:_.get(item,'name')}]);
      }else{
        setSelectedSkills([...selectedSkills, { name:_.get(item,'name')}]);
      }

    }))
    
    setError(false);
  };

  const closeSelectedTag = (id) => {
    const arr = _.filter(selectedSkills, (item) => _.get(item, "id") !== id);
    setSelectedSkills(arr);
  };

  const getEndYear = (date) => {
    setEndYear(convertStringDate(date));
  };

  const getStartYear = (date) => {
    setStartYear(convertStringDate(date));
  };

  const getSelectedJobType = (jobType) => {
    setSelectedJobType(_.get(jobType, "value"));
  };

  const onChangeSchool = (value) => {
    const text = getText(value);
    setSchool(text);
  };
  const onChangeCourse = (value) => {
    const text = getText(value);
    setCourse(text);
  };

  const onChangeField = (value) => {
    const text = getText(value);
    setStudyfield(text);
  };

  // const getFieldOfStudy = (value) => {
  //   setStudyfield(_.get(value, "option"));
  // };


  const updateCandidateDetails = () => {
    if (
      selectedJobType !== undefined &&
      selectedSkills !== undefined &&
      course !== undefined &&
      studyfield !== undefined &&
      school !== undefined &&
      (startYear !== endYear ? true : false)
    ) {
      
      dispatch(
        Actions.profile.updateCandidate({
          id: _.get(candidateDetails,'UserEducations[0].id'),
          job_type: selectedJobType,
          first_name: first_name,
          user_skill: selectedSkills,
          user_education: [
            {
              degree_name: course,
              study_field: studyfield,
              school_name: school,
              start_date: convertStringDate(startYear),
              end_date: convertStringDate(endYear),
            },
          ],
        })
      );
    } else {
      Swal.fire({
        toast: true,
        text: "Please fill the empty Fields.",
        icon: "error",
      });
    }
  };

  return (
    <div className="subscreen-container">
      <div className="flex flex-col ">
        <span class="text-lableColor font-DM text-3xl font-bold">
          Skill and Requirment
        </span>
      </div>
      <div className="grid grid-flow-row-dense grid-cols-1 gap-2">
        <div className="">
          <DropdownComponentCandidate
            defaultOption={selectedJobType}
            dropdownOptions={jobType}
            label="Job Type"
            getSelectedOption={getSelectedJobType}
          />
        </div>

        <div className="pb-3">
          <TypeAheadSearch
            error={error}
            errortext="Please select a Core Skills"
            listData={skillList}
            selectedItem={selectedItem}
            isLabelVisible={true}
            label="Core Skills"
            placeholder={"Type and select Core Skills"}
            showOnlyName={true}
          />
          <div className="grid grid-flow-row-dense grid-cols-1 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 gap-y-3">
            {_.map(selectedSkills, (skill, index) => {
              return (
                <div className="col-span-1" key={index + 1}>
                  <TagComponent
                    text={_.get(skill, "name")}
                    id={_.get(skill, "id")}
                    onClose={closeSelectedTag}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="">
          <p className="sub-heading">Educational qualifications</p>
        </div>

        <div className="">
          <TextFieldComponent
            label="Degree or course name"
            placeholder="Type and here"
            value={course}
            onChange={(e) => onChangeCourse(e)}
          />
        </div>

        <div className="">
          <TextFieldComponent
            value={school}
            label="University or school"
            placeholder="Type and here"
            onChange={(e) => onChangeSchool(e)}
          />
        </div>

        <div className="grid grid-flow-row-dense grid-cols-8 gap-2">
          <div className="col-span-full 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3">
          <TextFieldComponent
            value={studyfield}
            label="Field of study"
            placeholder="Type and here"
            onChange={(e) => onChangeField(e)}
          />
          </div>
          <div className="col-span-full 2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-2">
            {/* <DropdownComponentCandidate
              dropdownOptions={years}
              label="Start date"
              defaultOption={startYear}
              getSelectedOption={getStartYear}
            /> */}

            <DatePickerComponent
              label="Start Date"
              selectedDate={startYear}
              onChnage={(date) => getStartYear(date)}
            />
          </div>
          <div className="col-span-full 2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-2">
            <div className="grid grid-flow-row-dense grid-rows-2">
              <div className="">
                {/* <DropdownComponentCandidate
                  defaultOption={endYear}
                  label="End date"
                  dropdownOptions={years}
                  getSelectedOption={getEndYear}
                /> */}
                <DatePickerComponent
                  label="End Date"
                  selectedDate={endYear}
                  onChnage={(date) => getEndYear(date)}
                />
              </div>
              {/* <div className="">
                <CheckboxComponent
                  text={"Present"}
                  onChange={(e) => checkPresent(e)}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="grid grid-flow-row-dense grid-cols-1 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 gap-2">
          {/* <div className="">
            <ButtonComponent isPrimary={false} text="Cancel" />
          </div> */}
          <div className="">
            <ButtonComponent
              isPrimary={true}
              text="Save updates"
              onClick={updateCandidateDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
