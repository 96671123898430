export const InviteFriendsIcon = ({ isSelected = false }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 9.03125C10.8472 9.03125 12.75 7.12846 12.75 4.78125C12.75 2.43404 10.8472 0.53125 8.5 0.53125C6.15279 0.53125 4.25 2.43404 4.25 4.78125C4.25 7.12846 6.15279 9.03125 8.5 9.03125Z"
          fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M2.65625 16.4689H9.2225C8.28966 15.7212 7.67194 14.6502 7.49185 13.4684C7.31177 12.2865 7.58249 11.0802 8.25031 10.0886C6.87691 10.0204 5.58282 9.4247 4.63781 8.42578C3.57845 8.87298 2.67435 9.62243 2.03847 10.5805C1.40259 11.5386 1.06313 12.6628 1.0625 13.8127V14.8752C1.06376 15.2975 1.23208 15.7021 1.53069 16.0007C1.8293 16.2993 2.23395 16.4676 2.65625 16.4689Z"
          fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M8.5 12.75C8.5 13.4855 8.7181 14.2045 9.12672 14.816C9.53534 15.4276 10.1161 15.9042 10.7956 16.1857C11.4752 16.4671 12.2229 16.5408 12.9442 16.3973C13.6656 16.2538 14.3282 15.8996 14.8483 15.3796C15.3684 14.8595 15.7226 14.1969 15.866 13.4755C16.0095 12.7541 15.9359 12.0064 15.6544 11.3269C15.373 10.6474 14.8963 10.0666 14.2848 9.65797C13.6732 9.24935 12.9542 9.03125 12.2187 9.03125C11.2328 9.03237 10.2876 9.42453 9.59044 10.1217C8.89328 10.8188 8.50112 11.7641 8.5 12.75ZM12.75 11.6875V12.2187H13.2812C13.4221 12.2187 13.5573 12.2747 13.6569 12.3743C13.7565 12.474 13.8125 12.6091 13.8125 12.75C13.8125 12.8909 13.7565 13.026 13.6569 13.1257C13.5573 13.2253 13.4221 13.2812 13.2812 13.2812H12.75V13.8125C12.75 13.9534 12.694 14.0885 12.5944 14.1882C12.4948 14.2878 12.3596 14.3437 12.2187 14.3437C12.0779 14.3437 11.9427 14.2878 11.8431 14.1882C11.7435 14.0885 11.6875 13.9534 11.6875 13.8125V13.2812H11.1562C11.0154 13.2812 10.8802 13.2253 10.7806 13.1257C10.681 13.026 10.625 12.8909 10.625 12.75C10.625 12.6091 10.681 12.474 10.7806 12.3743C10.8802 12.2747 11.0154 12.2187 11.1562 12.2187H11.6875V11.6875C11.6875 11.5466 11.7435 11.4115 11.8431 11.3118C11.9427 11.2122 12.0779 11.1562 12.2187 11.1562C12.3596 11.1562 12.4948 11.2122 12.5944 11.3118C12.694 11.4115 12.75 11.5466 12.75 11.6875Z"
          fill={isSelected ? '#4283E5':'#2B3674'}
      />
    </svg>
  );
};
