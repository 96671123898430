import React, { useState } from "react";
import {
  ButtonComponent,
  DropdownComponent,
  StepperComponent,
  TextAreaComponent,
  TextFieldComponent,
} from "../../atoms";
import { IMAGES } from "../../../assets";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { getText } from "../../../core/Constant";


export const RecruiterStepTwo = ({ setUpdateCandidateStep = () => {} }) => {

  const [companySize,setCompanySize] = useState()
  const [aboutCompany,setAboutCompany] = useState(null)
  const [error, setError] = useState("");
  const [errorCompany,setErrorCompany] = useState()
  const dispatch = useDispatch();



    // Map the industryList to dropdown options
    const dropdownOptions = [
        {id:1,name:'1-10 Employees'},
        {id:2,name:'11-50 Employees'},
        {id:3,name:'51-250 Employees'},
        {id:3,name:'251 Employees and above'},
    ]






    


    const onsetAbout = (e) =>{
       const text = getText(e)
       setAboutCompany(text)
       setErrorCompany("")
    }

  
    const handleIndustrySelect = (option) => {
      setCompanySize(option);
      setError(""); // Clear any existing errors
    };

    const onClickCreateAccount = () => {
      if(aboutCompany &&  companySize){
       dispatch(Actions.auth.storeUserDetails({
        company_size: companySize.name,
        about:aboutCompany // Store the selected industry ID
       }));
       setUpdateCandidateStep(6);
      }
       if (!aboutCompany) {
         setErrorCompany("Tell About Your Company");
         return;
       }
       if (!companySize) {
         setError("Please select an Company Size.");
         return;
       }
   
     };


  
  return (
    <div className="sign-in-form-container">
      <div className="back-button-container">
        <StepperComponent totalSteps={3} currentStep={2} />
      </div>

      <div className="w-full 2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-2/3 ">
        <DropdownComponent
        dropdownOptions={dropdownOptions}
        onOptionSelected={handleIndustrySelect}
        error={!!error}
        errortxt={error}
        label="What is your company size? " />
      </div>

      <div className="w-full 2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-2/3 ">
        <TextAreaComponent
          label="Tell more about your company"
          placeholder="More details"
          value={aboutCompany}
          error={errorCompany}
          errortxt={errorCompany}
          onChange={onsetAbout}
        />
      </div>

      <div className="submit-button flex items-center gap-3 w-full 2xl:w-1/3 xl:w-1/3 lg:w-3/5 md:w-3/5">
        <ButtonComponent
          padding="p-2"
          text="Back"
          width="w-full"
          isPrimary={false}
          onClick={() => setUpdateCandidateStep(4)}
        />
        <ButtonComponent
          padding="p-2"
          text="Continue"
          width="w-full"
          isPrimary={true}
          onClick={onClickCreateAccount}
        />
      </div>
      <div>
        <p className="font-DM font-medium text-lableColor">
          Already have an account?
          <span className="text-primaryColor font-semibold cursor-pointer">
            Login
          </span>
        </p>
      </div>
    </div>
  );
};
