// Images
// import IMAGE_CREATE_ACCOUNT from "./assets_onboarding_screens/Left Side Image/With Text/Create_Account.png";
import JOB_IMAGE from "./images/job.png";
import BACKGROUND_IMAGE from "./images/Background.png";
import CV_IMAGE from "./images/CV.png";
import NAVBAR_IMG from "./images/NavLogo.png";
// Icons
import ICON_ONBOARDING_ARROW from "./assets_onboarding_screens/Icons/Arrow.svg";
import PROFILE_ICON from "./assets_Dashboard/Icons/Profile_icon.svg";
import EYE_ICON from "./assets_Dashboard/Icons/Eye_icon.svg";
import SUITCASE_ICON from "./assets_Dashboard/Icons/Suitcase_icon.svg";
import NEXT_ARROW_BLUE from "./assets_Dashboard/Icons/next_blue.svg";
import NEXT_ARROW_PURPLE from "./assets_Dashboard/Icons/next_purple.svg";
import NEXT_ARROW_GREEN from "./assets_Dashboard/Icons/next_green.svg";
import SUITCASE_BUTTON_ICON from "./button_icons/suitcase (1).svg";
import SAVED_BUTTON_ICON from "./button_icons/Layer 2.svg";
import GOOGLE_ICON from "./assets_onboarding_screens/Icons/Google.svg";
import MICROSOFT_ICON from "./assets_onboarding_screens/Icons/Microsoft.svg";
import LEFT_ARRROW from "./assets_onboarding_screens/Icons/arrow-left.svg";
import FIRE_ICON from "./svg/fire-icon.svg";
import LINKEDIN_ICON from "./assets_onboarding_screens/Icons/LinkedIN.svg";
import CLOUD_ICON from "./assets_onboarding_screens/Icons/cloud-computing 1.svg";
import UPLOAD_ICON from "./assets_onboarding_screens/Icons/Vector.svg";
import ADD_ICON from "./assets_onboarding_screens/Icons/Add.svg";
import DARK_BLUE_ADD from "./svg/darkblueadd.svg";
import SUCCESS_TICK from "./svg/success_tick.svg";
import LOADER_BUBBLE from "./svg/loader.svg";
import CLOSE_MODAL_BUTTON from "./svg/closeModalBtn.svg";
import SUCCESS_ONBOARDING from "./svg/Group 21237.svg";
import DELETE from "./svg/Group.svg";
import PLUSE_SIGN from "./svg/plus (1) 1.svg";
import COMPANY_IMAGE from "./images/job_image-123.png"
import PDF from './images/pdf.png'
import ACCEPT_IMG from './images/accept.png'
import REJECT_IMG from './images/rejected.png'

// jsx
export const IMAGES = {
  COMMON: {
    NAVBAR_IMG,
    SUITCASE_BUTTON_ICON,
    SAVED_BUTTON_ICON,
    LEFT_ARRROW,
    CLOUD_ICON,
    UPLOAD_ICON,
    ADD_ICON,
    DARK_BLUE_ADD,
    SUCCESS_TICK,
    CLOSE_MODAL_BUTTON,
    DELETE,
    PLUSE_SIGN,
    COMPANY_IMAGE,
    PDF,
    ACCEPT_IMG,
    REJECT_IMG,
  },
  ONBORADING: {
    ICON_ONBOARDING_ARROW,
    GOOGLE_ICON,
    MICROSOFT_ICON,
    FIRE_ICON,
    LINKEDIN_ICON,
    LOADER_BUBBLE,
    SUCCESS_ONBOARDING
  },
  DASHBOARD: {
    JOB_IMAGE,
    BACKGROUND_IMAGE,
    CV_IMAGE,
    PROFILE_ICON,
    EYE_ICON,
    SUITCASE_ICON,
    NEXT_ARROW_BLUE,
    NEXT_ARROW_PURPLE,
    NEXT_ARROW_GREEN,
  },
};
