import Repository from "./Repository";
import _ from "lodash";

const getPath = (path) => {
  return `profileview/${path}`;
};
class ProfileViewRepository extends Repository {
  logProfileView = async (params) => {
    try {
      const data = await this.postData(getPath(`logprofileview`),params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getProfileView = async () => {
    try {
      const data = await this.getData(getPath(`getprofileviews`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };
 
}
export default new ProfileViewRepository("profileView");
