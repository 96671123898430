import React, { useCallback, useEffect, useRef, useState } from "react";
import { SearchComponenet } from "../../../components/atoms/Inputs";
import {
  DropdownComponent,
  DropdownComponentCandidate,
} from "../../../components/atoms";
import { JobCard } from "../../../components";
import { connect, useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { getText } from "../../../core/Constant";
import _ from "lodash";

const JobSearchScreen = ({
  savedJobsCandidate,
  appliedJobsCandidate,
  jobPost,
  candidateAllJobs,
  getAllSavedJobCandidate,
  getAllAppliedJobCandidate,
  getAllJobCandidtes,
  locationList,
  getLocations,
  seachedResults,
}) => {
  const dispatch = useDispatch();
  const workType = [
    { option: "On Site", value: "On-site" },
    { option: "Remote", value: "Remote" },
    { option: "Hybrid", value: "Hybrid" },
  ];
  const [searchInput, setSearchInput] = useState("");
  const [location, setLocation] = useState([]);
  const jobType = [
    { option: "Full Time", value: "Full-Time" },
    { option: "Part Time", value: "Part-Time" },
    { option: "Contract", value: "Contract" },
    { option: "Internship", value: "Internship" },
    { option: "Seasonal", value: "Seasonal" },
  ];
  const [selectedJobType, setSelectedJobType] = useState("Job Type");
  const [selectedWorkType, setSelectedWorkType] = useState("Modality");
  const [selectedLocation, setSelectedLocation] = useState("Location");
  const searchResults = useSelector((state) =>
    state.JobSearch.get("searchResults")
  );
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {}, [dispatch]);

  useEffect(() => {
    getAllSavedJobCandidate();
    getAllAppliedJobCandidate();
    getAllJobCandidtes();
    getLocations();
  }, []);

  useEffect(() => {
    setLocation(
      _.map(locationList, (item) => {
        return {
          option: `${_.get(item, "name")}, ${_.get(item, "country_name")}`,
          value: `${_.get(item, "name")}, ${_.get(item, "country_name")}`,
        };
      })
    );

    console.log("searchResults", searchResults);
  }, [
    savedJobsCandidate,
    appliedJobsCandidate,
    jobPost,
    candidateAllJobs,
    locationList,
    seachedResults,
  ]);

  const getSelectedWorkType = (value) => {
    setSelectedWorkType(_.get(value, "value"));
  };

  const getSelectedJobType = (value) => {
    setSelectedJobType(_.get(value, "value"));
  };
  const getSelectedLocation = (value) => {
    setSelectedLocation(_.get(value, "value"));
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const fetchResults = async (query) => {
    if (!query) {
      seachedResults = [];
      return;
    } else {
      dispatch(
        Actions.JobSearch.searchResults({
          title: query,
        })
      );
    }
  };

  const debouncedFetchResults = useCallback(debounce(fetchResults, 300), []);

  const onChangeSearch = (value) => {
    setShowSearchResults(false);
    const text = getText(value);
    console.log('text:',text);
    setSearchInput(text);
    debouncedFetchResults(text);
  };

  const onClickSearch = () => {
    dispatch(
      Actions.JobSearch.searchResults({
        title: selectedSearch,
        work_type: selectedWorkType == 'Modality' ? "" : selectedWorkType,
        location: selectedLocation == 'Location' ? "" : selectedLocation,
        job_type: selectedJobType == 'Job Type' ? "": selectedJobType,
      })
    );
    setShowSearchResults(true);
  };

  const getSelectedSearchTitle = (value) => {
    console.log('getSelectedSearchTitle:',value);
    setSelectedSearch(value);
    setSearchInput(value);
  };

  return (
    <div className="p-4">
      <div className="rounded-lg p-4 bg-white">
        <div className="">
          <div className="">
            <SearchComponenet
              value={searchInput}
              onChange={(e) => onChangeSearch(e)}
              onClick={onClickSearch}
              searchResults={searchResults}
              getSelectedSearchItem={getSelectedSearchTitle}
            />
          </div>
        </div>
        <div className="grid grid-flow-row-dense grid-cols-12 items-center gap-x-2">
          <div className="2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-6 col-span-12">
            <DropdownComponentCandidate
              dropdownOptions={jobType}
              defaultOption={selectedJobType}
              getSelectedOption={getSelectedJobType}
            />
          </div>
          <div className="2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-6 col-span-12">
            <DropdownComponentCandidate
              defaultOption={selectedWorkType}
              dropdownOptions={workType}
              getSelectedOption={getSelectedWorkType}
            />
          </div>
          <div className="2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-6 col-span-12">
            <DropdownComponentCandidate
              defaultOption={selectedLocation}
              dropdownOptions={location}
              getSelectedOption={getSelectedLocation}
            />
          </div>
        </div>
      </div>
      <div>
      {showSearchResults ? (
      
          searchResults.length > 0 ? (
            <div className="grid grid-flow-row-dense 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2  mt-4 gap-2">
              {_.map(searchResults, (result, index) => {
                return (
                  <div className="col-span-1" key={index}>
                    <JobCard item={result} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex justify-center items-center max-h-svh min-h-[430px]">
              <span className="font-DM text-3xl text-gray-400">
              Sorry we did not find anything for your search
              </span>
            </div>
          )
      ) : (
        <div className="flex justify-center items-center max-h-svh min-h-[430px]">
          <span className="font-DM text-3xl text-gray-400">Search a Job</span>
        </div>
      )}
    </div>
    </div>
  );
};

export default connect(
  (state) => ({
    candidateAllJobs: state.jobs.get("candidateAllJobs"),
    jobPost: state.jobs.get("jobPost"),
    appliedJobsCandidate: state.jobs.get("appliedJobsCandidate"),
    savedJobsCandidate: state.jobs.get("savedJobsCandidate"),
    locationList: state.onboardingProcess.get("locationList"),
  }),
  {
    saveJob: Actions.jobs.saveJob,
    removeSavedJob: Actions.jobs.removeSavedJob,
    getAllJobCandidtes: Actions.jobs.getAllJobCandidtes,
    getJobPost: Actions.jobs.getJobPost,
    applyJob: Actions.jobs.applyJob,
    getAllAppliedJobCandidate: Actions.jobs.getAllAppliedJobCandidate,
    getAllSavedJobCandidate: Actions.jobs.getAllSavedJobCandidate,
    getLocations: Actions.onboardingProcess.getAllLocations,
  }
)(JobSearchScreen);
