import Repository from "./Repository";
import _ from "lodash";

const getPath = (path)=>{
    return `notes/${path}`;
}
class NotesRepository extends Repository {
    addNote = async (params) => {
        try {
            const data = await this.postData(getPath(`addnote`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };

    getNote = async (noteId)=>{
        try {
            const data = await this.getData(getPath(`getNote/${noteId}`), {}, '');
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };
    getAllNotes = async ()=>{
        try {
            const data = await this.getData(getPath(`notes`), {}, '');
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }

    updateNote = async (noteId, params) => {
        try {
            const data = await this.putData(getPath(`updatenote/${noteId}`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };

    
    removeNote = async (noteId) => {
        try {
            const data = await this.deleteData(getPath(`removenote/${noteId}`));
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }
}
export default new NotesRepository("notes");