import React from "react";
import { EyeIcon, EyeSlashIcon } from "../../../../assets/svg";

export const PasswordField = ({
  placeholder = "",
  label = "",
  error = false,
  errortxt = "",
  type = "password",
  value = undefined,
  onChange = () => {},
  isVisible = true,
  text = "",
  onclickEyeBtn = () => {},
}) => {
  return (
    <div className="input-field-wrap">
      <label className="input-label-txt">{label}</label>
      <div className="flex flex-row item-center justify-center ">
        <div
          className="bg-darkGray cursor-pointer  rounded-tl-lg rounded-bl-lg min-w-14 flex items-center justify-center  mt-1 mb-1"
          onClick={onclickEyeBtn}
        >
          {isVisible ? (
            <div style={{ width: 24, height: 24 }}>
              <EyeIcon />
            </div>
          ) : (
            <div style={{ width: 24, height: 24 }}>
              {" "}
              <EyeSlashIcon />{" "}
            </div>
          )}
          <span className="input-label-txt">{text}</span>
        </div>
        <input
          type={type}
          value={value}
          className={error ? "error-icon-field" : "input-icon-basic"}
          onChange={onChange}
          placeholder={placeholder}
          style={{ borderRadius: "8px" }}
        />
      </div>

      {error ? (
        <p className="input-error-txt">{errortxt}</p>
      ) : null}
    </div>
  );
};
