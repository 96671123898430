import { Map } from "immutable";
import _ from "lodash";
import { ModuleEvents } from "./Actions";
//import { ModuleEvents as SigninEvent } from "../signin/Actions";

const InitialState = Map({
  loadingAction: { loading: false, action: {} },
  candidateNotes: [],
  dashboard: [],
  chatList: [],
  messagesForChat: {},
  profileViews: [],
  allNotifications: [],
  readNotifications: [],
  unreadNotifications: [],
  screeningQuestions: [],
  candidateInterviews: [],
  interviewDetails: [],
  jobRoles: [],
  SkillList: [],
  screeningQuestionsList: [],
  createJob: {},
  recruiterDashboard: {},
  questionList: {},
  appliedList: {},
  qualifiedList: {},
  rejectedList: {},
  interviewList: [],
  interviewDetails: {},
  searchCandidiates:[],
  offeredList:{},
  OfferAcceptList:{},
  OfferRejectList:{},
  conversationList:{},
  messages: {},
  answersCandidiate:{},
  singleCandidiate:[],
  screeningInitiate:{}
  

});

export const Reducer = (state = InitialState, action) => {
  console.log(action);
  const { payload, type } = action;
  switch (type) {
    case ModuleEvents.LOADING_STARTED: {
      return state.set("loadingAction", { loading: true, action: payload });
    }
    case ModuleEvents.LOADING_FINISHED: {
      return state.set("loadingAction", { loading: false, action: payload });
    }

    case ModuleEvents.GET_ALL_NOTES: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("candidateNotes", data);
      }
      return state;
    }

    case ModuleEvents.GET_DASHBOARD_DETAILS: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("dashboard", data);
      }
      return state;
    }

    case ModuleEvents.GET_CHAT_LIST: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("chatList", data);
      }
      return state;
    }

    case ModuleEvents.GET_MESSAGES: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("messagesForChat", data);
      }
      return state;
    }

    case ModuleEvents.GET_PROFILE_VIEWS: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("profileViews", data);
      }
      return state;
    }

    case ModuleEvents.GET_ALL_NOTIFICATIONS: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("allNotifications", data);
      }
      return state;
    }

    case ModuleEvents.GET_READ_NOTIFICATIONS: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("readNotifications", data);
      }
      return state;
    }

    case ModuleEvents.GET_UNREAD_NOTIFICATIONS: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("unreadNotifications", data);
      }
      return state;
    }

    case ModuleEvents.GET_SCREEING_QUESTIONS: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("screeningQuestions", data);
      }
      return state;
    }

    case ModuleEvents.GET_INTERVIEWS_CANDIDATE: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("candidateInterviews", data);
      }
      return state;
    }

    case ModuleEvents.JOB_ROLE_LIST: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("jobRoles", data);
      }
      return state;
    }

    case ModuleEvents.SKILLS_LIST: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("SkillList", data);
      }
      return state;
    }

    case ModuleEvents.GET_SCREEING_QUESTIONS_LIST: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("screeningQuestionsList", data);
      }
      return state;
    }

    case ModuleEvents.GET_RECRUITER_DASHBOARD: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("recruiterDashboard", data);
      }
      return state;
    }
    case ModuleEvents.GET_SCREENING_QUESTIONS: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("questionList", data);
      }
      return state;
    }
    case ModuleEvents.GET_APLLIED_CANDIDATES: {
      if (payload) {
        const data = _.get(payload, "data", {});
        return state.set("appliedList", data);
      }
      return state;
    }
    case ModuleEvents.GET_QUALIFIED_CANDIDIATES: {
      if (payload) {
        const data = _.get(payload, "data", {});
        return state.set("qualifiedList", data);
      }
      return state;
    }
    case ModuleEvents.GET_REJECTED_CANDIDIATES: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("rejectedList", data);
      }
      return state;
    }
    case ModuleEvents.GET_OFFERED_CANDIDATES: { 
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", {});
        return state.set("offeredList", data);
      }
      return state;
    }
    case ModuleEvents.GET_OFFERREJECTED_CANDIDIATES: { 
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", {});
        return state.set("OfferRejectList", data);
      }
      return state;
    }
    case ModuleEvents.GET_OFFERACCEPTED_CANDIDIATES: { 
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", {});
        return state.set("OfferAcceptList", data);
      }
      return state;
    }
    case ModuleEvents.GET_INTERVIEW_LIST: { 
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", []);
        return state.set("interviewList", data);
      }
      return state;
    }
    case ModuleEvents.GET_CONVERSATION_LIST: { 
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", {});
        return state.set("conversationList", data);
      }
      return state;
    }
    case ModuleEvents.GET_MESSAGES_RECRUIT: { 
      if (
        payload &&
        payload.status_code == 1
      ) {
        console.log(payload,'payloaddd')
        const data = _.get(payload, "data", {});
        return state.set("messagesRecruit", data);
      }

      return state;
    }
    case ModuleEvents.GET_INTERVIEW_DETAILS: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("interviewDetails", data);
      }
      return state;
    }
    case ModuleEvents.SEACRH_CANDIDIATES: { 
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", []);
        return state.set("searchCandidiates", data);
      }
      return state;
    }
    case ModuleEvents.GET_ANSWERS_RECRUITER: { 
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", []);
        return state.set("answersCandidiate", data);
      }
      return state;
    }

    case ModuleEvents.GET_SINGLE_CANDIDITE: { 
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", []);
        return state.set("singleCandidiate", data);
      }
      return state;
    }
    case ModuleEvents.GET_SCREENING_ALLOCATED_CANDIDITE: {
      if (payload) {
        const data = _.get(payload, "data", {});
        return state.set("screeningInitiate", data);
      }
      return state;
    }
    case ModuleEvents.STORE_CREATE_JOB: {
      return state.set("createJob", { ...state.get("createJob"), ...payload });
    }
    case ModuleEvents.RESET_CREATE_JOB: {
      return state.set("createJob", InitialState.createJob);
    }
    case ModuleEvents.LOADING_STARTED: {
      return state.set("loadingAction", { loading: true, action: payload });
    }
    case ModuleEvents.LOADING_FINISHED: {
      return state.set("loadingAction", { loading: false, action: payload });
    }
    case ModuleEvents.GET_CAREER_ADVICE: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("careerPostList", data);
      }
      return state;
    }
    case ModuleEvents.GET_SINGLE_CAREER_POST: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("careerPost", data);
      }
      return state;
    }
    case ModuleEvents.GET_HIRED_CANDIDIATES: { 
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", {});
        return state.set("hiredList", data);
      }
      return state;
    }
  }

  return state;
};
