import React, { useEffect, useState } from "react";
import { ButtonComponent, TabButtonComponent, TextFieldComponent } from "../../../components/atoms";
import { ScreeningCard } from "../../../components/molecule";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { onToastNotify } from "../../../core/Constant";
import { imageURL } from "../../../core/repository/Repository";
import { InterviewPostModal } from "../../../components/molecule/Modals/InterviewPostModal";
import { ConversationModal } from "../../../components/molecule/Modals";
import { IMAGE_URL } from "../../../core/Constant";

const HiredScreen = ({
  isInitial = false,
  getAllJobRecruiter,
  getAllHiredcandidates,
  recruiterJobs,
  hiredList,
  markasQualified,
  markasRejected,
  offerJob,
}) => {
  const [activeTab, setActiveTab] = useState("applied");
  const [jobPost, setJobPost] = useState("");
  const [jobs, setJobs] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [appliedCandidates, setAppliedCandidates] = useState([]);
  const [qualifiedCandidates, setQualifiedCandidates] = useState([]);
  const [rejectedCandidates, setRejectedCandidates] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConversationModalOpen, setIsConversationModalOpen] = useState(false); 
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null); 
  const [conversationTitle, setConversationTitle] = useState("");

  useEffect(() => {
    getAllJobRecruiter();
  }, []);

  useEffect(() => {
    setJobs(recruiterJobs || []);
  }, [recruiterJobs]);

  const handleJobPostChange = (e) => {
    setJobPost(e.target.value);
    setShowDropdown(true);
  };

  const handleJobPostSelect = (job) => {
    console.log(job)
    setJobPost(job.title);
    setSelectedJob(job);
    getAllHiredcandidates(job.id);
    setShowDropdown(false);
  };

  useEffect(() => {
    if (activeTab === "applied" && selectedJob != null) {
      getAllHiredcandidates(selectedJob.id);
    }else{
      setAppliedCandidates([]);
    }
  }, [activeTab, selectedJob]);

  useEffect(() => {
    if(selectedJob != null){
      setAppliedCandidates(hiredList || []);
      if (activeTab === "applied") {
        setCandidateList(hiredList || []); // Update only if on applied tab
      }
    }else{
      setCandidateList([]);
    }
   
  }, [hiredList]);


  function createMarkup() {
    return { __html: selectedJob.description };
  }

  return (
    <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
      <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 ">
        <div className="bg-white p-4 rounded-xl">
          <p className="text-2xl text-darkBlue font-bold font-DM">Hired Candidates</p>

          <div className="relative">
            <TextFieldComponent
              placeholder="Please Select Job Post"
              value={jobPost}
              onChange={handleJobPostChange}
              label="Job Post"
            />
            {showDropdown && jobPost && (
              <div className="absolute z-10 bg-white shadow-md max-h-40 overflow-y-auto w-full">
                {jobs
                  .filter((job) => job.title.toLowerCase().includes(jobPost.toLowerCase()))
                  .map((job, index) => (
                    <div
                      key={index}
                      className="p-2 cursor-pointer hover:bg-blue-200"
                      onClick={() => handleJobPostSelect(job)}
                    >
                      {job.title}
                    </div>
                  ))}
              </div>
            )}
          </div>

          {selectedJob && (
            <div className="mt-4 border p-4 rounded-lg bg-gray-100 flex items-start">
              <img
                src={IMAGE_URL + selectedJob.job_image}
                alt={`${selectedJob?.title} image`}
                className="w-20 h-20 rounded-full object-cover mr-4"
              />
              <div>
                <p>
                  <strong>Job Title:</strong> {selectedJob.title}
                </p>
                <p>
                  <strong>Job Description:</strong> <p dangerouslySetInnerHTML={createMarkup()}></p>
                </p>
                <p>
                  <strong>Location:</strong> {selectedJob.location}
                </p>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between gap-y-2 flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-row mt-2">
          </div>
        </div>

        {candidateList.length > 0 ? (
          candidateList.map((item, index) => (
            <ScreeningCard 
              key={index} 
              candidate={item} 
              tabName={activeTab} 
              isOffered={true} />
          ))
        ) : (
          <p className="text-lg mt-2 text-gray-700 font-semibold p-4 bg-white rounded-lg shadow-md border border-gray-200">
            No candidates available.
          </p>
        )}
      </div>

      {/* {isModalOpen && (
        <InterviewPostModal
          jobId={selectedJobId}
          isOpen={isModalOpen}
          candidate={selectedCandidate}
          onClose={handleCloseModal}
        />
      )}

      {isConversationModalOpen && (
        <ConversationModal
          isOpen={isConversationModalOpen}
          onClose={() => setIsConversationModalOpen(false)}
          onSubmit={handleCreateConversation}
        />
      )} */}
    </div>
  );
};

export default connect(
  (state) => ({
    recruiterJobs: state.jobs.get("recruiterJobs"),
    appliedList: state.home.get("appliedList"),
    hiredList: state.home.get("hiredList"),

  }),
  {
    getAllJobRecruiter: Actions.jobs.getAllJobRecruiter,
    getAllHiredcandidates: Actions.home.getAllHiredcandidates,
 
  }
)(HiredScreen);
