import { Fragment} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ButtonComponent } from "../../atoms";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { IMAGES } from "../../../assets";
import { useNavigate } from "react-router-dom";

export const SuccessMessageModel = ({
  heading = "Account created successfully",
  subText = "",
  onClose,
  open = false,
  buttonText = "OK",
  setCandidateStep = () => {},
  
}) => {
  const navigate = useNavigate();
  const handlebtnClick = () => {
    setCandidateStep(4);
    // if(localStorage.getItem('type') == "candidate"){
    //   navigate("/dashboard");
    // }else{
    //   navigate("/recruiter_dashboard");
    // }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl  bg-white text-left shadow-xl transition-all w-full max-w-md">
                <div className=" p-12 grid grid-flow-row-dense grid-cols-1  gap-y-6">
                  <div className="flex flex-col items-center">
                  <img src={IMAGES.COMMON.SUCCESS_TICK} alt="icon" />
                  <p className="font-DM font-semibold text-lg text-center">
                    {heading}
                  </p>
                  <p className="font-DM font-normal text-[#A3AED0] text-md text-center">
                    {subText}
                  </p>
                  </div>

                  <div className="btn-container flex justify-center ">
                    <div className="w-44">
                      <ButtonComponent
                        isPrimary={true}
                        text={buttonText}
                        onClick={() => handlebtnClick()}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
