import React, { useEffect, useState } from "react";
import { ButtonComponent } from "../../atoms";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { dateFromNow } from "../../../core/Constant";
import { InterviewMoreDetailsModal, JobViewModal } from "../Modals";
import { TicketDetailsModal } from "../Modals/TicketDetailsModal";

export const SupportTicketCard = ({
  onClose,
  ticketDetails = {},
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {}, [ticketDetails]);

  return (
    <div className="w-full">
      <div className="bg-bgColor rounded-[12px] p-[15px]">
        <div className="grid grid-cols-1 sm:grid-cols-5 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-5 gap-4">
        
          <div className="col-span-1 sm:col-span-4 xl:col-span-10 md:col-span-4">
            <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4">
              <span className="text-lableColor font-DM text-[20px] sm:text-[24px] font-[700]">
                {_.get(ticketDetails, "title")}
              </span>
              <div className="flex flex-row mt-2 sm:mt-0 sm:ml-4">
                <span
                  className={`text-darktextColor font-DM text-[14px] sm:text-[16px] font-[500] ${_.get(ticketDetails, "status") === 'OPEN' ? 'bg-lighterGreen' : _.get(ticketDetails, "status") === 'REPLIED' ? 'bg-yellow-300' : 'bg-red-300'} py-1 px-2 sm:px-4 rounded-lg`}
                >
                  {_.get(ticketDetails, "status")}
                </span>
                <span className="text-primaryColor font-DM text-[14px] sm:text-[18px] font-[500] ml-4">
                  {dateFromNow(_.get(ticketDetails, "updatedAt"))}
                </span>
              </div>
            </div>
            <span className="text-lableColor font-DM text-[14px] sm:text-[16px] font-[400]">
              {_.get(ticketDetails, "description")}
            </span>
          </div>

          <div className="col-span-1 sm:col-span-1 xl:col-span-2 lg:col-span-2 flex items-center sm:justify-end">
            <div className="grid grid-cols-1 gap-2 w-full sm:w-auto">
              <div className="col-auto">
                <ButtonComponent text= {_.get(ticketDetails, "status") == 'CLOSED' ? 'View Replies' :'Add Reply'} onClick={() => setOpen(true)} isPrimary={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TicketDetailsModal open={open} onClose={()=> setOpen(false)} details={ticketDetails}/>
    </div>
  );
};
