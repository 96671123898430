import { IMAGES } from "../../../assets";

export const DashboardCard = ({ icon, count, headingText, color }) => {
  return (
    <div className="bg-white rounded-md grid grid-cols-3 grid-rows-2 p-4 gap-2 h-28 cursor-pointer">
      <div className="row-span-2 col-span-1"><img src={icon} alt="cardLogo" /></div>
      <div className="row-span-1 col-span-2  "><p className="text-sm font-DM text-[#A3AED0]">{headingText}</p></div>
      <div className="row-span-1 col-span-2">
        <div className="flex items-center justify-between ">
          <p className="sub-heading">{count}</p>
          {color == 'blue' ? <img src={IMAGES.DASHBOARD.NEXT_ARROW_BLUE} alt="Arrow-icon" /> : null}
          {color == 'purple' ? <img src={IMAGES.DASHBOARD.NEXT_ARROW_PURPLE} alt="Arrow-icon" /> : null}
          {color == 'green' ? <img src={IMAGES.DASHBOARD.NEXT_ARROW_GREEN} alt="Arrow-icon" /> : null}
        </div>
      </div>
    </div>
  );
};
