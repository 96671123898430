export const MenuVideoIcon = ({ isSelected = false }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 2.75C1 2.28587 1.18437 1.84075 1.51256 1.51256C1.84075 1.18437 2.28587 1 2.75 1H13.25C13.7141 1 14.1592 1.18437 14.4874 1.51256C14.8156 1.84075 15 2.28587 15 2.75V13.25C15 13.7141 14.8156 14.1592 14.4874 14.4874C14.1592 14.8156 13.7141 15 13.25 15H2.75C2.28587 15 1.84075 14.8156 1.51256 14.4874C1.18437 14.1592 1 13.7141 1 13.25V2.75ZM5.8125 11.5C5.81248 11.5843 5.83683 11.6669 5.88262 11.7377C5.92842 11.8085 5.99371 11.8646 6.07063 11.8992C6.14754 11.9337 6.23282 11.9453 6.31618 11.9326C6.39954 11.9198 6.47744 11.8832 6.5405 11.8272L10.478 8.32725C10.5243 8.2862 10.5613 8.23581 10.5867 8.1794C10.612 8.12299 10.6251 8.06185 10.6251 8C10.6251 7.93815 10.612 7.87701 10.5867 7.8206C10.5613 7.76419 10.5243 7.7138 10.478 7.67275L6.5405 4.17275C6.47744 4.11675 6.39954 4.08017 6.31618 4.06742C6.23282 4.05466 6.14754 4.06627 6.07063 4.10085C5.99371 4.13542 5.92842 4.19149 5.88262 4.26231C5.83683 4.33312 5.81248 4.41567 5.8125 4.5V11.5Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
    </svg>
  );
};
