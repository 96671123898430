import React from "react";
import { EditProfileForm, ProfileDetailCard, RecruiterProfileForm } from "../../../components";

export default ({ }) => {
    return (
        <div className="grid grid-flow-row-dense 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-2">
            <div className="">
                <ProfileDetailCard recruiter={true} />
            </div>
            <div className=" col-span-2">
                <RecruiterProfileForm />
            </div>
        </div>
    );
};
