import { createAction } from '../../core/AppUtils';
import CommonRepository from '../../core/repository/CommonRepository';

export const ModuleEvents = {
  LOADING_STARTED: 'LOADING_STARTED',
  LOADING_FINISHED: 'LOADING_FINISHED',
  NETWORK_ERROR:'NETWORK_ERROR',
  GET_INDUSTRY_LIST:'GET_INDUSTRY_LIST',
  GET_SKILL_LIST:'GET_SKILL_LIST',
  GET_TITLE_LIST:'GET_TITLE_LIST',
  GET_JOB_ROLE_LIST:'GET_JOB_ROLE_LIST'
};

export default {
  networkError: createAction(ModuleEvents.NETWORK_ERROR, error => error),
  loadingStarted: createAction(ModuleEvents.LOADING_STARTED, action => action),
  loadingFinished: createAction(ModuleEvents.LOADING_FINISHED, action => action),
  
  getindustryList: createAction(ModuleEvents.GET_INDUSTRY_LIST, async () => {
    return await CommonRepository.getindustryList();
  }),

  getskillList:createAction(ModuleEvents.GET_SKILL_LIST, async () => {
    return await CommonRepository.getskillList();
  }),

  getjobTitleList:createAction(ModuleEvents.GET_TITLE_LIST, async () => {
    return await CommonRepository.getjobTitleList();
  }),

  
  getjobRoleList:createAction(ModuleEvents.GET_JOB_ROLE_LIST, async () => {
    return await CommonRepository.getJobRoleList();
  }),

};



