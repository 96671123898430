import React from "react";

export const SquareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="#ffffff"
      width={18}
      height={18}
    >
      <path d="M0 0H512V512H0V0z" />
    </svg>
  );
};
