import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
} from "@material-tailwind/react";
import { ButtonComponent, CountryDropdownComponent, DropdownComponent, TextAreaComponent, TextFieldComponent } from "../../atoms";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { getFile, onToastNotify } from "../../../core/Constant";
import { IMAGES } from "../../../assets";

export const CreateJobFormOne = ({ setActiveTab,jobId  }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState(null);
    const [isValidImage,setIsvalidImage] = useState(false)
    const [jobTitle, setJobTitle] = useState(null);
    const [companyname,setCompanyname] = useState(null);
    const [workmode, setWorkMode] = useState(null);
    const [minSalary, setMinsalary] = useState('');
    const [maxSalary, setMaxsalary] = useState('');
    const [location, setLocation] = useState(null);
    const [empType, setEmpType] = useState(null);
    const [errorjobTitle, setErrorJobTitle] = useState(null);
    const [errorcompanyname,setErrorCompanyname] = useState(null);
    const [errorworkmode, setErrorWorkMode] = useState(null);
    const [errorminSalary, setErrorMinsalary] = useState(null);
    const [errormaxSalary, setErrorMaxsalary] = useState(null);
    const [errorlocation, setErrorLocation] = useState(null);
    const [errorempType, setErrorEmpType] = useState(null);
    const createJob = useSelector((state) => state.home.get('createJob'));
    const titleList = useSelector((state) => state.common.get('titleData'));
    const jobPost = useSelector((state) => state.jobs.get('jobPost'));

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(Actions.common.getjobTitleList());
        if(jobId){
            dispatch(Actions.jobs.getJobPost(jobId));
        }
        console.log(jobId,'iddddddd')
      }, [dispatch]);

      useEffect(() => {
        console.log(titleList, 'industry list');
      }, [titleList]);

    



    useEffect(()=>{
        setJobTitle(createJob?.title)
        setLocation(createJob?.location        )
        setMaxsalary(createJob?.max_salary)
        setMinsalary(createJob?.min_salary)
        setEmpType(createJob?.job_type)
        setWorkMode(createJob?.work_type)
       // console.log(jobId,'iddddddd')
    },[createJob])


    const workType = [
        {id:1,name:'Full-Time'},
        {id:2,name:'Part-Time'},
        {id:3,name:'Contract'},
        {id:4,name:'Internship'},
        {id:5,name:'Apprenticeship'},
        {id:6,name:'Seasonal'},
        {id:7,name:'Freelance'},
    ]

    const workMode = [
        {id:1,name:'Remote'},
        {id:2,name:'On-site'},
        {id:3,name:'Hybrid'},
    ]

    const dropdownOptions = titleList?.map((industry,index) => ({
        id: index,
        name: industry.title,
      }));

    const handleWorkmode = (option) =>{
       setWorkMode(option)
    }

    const handleJobtype = (option)=>{
        setEmpType(option)

    }

   const  handleTitle = (option)=>{
    setJobTitle(option)

}

    const handleImageUpload = (event) => {
        const file = getFile(event);
        if (file) {
          const imageUrl = URL.createObjectURL(file);
          setImage(file);
          setSelectedImage(imageUrl);
          setIsvalidImage(true)
        }
      };

       const handleContinue = (event) => {
        event.preventDefault(); 
        console.log(jobTitle,workmode)
        if(jobTitle && workmode && location ){
            dispatch(Actions.home.storeCreateJob({title:jobTitle,work_type:workmode,min_salary:minSalary ? minSalary : "",max_salary:maxSalary  ? maxSalary : "",location:location,currency:'LKR',job_type:empType,job_image:image}))
            setActiveTab('Job Description');     
        }
        else{
            onToastNotify('Add All Details')
        }

    };




    return (
        <Card className="bg-white py-4 px-8" shadow={false}>
            <form className="mb-2">
                <div className="w-full my-8 h-auto flex flex-col items-start">
                <div className="upload-photo-container relative rounded-full cursor-pointer bg-[#E4F1FC] w-24 h-24">
        <input
          type="file"
          accept="image/*"
          className="absolute inset-0 opacity-0 cursor-pointer"
          onChange={handleImageUpload}
        />
        <div className="upload-photo-inner-content flex flex-col gap-y-2 justify-center h-full items-center p-2">
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected"
              className="rounded-full w-full h-full object-cover"
            />
          ) : (
            <>
              <img src={IMAGES.COMMON.UPLOAD_ICON} alt="icon-upload" />
              <p className="font-DM text-sm text-center">Upload photo</p>
            </>
          )}
        </div>
        <div className="absolute bottom-0 right-0">
          <img src={IMAGES.COMMON.ADD_ICON} alt="icon-add" className="w-6" />
        </div>
      </div>
                    <TextFieldComponent
                        error={false}
                        placeholder="type here"
                        isdisabled={false}
                        errortxt="Title is required!"
                        label="Job Title"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                    />
                      {/* <DropdownComponent
                        error={false}
                        placeholder="type here"
                        isdisabled={false}
                        errortxt="Title is required!"
                        label="Job Title"
                        dropdownOptions={dropdownOptions}
                        onOptionSelected={handleTitle}
                    /> */}
                    <div className="w-full justify-between flex flex-row">
                    <DropdownComponent
                        error={false}
                        placeholder="type here"
                        isdisabled={false}
                        errortxt="label is required!"
                        label="Job Type"
                        dropdownOptions={workType}
                        value={empType}
                        onOptionSelected={handleJobtype}
                    />
                        <div className="w-[35px]"></div>
                        <DropdownComponent
                            error={false}
                            placeholder="type here"
                            isdisabled={false}
                            errortxt="label is required!"
                            label="Work Mode"
                            dropdownOptions={workMode}
                            value={workmode}
                            onOptionSelected={handleWorkmode}
                        />
                    </div>
                    <div className="w-full justify-between flex flex-row">
                        <CountryDropdownComponent
                        onChange={(e) =>{setMinsalary(e.target.value)}}
                        value={minSalary}
                            label={'Minimum Salary (Optional)'}
                        />
                        <div className="w-[35px]"></div>
                        <CountryDropdownComponent
                            label={'Maximum Salary (Optional)'}
                            value={maxSalary}
                            onChange={(e) =>{setMaxsalary(e.target.value)}}
                        />
                    </div>
                    <TextFieldComponent
                        error={false}
                        placeholder="type here"
                        isdisabled={false}
                        errortxt="label is required!"
                        label="Location"
                        value={location}
                        onChange={(e)=>{setLocation(e.target.value)}}
                    />
                </div>
                <div className="">
                    <ButtonComponent text="Continue" onClick={handleContinue} />
                </div>
            </form>
        </Card>
    );
};
