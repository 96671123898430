export const HomeIcon = ({ isSelected = false }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7495 5.07168V12.5387C13.7491 12.9364 13.591 13.3177 13.3097 13.5989C13.0285 13.8801 12.6472 14.0383 12.2495 14.0387H9.74951C9.48429 14.0387 9.22994 13.9333 9.04241 13.7458C8.85487 13.5582 8.74951 13.3039 8.74951 13.0387V10.2887C8.74951 10.1561 8.69683 10.0289 8.60307 9.93512C8.5093 9.84136 8.38212 9.78868 8.24951 9.78868H5.74951C5.6169 9.78868 5.48973 9.84136 5.39596 9.93512C5.30219 10.0289 5.24951 10.1561 5.24951 10.2887V13.0387C5.24951 13.3039 5.14415 13.5582 4.95662 13.7458C4.76908 13.9333 4.51473 14.0387 4.24951 14.0387H1.74951C1.35181 14.0383 0.970509 13.8801 0.68929 13.5989C0.408072 13.3177 0.249909 12.9364 0.249512 12.5387V5.07168C0.249886 4.899 0.294758 4.72933 0.379799 4.57904C0.46484 4.42875 0.587178 4.30292 0.735012 4.21368L6.48501 0.763678C6.64046 0.670379 6.81834 0.621094 6.99964 0.621094C7.18093 0.621094 7.35882 0.670379 7.51426 0.763678L13.2643 4.21368C13.412 4.30295 13.5343 4.4288 13.6193 4.57908C13.7043 4.72937 13.7492 4.89902 13.7495 5.07168Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
    </svg>
  );
};
