import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import { IMAGES } from "../../../assets";
import moment from "moment";
import { websiteURL } from "../../../core/repository/Repository";

const OfferVerifyScreen = ({
  submitResults
}) => {
  const dispatch = useDispatch();

  const { id, token } = useParams();
  const [searchParams] = useSearchParams();

  const [showSubmitResponse, setSubmitResponse] = useState(false);
  const [offerId, setOfferId] = useState(id);
  const [offerToken, setOfferToken] = useState(token);
  const decision = searchParams.get('decision');

  useEffect(() => {
    if (!decision) {
      return;
    } else {
      if (decision == 'accept') {
        dispatch(
          Actions.offerVerify.acceptResults(offerId, offerToken)
        );
      } else {
        dispatch(
          Actions.offerVerify.rejectResults(offerId, offerToken)
        );
      }
      setSubmitResponse(true);
    }
  }, [decision, offerId, offerToken, dispatch]);

  useEffect(() => {
  }, [showSubmitResponse, submitResults]);

  const handleClick = () => {
    window.location.href = websiteURL;
  };

  return (
    <div className="p-4">
      <div className="rounded-lg p-4 bg-white">
        <div className="grid grid-flow-row-dense grid-cols-12 gap-4 items-center">
          <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-9 md:col-span-12 sm:col-span-12">
            <div className="flex flex-col items-center justify-center h-full">
              {showSubmitResponse ? (<>
                <>
                  <p className="sub-heading">{_.get(submitResults, "data.job.title")}</p>
                  <h6>{_.get(submitResults, "data.job.Company.name")} - {_.get(submitResults, "data.job.location")}</h6>
                  <h6 className={`p-2 text-center ${_.get(submitResults, "status_code") == 0 ? 'text-red' : ''} `}> {_.get(submitResults, "message", "No message available")}</h6>
                  <span className="text-textGrey font-medium font-DM text-[12px] p-2">Applied at: {moment(_.get(submitResults, "data.applied_at")).format('MMM D, YYYY')}</span>
                  {_.get(submitResults, "data.candidate_status") == 'Accepted Candidate' ? (<>
                    <img
                      className="object-contain 2xl:w-40 xl:w-36 lg:w-32 md:w-32 sm: w-36"
                      src={IMAGES.COMMON.ACCEPT_IMG}
                      alt="Job offer accepted"
                    />
                  </>) : (<>
                    <img
                      className="object-contain 2xl:w-40 xl:w-36 lg:w-32 md:w-32 sm: w-36"
                      src={IMAGES.COMMON.REJECT_IMG}
                      alt="Job offer accepted"
                    />
                  </>)}
                </>
                <button
                  type="button"
                  class="mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={handleClick}
                >
                  Go to Recruit60
                </button>
              </>) : (<>
              </>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    submitResults: state.offerVerify.get("submitResults"),
  }),
  {
  }
)(OfferVerifyScreen);
