import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setTopLevelNavigator } from "../../../core/services/NavigationServicd";


const  OnboardingLayout = () => {
  const intervalRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const onNavigate = (path, obj = {}) => {
    if (path) {
        navigate(path, obj);
    }
}

  useEffect(()=>{
    intervalRef.current = onNavigate;
    setTopLevelNavigator(intervalRef);
  },[navigate])


  return( <Outlet />);
};

export default OnboardingLayout;