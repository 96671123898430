import React, { useEffect, useState } from "react";
import { Avatar } from "@material-tailwind/react";
import { ButtonComponent } from "../../atoms";
import { imageURL } from "../../../core/repository/Repository";
import { IMAGE_URL } from "../../../core/Constant";

export const CandidiateCard = ({ candidate = [], onSelectCandidate ,isRecruiter, isSelected = false,onViewProfile,onViewVideo}) => {
  const [user, setUser] = useState();
  const [applicant, setApplicant] = useState();

  useEffect(() => {
    setApplicant(candidate?.application);
    setUser(candidate?.application?.Candidate.User);
  }, [candidate]);

  const handleSelect = () => {
    onSelectCandidate(applicant.id, !isSelected);
  };

  return (
    <div className="bg-bgColor flex flex-col rounded-xl min-w-full overflow-hidden p-5">
      <div className="flex items-start w-full mb-2">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={handleSelect}
          className="mr-2"
        />
      </div>
      <div className="flex flex-col items-center gap-y-2">
        <span className="text-lighterGreen font-DM text-md font-medium">
          {applicant?.matching_percentage}% Matching profile
        </span>
        <Avatar
          style={{ width: "78px", height: "78px", marginTop: "20px" }}
          src={IMAGE_URL + user?.profile_image}
          alt="avatar"
        />
        <span className="text-lableColor font-DM text-lg font-medium">
          {applicant?.candidate_name}
        </span>
        <span className="text-textlightColor font-DM text-sm font-medium">
          {user?.headline}
        </span>
        {/* <span className="text-textlightColor font-DM text-sm font-medium">
          Experience: {candidate?.matching_percentage} Years
        </span> */}


{  isRecruiter ?
        <div className="grid grid-cols-1 w-full gap-2 mt-3">
          <div className="w-full"></div>
        <ButtonComponent fontsize="text-sm" padding="p-2" isPrimary={false} text="60s video" onClick={onViewVideo}/>
        <ButtonComponent onClick={onViewProfile} fontsize="text-sm" padding="p-2" isPrimary={true} text="View Profile" />
      </div> : <></>
}


      </div>
    </div>
  );
};
