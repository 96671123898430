import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  CreateAccountForm,
  ForgotPasswordForm,
  SignInForm,
  SignUpForm,
  SignUpFormCandidate,
  SignUpFormPassword,
  SignUpFormRecruiter,
} from "../../../components/organism";
import { AUTH_PROCESS_STEPS } from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";

const AuthenticationScreen = () => {
  const [image, setImage] = useState("bg-create-account");
  const authState = useSelector((state) => state.auth.get("authstep"));
  useEffect(() => {}, [authState]);
  return (
    <div className="main-auth-page">
      <div className={`left-side-content col-span-1 ${image}`}></div>
      <div className="right-side-content col-span-1">
        {AUTH_PROCESS_STEPS.create_account == _.get(authState, "step") ? (
          <CreateAccountForm />
        ) : null}
        {AUTH_PROCESS_STEPS.signIn == _.get(authState, "step") ? (
          <SignInForm />
        ) : null}
        {AUTH_PROCESS_STEPS.signUp == _.get(authState, "step") ? (
          <SignUpForm />
        ) : null}
        {AUTH_PROCESS_STEPS.signUpFormRecruiter == _.get(authState, "step") ? (
          <SignUpFormRecruiter />
        ) : null}
        {AUTH_PROCESS_STEPS.signUpFormPassword == _.get(authState, "step") ? (
          <SignUpFormPassword />
        ) : null}
        {AUTH_PROCESS_STEPS.forgotPassword == _.get(authState, "step") ? (
          <ForgotPasswordForm />
        ) : null}
        {AUTH_PROCESS_STEPS.signUpFormCandidate == _.get(authState, "step") ? (
          <SignUpFormCandidate />
        ) : null}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    resetPasswordState: state.auth.get("resetPasswordState"),
  }),
  {
    recruiterlogIn: Actions.auth.recruiterlogIn,
  }
)(AuthenticationScreen);
