import React, { useState, useEffect } from "react";
import {
  EditProfileForm,
  ProfileDetailCard,
  ResumeCard,
} from "../../../components";
import { ButtonComponent } from "../../../components/atoms";
import { PdfUploadCard } from "../../../components/molecule/Cards";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import { IMAGE_URL, onToastNotify } from "../../../core/Constant";

export default ({}) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const details = useSelector((state) => state.profile.get("candidateDetails"));

  useEffect(() => {
      dispatch(Actions.profile.getCandiateDetails());
  }, [dispatch]);

  const getFileSelected = (selectedFile, isFileSelected) => {
    setFile(selectedFile);
    setIsSelected(isFileSelected);
  };

  const handleOnPressRemove = () => {
    setFile(null);
    setIsSelected(false);
  };
  const handleUploadFile = async () => {
    if (file !== null && isSelected) {
      const blob = new Blob([file], { type: file.type });

      const data = new FormData();
      data.append("cv_url", blob, _.get(file, "name"));
      dispatch(
        Actions.profile.updateCandidateCv(
          data,
          { "Content-Type": "multipart/form-data" },
          (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log("percentage: ", percent);
          }
        )
      );
    }
  };

  const handleOpenCV = () => {
    if (_.get(details, "candidateDetails.cv_url")) {
      window.open(IMAGE_URL + _.get(details, "candidateDetails.cv_url")
        , "_blank"); 
    } else {
      onToastNotify('CV Not Available')
    }
  };
  return (
    <div className="subscreen-container">
      <div className="flex flex-row justify-between">
        <span class="text-lableColor font-DM text-2xl font-bold">
          Update CV
        </span>
        <span class="text-ash font-DM text-md font-normal">
          {/* In publishing and graphic design, Lorem ipsum is a placeholder */}
        </span>
        <ButtonComponent
            icon={true}
            text="Download CV"
            width="2xl:w-2/5 xl:w-1/3 lg:w-1/2 md:w-1/3 sm:w-full"
            isPrimary={false}
            onClick={handleOpenCV}
          />
      </div>
      <div className="w-96">
        <PdfUploadCard
          setFileNFileSelected={getFileSelected}
          file={file}
          isFileSelected={isSelected}
          removeSelectedFile={handleOnPressRemove}
        />
      </div>
      <div className="flex items-center justify-between gap-3 2xl:w-2/5 xl:w-1/3 lg:w-1/2 md:w-1/3 sm:w-full">
        {/* <ButtonComponent isPrimary={false} text="Cancel" />
        <span class="text-lableColor font-DM text-md font-bold">OR</span> */}
        <ButtonComponent
          text="Replace selected file"
          onClick={handleUploadFile}
        />
      </div>
    </div>
  );
};
