import React from "react";
import { EditProfileForm, ProfileDetailCard, ResumeCard, SalaryChart } from "../../../components";
import { ButtonComponent, DropdownComponent, DropdownComponentCandidate } from "../../../components/atoms";
import { AverageSalaryCard, PdfUploadCard } from "../../../components/molecule/Cards";






export default ({ }) => {
  return (
    <div className="subscreen-container">
      <div className="flex flex-col ">
        <span class="text-lableColor font-DM text-[28px] font-[700]">Salary expectation</span>
        <span class="text-ash font-DM text-[16px] font-[400]">In publishing and graphic design, Lorem ipsum is a placeholder</span>
      </div>
      <div class="grid grid-flow-row-dense grid-cols-10 gap-2">
        <div class="col-span-full 2xl:col-span21 xl:col-span-2 lg:col-span-2 md:col-span-4">
          <DropdownComponentCandidate />
        </div>
        <div class="col-span-full 2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-4">
          <DropdownComponentCandidate />
        </div>
        <div class="col-span-full 2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-4">
          <DropdownComponentCandidate />
        </div>

        <div class="col-span-full 2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-4">
          <DropdownComponentCandidate />
        </div>
       
      </div>
      <SalaryChart />
      <div className="w-full grid grid-cols-1">
        <AverageSalaryCard />
      </div>
    </div>
  )
}