import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { PasswordField } from "../../../components/atoms/Inputs";
import { ButtonComponent, HeadingComponent } from "../../../components/atoms";
import { AUTH_PROCESS_STEPS, getText, PASSWORD_REGEX } from "../../../core/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

export const ResetPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [isValidNewPassword, setIsValidNewPassword] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPwError, setConfirmPwError] = useState(false);

  const [token, setToken] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {}, [dispatch]);
  useEffect(() => {
    const path = _.get(location,'pathname');
    setToken(path.substring(path.lastIndexOf('/') + 1))
  }, [location]);

  const onChangeNewPassword = (password) => {
    let text = getText(password);
    const reg = PASSWORD_REGEX;
    let isValid = false;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }

    setNewPassword(text);
    setIsValidNewPassword(isValid);
    setPasswordError(isValid ? false : true);
    setNewPasswordError("Please enter a valid password");
  };

  const onChangeConfirmPassword = (password) => {
    let text = getText(password);
    const reg = PASSWORD_REGEX;
    let isValid = false;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
      if (isValid && newPassword !== text) {
        isValid = false;
      }
    }

    setConfirmPassword(text);
    setIsValidConfirmPassword(isValid);
    setConfirmPwError(isValid ? false : true);
    setConfirmPasswordError("Entered password not matching");
  };

  const onClickShowPassword = () => {
    setShowNewPassword(showNewPassword ? false : true);
  };

  const onClickShowConfirmPassword = () => {
    setShowConfirmPassword(showConfirmPassword ? false : true);
  };

  const onClickLogin = () => {
    navigate('/',{state:{authStep:AUTH_PROCESS_STEPS.signIn}});
  }

  const onResetPassword = () => {
    if (isValidNewPassword && isValidConfirmPassword) {
      dispatch(
        Actions.auth.resetPassword({
          new_password: newPassword,
          confirm_password: confirmPassword,
        },token)
      );
    } else {
      if (!isValidNewPassword) {
        setPasswordError(true);
        setNewPasswordError("Please enter a correct password");
      }
      if (!isValidConfirmPassword) {
        setConfirmPwError(true);
        setConfirmPasswordError("Entered password is not matching");
      }
    }
  };


  return (
    <div className="main-auth-page">
      <div className={`left-side-content col-span-1 bg-create-account`}></div>
      <div className="right-side-content col-span-1">
        <div className="sign-in-form-container">
          <HeadingComponent
            headingText={"Reset Password"}
            description={"Create your new password here "}
          />
          <div className="sign-in-content-wrapper">
            <PasswordField
              onChange={(e) => onChangeNewPassword(e)}
              placeholder="Enter your New password"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              isVisible={showNewPassword}
              error={passwordError}
              errortxt={newPasswordError}
              onclickEyeBtn={() => onClickShowPassword()}
            />

            <PasswordField
              onChange={(e) => onChangeConfirmPassword(e)}
              placeholder="Confirm your Password"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              isVisible={showConfirmPassword}
              error={confirmPwError}
              errortxt={confirmPasswordError}
              onclickEyeBtn={() => onClickShowConfirmPassword()}
            />
          </div>
          <div className="submit-button">
            <ButtonComponent
              onClick={onResetPassword}
              text="reset Password"
              width="w-1/3"
              isPrimary={true}
            />
          </div>
          <div>
            <p className="font-DM font-medium text-lableColor">
              Already have an Account?
              <span
                className="text-primaryColor font-semibold cursor-pointer ps-1"
                onClick={onClickLogin}
              >
                Login
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
