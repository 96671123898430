import React from 'react';
import { Actions } from '../../core/modules/Actions';
import {ModuleEvents} from './Actions';
import { onMessage, onToast } from '../../core/Constant';
import { navigateTo } from '../../core/services/NavigationServicd';
// import {navigate} from '../../core/repository/Repository';

export default {
  [ModuleEvents.NETWORK_ERROR]: ({dispatch, payload, appState}) => {
    // Logger.infor(JSON.stringify(payload))
   },

   [ModuleEvents.SAVE_JOB]: ({ dispatch, payload, appState }) => {
    if (payload && payload.status_code == 1) {
      onToast('Job Saved', payload, false);
      dispatch(Actions.home.getDashboardDetails())
      dispatch(Actions.jobs.getAllJobCandidtes());
    }
   
  },


  [ModuleEvents.APPLY_JOB]: ({ dispatch, payload, appState }) => {
    onToast('Application Submitted', payload, false);
    dispatch(Actions.home.getDashboardDetails())
    dispatch(Actions.jobs.getAllJobCandidtes());
  },
  [ModuleEvents.SET_JOBSTATUS_RECRUITER]: ({ dispatch, payload, appState }) => {
    // if(payload && !payload.error && payload.status_code == 1){
      dispatch(Actions.jobs.getAllJobRecruiter())
    // }
    onToast('Job Status', payload, false);
  },
  [ModuleEvents.EDIT_JOB_POST]: ({ dispatch, payload, appState }) => {
    if(payload && !payload.error && payload.status_code == 1){
        dispatch(Actions.jobs.getAllJobRecruiter())
    }
    onToast('Job Status', payload, false);
  },
  
  [ModuleEvents.ACCEPT_JOB_OFFERS]: ({ dispatch, payload, appState }) => {
    onToast('Job Offer', payload, false);
    if(payload && !payload.error && payload.status_code == 1){
      onMessage("Job Offer", 'You have accepted the job offer', 'success');
      navigateTo('/')
    }
  },
  [ModuleEvents.REJECT_JOB_OFFERS]: ({ dispatch, payload, appState }) => {
    onToast('Job Offer', payload, false);
    if(payload && !payload.error && payload.status_code == 1){
      onMessage("Job Offer", 'You have rejected the job offer', 'error');
      navigateTo('/')
    }
  },
};
