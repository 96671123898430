import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloseButton } from '../../atoms/Buttons/CloseButton'
import { IMAGES } from '../../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from '../../../core/modules/Actions'

export const JobPostViewModal = ({ jobId, onClose, isOpen = false }) => {
    const [open, setOpen] = useState(isOpen)
    const cancelButtonRef = useRef(null)
    const dispatch = useDispatch()
    const jobPost = useSelector((state) => state.jobs.get('jobPost'));


    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])


    useEffect(() => {
        if (jobId && open) {
            dispatch(Actions.jobs.getJobPost(jobId)) 
        }
    }, [jobId, open, dispatch])


    const handleClose = () => {
        setOpen(false)
        onClose()
    }

    function createMarkup() {
        return { __html: jobPost.description };
    }
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 flex items-center justify-center"
                initialFocus={cancelButtonRef}
                onClose={handleClose} // Close modal when clicking outside or pressing Esc
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className="relative w-full max-w-3xl mx-4 sm:mx-0">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <Dialog.Panel className="w-full max-w-4xl bg-white rounded-lg shadow-xl max-h-[90vh] overflow-y-auto">
                                <div className="p-8 space-y-6">
                                    <div className="flex flex-col sm:flex-row items-center justify-between">
                                        <div className="flex items-center mb-4 sm:mb-0">
                                            <img className="w-16 h-16 mr-4 rounded-full border-2 border-white bg-cover" src={IMAGES.DASHBOARD.JOB_IMAGE} alt="Company Logo" />
                                            <div className='text-left'>
                                                <div className='flex flex-col sm:flex-row items-center'>
                                                    <span className="block text-xl font-medium text-lableColor mr-4">{jobPost?.Company?.name
                                                    }</span>
  {new Date(jobPost?.updatedAt).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })}
                                                </div>
                                                <span className="block text-sm font-normal text-textlightColor">{jobPost?.location}</span>
                                            </div>
                                        </div>
                                        <CloseButton onClick={handleClose} />
                                    </div>
                                    <div className="bg-cover bg-center h-40 rounded-t-lg" style={{ backgroundImage: `url(${IMAGES.DASHBOARD.BACKGROUND_IMAGE})` }}>
                                        <div className="flex items-center justify-between h-full px-6 py-4 text-white">
                                            <div>
                                                <span className="block text-2xl font-semibold">{jobPost.title}</span>
                                                <span className="block text-sm font-normal">{jobPost.
work_type}</span>
                                                <span className="block text-sm font-normal">{jobPost.
job_type}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="space-y-2 bg-bgColor text-left p-4 rounded-b-lg">
                                        <h2 className="text-lg font-medium text-lableColor">Job Description</h2>
                                        <p className="text-sm text-gray-500" dangerouslySetInnerHTML={createMarkup()}>
            {/* {jobPost.description} */}
                                        </p>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    )
}
