import React, { useCallback} from "react";
import { CloudIcon, PdfIcon } from "../../../assets/svg";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import _ from "lodash";
import { onToastNotify } from "../../../core/Constant";

export const PdfUploadCard = ({setFileNFileSelected = () => {}, buttonLabel= 'Select your CV (.pdf)', file = {}, isFileSelected = false, removeSelectedFile = () => {}}) => {

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length < 2) {
      setFileNFileSelected(acceptedFiles[0], true);

    } else {
      onToastNotify("Sorry, Cannot select multiple files. Please select a single file (.pdf)");
      setFileNFileSelected(null, false);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


  
  return (
    <div>
      {!isFileSelected ? (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <div className="bg-[#EAF3F6] border border-dashed flex items-center justify-center rounded-xl border-gray-500 p-4 h-20">
              <span className="font-DM text-gray-400 font-medium text-center">
                Drag and drop files here
              </span>
            </div>
          ) : (
            <div className="bg-[#EAF3F6] rounded-lg p-3 w-full flex cursor-pointer items-center justify-between">
              <span className="font-DM text-darkBlue font-medium ">
               {buttonLabel}
              </span>

              <CloudIcon />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-row items-center w-fit rounded-xl  bg-liteGray2">
          <div className="pl-4">
            <PdfIcon />
          </div>
          <span className="text-lableColor  ml-2 font-DM  text-sm">
            {_.get(file,'name')}
          </span>
          <button className="bg-darkGray rounded-tr-[13px] rounded-br-[13px]  items-center justify-center" onClick={removeSelectedFile}>
            <Icon />
          </button>
        </div>
      )}
    </div>
  );
};

const Icon = () => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="55" height="55" rx="27.5" fill="" />
      <g clip-path="url(#clip0_176_2564)">
        <path
          d="M30.0533 27.9232L39.1866 18.7897C39.7809 18.1956 39.7809 17.235 39.1866 16.6409C38.5925 16.0468 37.6319 16.0468 37.0378 16.6409L27.9043 25.7744L18.771 16.6409C18.1766 16.0468 17.2163 16.0468 16.6222 16.6409C16.0279 17.235 16.0279 18.1956 16.6222 18.7897L25.7555 27.9232L16.6222 37.0567C16.0279 37.6508 16.0279 38.6114 16.6222 39.2055C16.9183 39.5019 17.3076 39.6507 17.6966 39.6507C18.0856 39.6507 18.4747 39.5019 18.771 39.2055L27.9043 30.072L37.0378 39.2055C37.3341 39.5019 37.7232 39.6507 38.1122 39.6507C38.5012 39.6507 38.8902 39.5019 39.1866 39.2055C39.7809 38.6114 39.7809 37.6508 39.1866 37.0567L30.0533 27.9232Z"
          fill="#99A2BF"
        />
      </g>
      <defs>
        <clipPath id="clip0_176_2564">
          <rect
            width="23.4559"
            height="23.4559"
            fill="white"
            transform="translate(16.1765 16.1763)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
