import React, { useEffect, useState } from "react";

export const ProgressBar = ({progress = 0, setProgress = () => {}}) => {
    const intervalSpeed = 10;
    const incrementSpeed = 1;
  
    useEffect(() => {
    //   const progressInterval = setInterval(() => {
    //     setProgress(prevProgress => {
    //       if (prevProgress >= 2) {
    //         clearInterval(progressInterval);
    //         return prevProgress; // Ensure progress stops at 2%
    //       }
    //       return prevProgress + incrementSpeed;
    //     });
    //   }, intervalSpeed);
  
      // Cleanup function to clear interval when component unmounts
    //   return () => clearInterval(progressInterval);
    }, []);
  
  return (
    <div className="h-8 relative w-full rounded-md overflow-hidden mt-2">
      <div className="w-full h-full bg-[#D6E2F2] absolute "></div>
      <div
        className="h-full bg-[#4283E5] relative flex justify-center items-center"
        style={{ width: `${progress}%` }}
      >
        <span className="font-DM text-white font-semibold">{`${progress}%`}</span>
      </div>
    </div>
  );
};
