import React, { useEffect, useState } from "react";
import { NotificationIcon } from "../../../assets/svg";
import { dateFromNow } from "../../../core/Constant";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import { button } from "@material-tailwind/react";
import { Action } from "history";

export const NotificationView = () => {
  const dispatch = useDispatch();
  const allNotifications = useSelector((state) =>
    state.home.get("allNotifications")
  );
  const unreadNotifications = useSelector((state) =>
    state.home.get("unreadNotifications")
  );
  const readNotifications = useSelector((state) =>
    state.home.get("readNotifications")
  );
  const [selectOption, setSelectedOption] = useState(0);

  useEffect(() => {
    dispatch(Actions.home.getALLNotifications());
    dispatch(Actions.home.getUnreadNotifications());
    dispatch(Actions.home.getReadNotifications());
  }, [dispatch]);

  const handleOnClickOption = (value) => {
    if (value == 0) {
      setSelectedOption(value);
    } else if (value == 1) {
      setSelectedOption(value);
    } else if (value == 2) {
      setSelectedOption(value);
    }
  };

  const onClickMarkAllAsRead = () => {
    dispatch(Actions.home.markAllNotificationsAsRead());
  }

  const onClickMarkAsRead = (value) => {
    dispatch(Actions.home.markNotifocationAsRead({notification_id : value}));
  }

  return (
    <div
      className="bg-white rounded-lg w-80 p-3 shadow-md"
      style={{ zIndex: 1200 }}
    >
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-1 m-1">
          <button
            className={`${
              selectOption == 0 ? "bg-primaryColor" : "bg-white"
            } rounded-lg border border-gray-200 shadow-sm p-1 w-16 font-DM ${
              selectOption == 0 ? "text-white" : "text-gray-600"
            }  font-semibold text-sm  hover:bg-primaryColorHover hover:text-white`}
            onClick={() => handleOnClickOption(0)}
          >
            All
          </button>
          <button
            className={`${
              selectOption == 1 ? "bg-primaryColor" : "bg-white"
            } rounded-lg border border-gray-200 shadow-sm p-1 w-16 font-DM ${
              selectOption == 1 ? "text-white" : "text-gray-600"
            }  font-semibold text-sm  hover:bg-primaryColorHover hover:text-white`}
            onClick={() => handleOnClickOption(1)}
          >
            Unread
          </button>
          <button
            className={`${
              selectOption == 2 ? "bg-primaryColor" : "bg-white"
            } rounded-lg border border-gray-200 shadow-sm p-1 w-16 font-DM ${
              selectOption == 2 ? "text-white" : "text-gray-600"
            }  font-semibold text-sm  hover:bg-primaryColorHover hover:text-white`}
            onClick={() => handleOnClickOption(2)}
          >
            Read
          </button>
        </div>
        <button className="shadow rounded p-1 mb-1" title="Mark all as read" onClick={onClickMarkAllAsRead}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={"24px"}
            height={"24px"}
            viewBox="0 0 512 512"
          >
            <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32l224 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-224 0c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32l224 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-224 0c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32l288 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-288 0c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
          </svg>
        </button>
      </div>
      <hr className="bg-blue-gray-500" />
      <div
        className="max-h-[400px] overflow-y-auto
  [&::-webkit-scrollbar]:w-1
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 pe-1 "
      >
        {selectOption == 0 ? (
          <div>
            {_.map(allNotifications, (item, index) => {
              return (
                <div
                  className="shadow rounded-lg grid grid-cols-8 p-1 ps-2 pe-2 mt-2 mb-1"
                  key={index + 1}
                 
                >
                  <div className="col-span-7 m-1">
                    <div>
                      <span className="font-DM font-medium text-darkBlue line-clamp-2 text-[12px]">
                        {_.get(item, "message")}
                      </span>
                    </div>

                    <div>
                      <span className="font-DM text-primaryColor text-[12px]">
                        {dateFromNow(_.get(item, "createdAt"))}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-1 flex items-center justify-end">
                    <div>
                      {_.get(item, "is_read") ? (
                        <div className=" bg-darkGray font-semibold rounded-md p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={15}
                            height={15}
                             fill="#ffffff"
                            viewBox="0 0 448 512"
                          >
                            <path d="M342.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 402.7 54.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z" />
                          </svg>
                        </div>
                      ) : (
                        <button className=" bg-primaryColor  font-semibold rounded-md p-1"  onClick={() =>  onClickMarkAsRead(_.get(item, "id"))}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={15}
                            height={15}
                            fill="#ffffff"
                            viewBox="0 0 448 512"
                          >
                            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        {selectOption == 1 ? (
          <div>
            {unreadNotifications.length > 0 ? 
            _.map(unreadNotifications, (item, index) => {
              return (
                <div
                  className="shadow rounded-lg grid grid-cols-8 p-1 ps-2 pe-2 mt-2  mb-1"
                  key={index + 1}
                >
                  <div className="col-span-7 m-1">
                    <div>
                      <span className="font-DM font-medium text-darkBlue line-clamp-2 text-[12px]">
                        {_.get(item, "message")}
                      </span>
                    </div>

                    <div>
                      <span className="font-DM font-medium text-primaryColor text-[12px]">
                        {dateFromNow(_.get(item, "createdAt"))}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-1 flex items-center justify-end">
                  <div>
                      {_.get(item, "is_read") ? (
                        <div className=" bg-darkGray font-semibold rounded-md p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={15}
                            height={15}
                             fill="#ffffff"
                            viewBox="0 0 448 512"
                          >
                            <path d="M342.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 402.7 54.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z" />
                          </svg>
                        </div>
                      ) : (
                        <button className=" bg-primaryColor  font-semibold rounded-md p-1"  onClick={() =>  onClickMarkAsRead(_.get(item, "id"))}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={15}
                            height={15}
                            fill="#ffffff"
                            viewBox="0 0 448 512"
                          >
                            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            }) : <div className="p-2 w-full h-20 flex justify-center items-center">
              <span className="font-DM font-semibold text-darkBlue">
                All Caught Up
              </span>
              <NotificationIcon width={26} height={26}/>
              </div>}
            
          </div>
        ) : null}

        {selectOption == 2 ? (
          <div>
            {_.map(readNotifications, (item, index) => {
              return (
                <div
                  className="shadow rounded-lg grid grid-cols-8 p-1 ps-2 pe-2 mt-2  mb-1"
                  key={index + 1}
                >
                  <div className="col-span-7 m-1">
                    <div>
                      <span className="font-DM font-medium text-darkBlue line-clamp-2 text-[12px]">
                        {_.get(item, "message")}
                      </span>
                    </div>

                    <div>
                      <span className="font-DM font-medium text-primaryColor text-[12px]">
                        {dateFromNow(_.get(item, "createdAt"))}
                      </span>
                    </div>
                  </div>
                  <div className="col-span-1 flex items-center justify-end">
                  <div>
                      {_.get(item, "is_read") ? (
                        <div className=" bg-darkGray font-semibold rounded-md p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={15}
                            height={15}
                             fill="#ffffff"
                            viewBox="0 0 448 512"
                          >
                            <path d="M342.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 402.7 54.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z" />
                          </svg>
                        </div>
                      ) : (
                        <button className=" bg-primaryColor  font-semibold rounded-md p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={15}
                            height={15}
                            fill="#ffffff"
                            viewBox="0 0 448 512"
                          >
                            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
