import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTrash } from "react-icons/fa";
import { DropdownWithTextInput, StepperComponent, ButtonComponent } from "../../atoms";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { SuccessMessageModel } from "../../molecule/Modals";
import { onToastNotify } from "../../../core/Constant";

export const RecruiterStepThree = ({ setUpdateCandidateStep = () => {} }) => {
  const userDetails = useSelector((state) => state.auth.get("userDetails"));
  const [companyLinks, setCompanyLinks] = useState([
    { name: "Facebook", url: "" },
    { name: "Instagram", url: "" },
  ]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const options = [
    { label: "Facebook", value: "Facebook", icon: <FaFacebook /> },
    { label: "Instagram", value: "Instagram", icon: <FaInstagram /> },
    { label: "LinkedIn", value: "LinkedIn", icon: <FaLinkedin /> },
  ];

  const handleOptionChange = (index, value) => {
    const updatedLinks = [...companyLinks];
    updatedLinks[index].name = value;
    setCompanyLinks(updatedLinks);
  };

  const handleInputChange = (index, e) => {
    const updatedLinks = [...companyLinks];
    updatedLinks[index].url = e.target.value;
    setCompanyLinks(updatedLinks);
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = companyLinks.filter((_, i) => i !== index);
    setCompanyLinks(updatedLinks);
  };

  const onCreateAccount = () => {
    console.log(userDetails,'user detaillsss')
    const fd = new FormData();
    fd.append("email", userDetails?.email);
    fd.append("password", userDetails.password ? userDetails?.password : "" );
    fd.append("confirm_password", userDetails.confirmPassword ? userDetails?.password : "");
    fd.append("company_name", userDetails?.company);
    fd.append('google_id', userDetails?.google_id);
    fd.append('microsoft_id', userDetails?.microsoft_id);
    fd.append("profile_image", userDetails?.image);
    fd.append("company_industry_id",userDetails?.industryId);
    fd.append("company_size", userDetails?.company_size);
    fd.append("company_about", userDetails?.about);
    companyLinks.forEach((link, index) => {
      fd.append(`company_links[${index}][name]`, link.name);
      fd.append(`company_links[${index}][url]`, link.url);
    });

    const hasEmptyUrl = companyLinks.some(link => link.url.trim() === "");
    
    // if (hasEmptyUrl) {
    //   onToastNotify("Select URL",{message:'Please Add Urls',status_code:0})
    //   return;
    // }
    // else{
    //   console.log(companyLinks,'linkssss')
    //   dispatch(Actions.auth.recruitersignUp(fd));
    // }


    console.log(fd, userDetails);
    for (let [key, value] of fd.entries()) {
      console.log(`${key}: ${value}`);
    }
    dispatch(Actions.auth.recruitersignUp(fd));
  };

  return (
    <>
      <div className="sign-in-form-container">
        <div className="back-button-container">
          <StepperComponent totalSteps={3} currentStep={3} />
        </div>

        {companyLinks.map((link, index) => (
          <div
            key={index}
            className="w-full 2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-2/3 mb-4 relative group"
          >
            <DropdownWithTextInput
              label={`Share your company links ${index + 1}`}
              options={options}
              selectedOption={link.name}
              onOptionChange={(value) => handleOptionChange(index, value)}
              value={link.url}
              onChange={(e) => handleInputChange(index, e)}
            />
            <button
              className="absolute right-0 top-0 mt-2 mr-2 text-red-500 hover:text-red-700 opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={() => handleRemoveLink(index)}
            >
              <FaTrash />
            </button>
          </div>
        ))}

        <button
          className="w-full bg-[#D6E2F2] flex justify-center p-2 gap-x-3 rounded-md items-center 2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-2/3 mb-4"
          onClick={() => setCompanyLinks([...companyLinks, { name: "", url: "" }])}
        >
          <p className="text-sm font-DM text-darkBlue font-semibold"> Add </p>
        </button>

        <div className="submit-button flex items-center gap-3 w-full 2xl:w-1/3 xl:w-1/3 lg:w-3/5 md:w-3/5 mb-4">
          <ButtonComponent
            padding="p-2"
            text="Back"
            width="w-full"
            isPrimary={false}
            onClick={() => setUpdateCandidateStep(5)}
          />
          <ButtonComponent
            padding="p-2"
            text="Continue"
            width="w-full"
            isPrimary={true}
            onClick={onCreateAccount}
          />
        </div>

        <div>
          <p className="font-DM font-medium text-lableColor">
            Already have an account?
            <span className="text-primaryColor font-semibold cursor-pointer">
              Login
            </span>
          </p>
        </div>
      </div>

      <SuccessMessageModel onClose={() => setOpen(false)} open={open} />
    </>
  );
};
