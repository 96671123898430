import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../../core/modules/Actions';
import { CareerAdviceCard } from '../../../components';

export const PostExploreMoreScreen = () => {
    const dispatch = useDispatch();
    const posts = useSelector((state) => state.home.get("careerPostList"));
  
    useEffect(() => {
      dispatch(Actions.home.getCareerAdvicePostList());
    }, [dispatch]);
  
    useEffect(() => {
    }, [posts]);
  return (
    <div className="subscreen-container">
    <div className="flex flex-col ">
      <span class="text-lableColor font-DM text-3xl font-bold">
        Career Advices
      </span>
    </div>
    {posts?.length > 0 ? (
    <div className="job-listing-container">
      {posts?.map((item, index) => {
        return (
          <div className="col-span-1" key={index + 1}>
           <CareerAdviceCard key={index} post={item}/>
          </div>
        );
      })}
    </div>
         ): (<>
          <div className="flex justify-center items-center max-h-svh min-h-[180px]">
               <span className="font-DM text-3xl text-gray-400 text-center">Blog post not available...!
                 </span>
             </div>
         </>)}
  </div>
  )
}
