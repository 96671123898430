import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IMAGES } from "../../../assets";
import { AUTH_PROCESS_STEPS, EMAIL_REGEX, getText } from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";
import {
  ButtonComponent,
  HeadingComponent,
  TextFieldComponent,
} from "../../atoms";
import { navigateTo } from "../../../core/services/NavigationServicd";

export const SignUpFormRecruiter = ({ createAccount = () => { } }) => {
  const dispatch = useDispatch();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState('');
  const [sso, setSso] = useState(false)
  const [profileToken, setProfileDataToken] = useState(null);
  const [signupProcess, setSignupProcess] = useState(false);
  const [microsoft_id, setMicrosoft] = useState();
  const [google_id, setGoogle] = useState();
  const [message, setMessage] = useState();




  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      setProfileDataToken(token);
    }
    const process = params.get('process');
    const resource = params.get('res');
    console.log(process,'processssssssss')
    if (process) {
      if (resource == 'google') {
        setGoogle(process);
      } else {
        setMicrosoft(process);
      }

      setSignupProcess(process);
      setSso(true)
    }
    const urlEmail = params.get('email');

    if (urlEmail) {
      setEmail(urlEmail);
    }
    const urlMessage = params.get('message');
    if (urlMessage) {
      setMessage(urlMessage);
    }

    if (signupProcess) {
      // console.log(google_id,microsoft_id,'ssoooooo')
      dispatch(Actions.auth.storeUserDetails({ email, google_id, microsoft_id }));
      navigateTo("/onboarding-process");
    }

  }, []);


  const onEmailChange = (e) => {
    const text = getText(e);
    let isValid = false;
    const reg = EMAIL_REGEX;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setEmail(text);
    setIsEmailValid(isValid);
    setEmailError(isValid ? false : true);
  };

  const onClickCreateAccount = () => {
   console.log(sso,google_id,microsoft_id)
    if (isEmailValid) {
  
      if (sso && (google_id || microsoft_id)) {
        dispatch(Actions.auth.storeUserDetails({ email, google_id, microsoft_id }));
        navigateTo("/onboarding-process");
      } else {
        dispatch(Actions.auth.storeUserDetails({ email }));
        dispatch(Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signUpFormPassword }));
      }
    
    }
    else {
      setEmailError(true);
    }
  };

  const onClickBackBtn = () => {
    dispatch(Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.create_account }))
  }


  const handleSignInWithGoogle = () => {
    window.location.href = "https://api.r60.archnix.dev/auth/google"; 
    setSso(true)
  };


  const handleMicrosoftLogin = () => {
    window.location.href = "https://api.r60.archnix.dev/auth/microsoft";
  };
  return (
    <div className="sign-in-form-container">
      <div className="back-button-container">
        <button onClick={onClickBackBtn}>
          <div className="button-content flex gap-x-2 items-center">
            <img src={IMAGES.COMMON.LEFT_ARRROW} alt="logo" />
            <p className="font-DM">Back</p>
          </div>
        </button>
      </div>
      <HeadingComponent
        headingText={"Sign up as a Recruiter"}
        description={""}
      />
      <div className="sign-in-top-section">
        <div onClick={handleSignInWithGoogle} className="sso-button-container">
          <div className="sso-button-content">
            <div className="button-logo">
              <img src={IMAGES.ONBORADING.GOOGLE_ICON} alt="btn-logo" />
            </div>
            <div className="button-text">Sign up with Google</div>
          </div>
        </div>

        <div onClick={handleMicrosoftLogin} className="sso-button-container">
          <div className="sso-button-content">
            <div className="button-logo">
              <img src={IMAGES.ONBORADING.MICROSOFT_ICON} alt="btn-logo" />
            </div>
            <div className="button-text">Sign up with Microsoft</div>
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <div class="flex-grow border-t border-gray-300"></div>
        <span class="mx-4 text-gray-500 text-md">or</span>
        <div class="flex-grow border-t border-gray-300"></div>
      </div>

      <div className="sign-in-content-wrapper">
        <TextFieldComponent
          type="text"
          label="Email"
          placeholder="Enter your email address"
          value={email}
          error={emailError}
          errortxt="Please enter a valid email"
          onChange={onEmailChange}
        />
      </div>

      <div className="submit-button">
        <ButtonComponent
          text="Create account "
          width="w-full"
          isPrimary={true}
          onClick={() => { onClickCreateAccount() }}
        />
      </div>
      {/* <div>
        <p className="font-DM font-medium text-lableColor">
          Already have an account?
          <span className="text-primaryColor font-semibold cursor-pointer">
            Login
          </span>
        </p>
      </div> */}
    </div>
  );
};