import {Map} from 'immutable';
import _ from 'lodash';
import {ModuleEvents} from './Actions';
//import { ModuleEvents as SigninEvent } from "../signin/Actions";

const InitialState = Map({
  loadingAction: {loading: false, action: {}},
  candidateDetails:[],
  resumeTemplates:[],
  coverLetterTemplates:[],
  candidateCv:[],
  companyDetails:[],
  recruiteredit:[],
  supportTickets:[],
  newTicket:{},

});

export const Reducer = (state = InitialState, action) => {
  const {payload, type} = action;
  switch (type) {
    case ModuleEvents.LOADING_STARTED: {
      return state.set('loadingAction', {loading: true, action: payload});
    }
    case ModuleEvents.LOADING_FINISHED: {
      return state.set('loadingAction', {loading: false, action: payload});
    }   

    case ModuleEvents.GET_RESUME_TEMPLATES: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        
        const data = _.get(payload, "data", {});
        return state.set("resumeTemplates", data);
      }
      return state;
    }


    case ModuleEvents.GET_CANDIDATE_DETAILS: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        
        const data = _.get(payload, "data", {});
        return state.set("candidateDetails", data);
      }
      return state;
    }

    case ModuleEvents.GET_COVER_LETTER_TEMPLATES: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", {});
        return state.set("coverLetterTemplates", data);
      }
      return state;
    }


    case ModuleEvents.GET_CANDIDATE_CV: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", {});
        return state.set("candidateCv", data);
      }
      return state;
    }


    case ModuleEvents.LOCATION_LIST: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", {});
        return state.set("locationList", data);
      }
      return state;
    }

    case ModuleEvents.GET_COMPANY_DETAILS: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        
        const data = _.get(payload, "data", {});
        return state.set("companyDetails", data);
      }
      return state;
    }

    case ModuleEvents.RECRUITER_EDIT: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        
        const data = _.get(payload, "data", {});
        return state.set("recruiteredit", data);
      }
      return state;
    }
    case ModuleEvents.SUPPORT_TICKET_LIST: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        
        const data = _.get(payload, "data", {});
        return state.set("supportTickets", data);
      }
      return state;
    }
    case ModuleEvents.CREATE_SUPPORT_TICKET: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        
        const data = _.get(payload, "data", {});
        return state.set("newTicket", data);
      }
      return state;
    }
    
  }

  
  return state;
};
