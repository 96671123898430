import { useEffect, useState } from "react";
import { IMAGES } from "../../../assets";
import {
  ButtonComponent,
  CheckboxComponent,
  HeadingComponent,
  TextFieldComponent,
} from "../../atoms";
import {
  AUTH_PROCESS_STEPS,
  EMAIL_REGEX,
  getText,
  MINIMUM_PASSWORD_CHARACTERS,
  USER_ROLE,
} from "../../../core/Constant";
import { PasswordField } from "../../atoms/Inputs";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { getUserType } from "../../../core/UtilMethods";

export const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [visibilty, setVisibility] = useState(false);
  const dispatch = useDispatch();


  const onEmailChange = (e) => {
    const text = getText(e);
    let isValid = false;
    const reg = EMAIL_REGEX;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setEmail(text);
    setIsEmailValid(isValid);
    setEmailError(isValid ? false : true);
  };

  const onPasswordChange = (e) => {
    const text = getText(e);
    let isValid = false;
    isValid = text.trim().length > MINIMUM_PASSWORD_CHARACTERS;
    setPassword(text);
    setIsPasswordValid(isValid);
    setPasswordError(isValid ? false : true);
  };

  const handleOnClick = () => {
    dispatch(Actions.auth.authStep({ step:AUTH_PROCESS_STEPS.create_account }))
  };
  const onClickEye = (value) => {
    setVisibility(value ? false : true);
  };

  const onLogin = () => {
    if (isEmailValid && isPasswordValid) {
      dispatch(Actions.auth.logIn({ email: email, password: password }));
    } else {
      if (!isEmailValid) {
        setEmailError(true);
      }
      if (!isPasswordValid) {
        setPasswordError(true);
      }
    }
  };

  const handleSignInWithGoogle = () => {
    window.location.href = "https://api.r60.archnix.dev/auth/google";
    //setSso(true)
  };

  const handleMicrosoftLogin = () => {
    window.location.href = "https://api.r60.archnix.dev/auth/microsoft";
  };

  const onClickForgotPassword = () => {
    dispatch(Actions.auth.authStep({ step:AUTH_PROCESS_STEPS.forgotPassword }))
  }
  return (
    <div className="sign-in-form-container">
      <HeadingComponent
        headingText={"Sign In"}
        description={"Enter your email and password to sign in!"}
      />
      <div className="sign-in-top-section">
        <div onClick={handleSignInWithGoogle} className="sso-button-container">
          <div className="sso-button-content">
            <div className="button-logo">
              <img src={IMAGES.ONBORADING.GOOGLE_ICON} alt="btn-logo" />
            </div>
            <div className="button-text">Sign in with Google</div>
          </div>
        </div>

        <div onClick={handleMicrosoftLogin} className="sso-button-container">
          <div className="sso-button-content">
            <div className="button-logo">
              <img src={IMAGES.ONBORADING.MICROSOFT_ICON} alt="btn-logo" />
            </div>
            <div className="button-text">Sign in with Microsoft</div>
          </div>
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="mx-4 text-gray-500 text-md">or</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>

      <div className="sign-in-content-wrapper">
        <TextFieldComponent
          type="text"
          label="Email"
          placeholder="Enter your email address"
          value={email}
          onChange={onEmailChange}
          error={emailError}
          errortxt="Please enter a valid email"
        />
        <PasswordField
          onChange={onPasswordChange}
          placeholder="Enter your Password here"
          type={visibilty ? "text" : "password"}
          value={password}
          isVisible={visibilty}
          error={passwordError}
          errortxt="Password is incorrect"
          onclickEyeBtn={() => onClickEye(visibilty)}
        />
      </div>

      <div className="grid grid-cols-2 grid-flow-row-dense items-center">
        <div className="logged-checkbox col-span-2 md:col-span-1  lg:col-span-1  xl:col-span-1  2xl:col-span-1 text-center 2xl:text-left xl:text-left lg:text-left md:text-left">
          <CheckboxComponent text={"Keep me logged in"} />
        </div>
        <div className="fp-text col-span-2 md:col-span-1  lg:col-span-1  xl:col-span-1  2xl:col-span-1">
          <p className="text-md font-DM font-semibold text-primaryColor text-center 2xl:text-right xl:text-right lg:text-right md:text-right cursor-pointer" onClick={onClickForgotPassword}>
            Forgot Password
          </p>
        </div>
      </div>

      <div className="submit-button">
        <ButtonComponent
          onClick={onLogin}
          text="Sign in "
          width="w-full"
          isPrimary={true}
        />
      </div>
      <div>
        <p className="font-DM font-medium text-lableColor">
          Not registered yet?
          <span
            className="text-primaryColor font-semibold cursor-pointer ps-1"
            onClick={() => handleOnClick()}
          >
            Create an Account
          </span>
        </p>
      </div>
    </div>
  );
};
