import {Map} from 'immutable';
import _ from 'lodash';
import {ModuleEvents} from './Actions';
//import { ModuleEvents as SigninEvent } from "../signin/Actions";

const InitialState = Map({
  loadingAction: {loading: false, action: {}},
  industryList:[],
  skillData:[],
  titleData:[],
  jobRoleList:[]
});

export const Reducer = (state = InitialState, action) => {
  const {payload, type} = action;
  switch (type) {
    case ModuleEvents.LOADING_STARTED: {
      return state.set('loadingAction', {loading: true, action: payload});
    }
    case ModuleEvents.LOADING_FINISHED: {
      return state.set('loadingAction', {loading: false, action: payload});
    } 
    case ModuleEvents.GET_INDUSTRY_LIST: {
      if (payload && !payload.error  && payload.status_code == 1) {
        const data = _.get(payload, 'data', []);
        return state.set('industryList', data);

      }
      return state
    } 
    case ModuleEvents.GET_SKILL_LIST: {
      if (payload && !payload.error  && payload.status_code == 1) {
        const data = _.get(payload, 'data.list', []);
        return state.set('skillData', data);

      }
      return state
    } 


    case ModuleEvents.GET_TITLE_LIST: {
      if (payload && !payload.error  && payload.status_code == 1) {
        const data = _.get(payload, 'data', []);
        return state.set('titleData', data);

      }
      return state
    } 

    case ModuleEvents.GET_JOB_ROLE_LIST: {
      if (payload && !payload.error  && payload.status_code == 1) {
        const data = _.get(payload, 'data', []);
        return state.set('jobRoleList', data);

      }
      return state
    } 
  }
  

  return state;
};
