import { createAction } from "../../core/AppUtils";
import JobRepository from "../../core/repository/JobRepository";

export const ModuleEvents = {
  LOADING_STARTED: "LOADING_STARTED",
  LOADING_FINISHED: "LOADING_FINISHED",
  NETWORK_ERROR: "NETWORK_ERROR",

  SUBMIT_OFFER_ACCEPT : 'SUBMIT_OFFER_ACCEPT',
  SUBMIT_OFFER_REJECT : 'SUBMIT_OFFER_REJECT'
  
};

export default {
  networkError: createAction(ModuleEvents.NETWORK_ERROR, (error) => error),
  loadingStarted: createAction(
    ModuleEvents.LOADING_STARTED,
    (action) => action
  ),
  loadingFinished: createAction(
    ModuleEvents.LOADING_FINISHED,
    (action) => action
  ),

  acceptResults: createAction(
    ModuleEvents.SUBMIT_OFFER_ACCEPT,
    async (offerId,token) => {
      return await JobRepository.candidateOfferAccept(offerId,token);
    }
  ),

  rejectResults: createAction(
    ModuleEvents.SUBMIT_OFFER_REJECT,
    async (offerId,token) => {
      return await JobRepository.candidateOfferReject(offerId,token);
    }
  ),
 
};
