import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { formatUrl, IMAGE_URL } from "../../../core/Constant";
import _ from "lodash";
import { ButtonComponent } from "../../../components/atoms";
import { useNavigate } from "react-router-dom";

export const ScreeningQuestionsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questions = useSelector((state) =>
    state.home.get("screeningQuestionsList")
  );
  useEffect(() => {
    dispatch(Actions.home.getScreeningQuestionsList());
  }, [dispatch]);

  useEffect(() => {
  }, [questions]);

  const onClickAttempt = (value) => {
  if(!_.get(value, "is_screening_attempted")){
    const url = `/screening-quiz-attempt/${_.get(value,'job.Company.name')}/${_.get(value,'job.title')}`
    navigate(formatUrl(url),{state:{job_id:_.get(value,'job_id')}})
  } 
  }
  return (
    <div className="subscreen-container">
      <div className="">
        <span class="text-lableColor font-DM text-3xl font-[500]">
          Screening Questions
        </span>
      </div>

      <div className="grid grid-flow-row-dense grid-cols-1 gap-2">
      {questions && questions.length > 0 ? (
        _.map(questions, (quiz, index) => {
          function createMarkup() {
            return { __html: _.get(quiz, "job.description") };
        }
          return (
            <div className="w-full bg-blue-50 rounded-xl p-2" key={index + 1}>
              <div className="grid grid-cols-1 sm:grid-cols-12 gap-3">
                <div className="sm:col-span-2">
                  <img
                    src={IMAGE_URL + _.get(quiz, "job.job_image")}
                    alt="job-image"
                    className="object-cover w-full h-full rounded-xl"
                  />
                </div>
                <div className="sm:col-span-7">
                  <div className="flex flex-col h-full items-start gap-1">
                    <div className="flex flex-wrap gap-2 items-baseline">
                      <span className="font-DM font-semibold text-darkBlue text-xl">
                        {_.get(quiz, "job.title")}
                      </span>
                      <span className="font-DM font-medium text-darkBlue text-sm">
                        {_.get(quiz, "job.work_type")}
                      </span>
                    </div>
                    <span className="font-DM font-semibold text-darkBlue text-sm">
                      {_.get(quiz, "job.job_type")}
                    </span>

                    <span className="font-DM font-normal text-darkBlue text-sm">
                      {_.get(quiz, "job.Company.name")}
                    </span>

                    <span className="font-DM font-normal text-darkBlue text-sm">
                      {_.get(quiz, "job.location")}
                    </span>

                    <span className="font-DM font-normal text-darkBlue text-sm" dangerouslySetInnerHTML={createMarkup()}>
                      {/* {_.get(quiz, "job.description")} */}
                    </span>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <div className="flex flex-col h-full justify-center items-end gap-4">
                    {_.get(quiz, "is_screening_attempted") ? (
                      <div className="p-2">
                        <span className="font-DM font-semibold text-red">
                          Quiz Attempted
                        </span>
                      </div>
                    ) : (
                      <div className="p-2">
                        <span className="font-DM font-semibold text-green-500">
                          Quiz Not Attempted
                        </span>
                      </div>
                    )}

                    <ButtonComponent
                      text="Attempt Quiz"
                      isDisabled={_.get(quiz, "is_screening_attempted")}
                      onClick={() => onClickAttempt(quiz)}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="flex justify-center items-center max-h-svh min-h-[150px]">
         <span className="font-DM text-3xl text-gray-400 text-center">
         No quiz available</span>
       </div>
    )}
      </div>
    </div>
  );
};
