import React from 'react';

export const TabComponent = ({ tabList, tabContent, activeTab, setActiveTab }) => {
    return (
        <div className="text-sm font-medium text-center text-gray-500 border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex flex-wrap">
                {tabList.map((tab, index) => (
                    <li className="me-2" key={index}>
                        <a
                            // href="#"
                            onClick={() => setActiveTab(tab)}
                            className={`block p-4 font-DM font-normal text-[16px] border-b-2 ${activeTab === tab
                                ? 'text-primaryColor border-primaryColor rounded-t-lg active dark:text-primaryColor dark:border-primaryColor'
                                : 'border-gray-300'
                                }`}
                            aria-current={activeTab === tab ? 'page' : null}
                        >
                            {tab}
                        </a>
                    </li>
                ))}
            </ul>

            {/* Render content based on active tab */}
            {tabContent[activeTab]}
        </div>
    );
};
