export const MyJobsIcon = ({isSelected = false}) => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_141)">
        <path d="M15.0931 3.3335H2.47354C1.43669 3.3335 0.597656 4.1535 0.597656 5.16683V7.3335H6.7369C6.7369 6.45816 7.28875 6.00016 7.93064 6.00016H9.63599C10.2779 6.00016 10.8297 6.45816 10.8297 7.3335H16.969V5.16683C16.969 4.1535 16.1299 3.3335 15.0931 3.3335Z" fill="#99A2BF"/>
        <path d="M10.8297 8.6665C10.8297 9.54184 10.2779 9.99984 9.63599 9.99984H7.93064C7.28875 9.99984 6.7369 9.54184 6.7369 8.6665H0.597656V12.8332C0.597656 13.8465 1.43669 14.6665 2.47354 14.6665H15.0931C16.1299 14.6665 16.969 13.8465 16.969 12.8332V8.6665H10.8297Z" fill="#99A2BF"/>
        <path d="M11.0002 4.3335C10.7178 4.3335 10.4886 4.1095 10.4886 3.8335V2.50016C10.4886 2.40816 10.4122 2.3335 10.3181 2.3335H7.24843C7.15429 2.3335 7.07789 2.40816 7.07789 2.50016V3.8335C7.07789 4.1095 6.8487 4.3335 6.56629 4.3335C6.28389 4.3335 6.05469 4.1095 6.05469 3.8335V2.50016C6.05469 1.85683 6.59017 1.3335 7.24843 1.3335H10.3181C10.9763 1.3335 11.5118 1.85683 11.5118 2.50016V3.8335C11.5118 4.1095 11.2826 4.3335 11.0002 4.3335Z" fill="#99A2BF"/>
        </g>
        <defs>
        <clipPath id="clip0_2_141">
        <rect width="16.3713" height="16" fill="white" transform="translate(0.597656)"/>
        </clipPath>
        </defs>
        </svg>
    )
}