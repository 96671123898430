import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ButtonComponent,
  CheckboxComponent,
  SkillCardlet,
  TextAreaComponent,
  TextFieldComponent,
} from "../../atoms";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { EMAIL_REGEX, getText, PHONE_REGEX } from "../../../core/Constant";
import { set } from "lodash";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";

export const JobApplyModal = ({ jobId, onClose, open = false, applyingJob}) => {
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch(); 
  const [companyEmail, setCompanyEmail] = useState("");
  const [errorCompanyEmail, setErrorCompanyEmail] = useState(false);

  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState(false);

  const [contactNo, setConatactNo] = useState("");
  const [errorContactNo, setErrorContactNo] = useState(false);

  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const [attachCV, setAttachCV] = useState(0);
  const [reciveEmail, setReciveEmail] =useState(0);

  const onNameChange = (e) => {
    const text = getText(e)
    setName(text);
    setErrorName(text.length <= 0);
    // setEmailError(false);
};
   
  const onCompanyEmailChange = (e) => {
    const text = getText(e)
    let isValid = false;
    const reg = EMAIL_REGEX;
    if (text.trim().length > 3) {
        isValid = reg.test(text.trim());
    }
    setCompanyEmail(text);
    setErrorCompanyEmail(!isValid);
    // setEmailError(false);
};

  const onEmailChange = (e) => {
    const text = getText(e)
    let isValid = false;
    const reg = EMAIL_REGEX;
    if (text.trim().length > 3) {
        isValid = reg.test(text.trim());
    }
    setEmail(text);
    setErrorEmail(!isValid);
    // setEmailError(false);
};


const onContactNoChange = (e) => {
    const text = getText(e)
    let isValid = false;
    const reg = PHONE_REGEX;
    if (text.trim().length > 3) {
        isValid = reg.test(text.trim());
    }
    setConatactNo(text);
    setErrorContactNo(!isValid);
    // setEmailError(false);
};


const onMessageChange = (e) => {
    const text = getText(e)
    // let isValid = false;
    // const reg = EMAIL_REGEX;
    // if (text.trim().length > 3) {
    //     isValid = reg.test(text.trim());
    // }
    setMessage(text);
    setErrorMessage(text.length <= 0);
    // setEmailError(false);
};


const onChangeAttachCv = (e) => {
    const text = e?.target?.checked;
    console.log(text, 'lklklklklk')
};


const onChangeReciveEmail = (e) => {
    const text = e?.target?.checked;
    console.log(text, 'lklklklklk')
};

const handleApply = () => {
    dispatch(Actions.jobs.applyJob(jobId, {
    name:name, // required
    contact_number:contactNo, // required
    message: message, // required
    copy_of_resume: 1, //required | 0 or 1
    receive_application_to_email: reciveEmail // required | 0 or 1
    }));

    handleClear();
    onClose();
}

  const handleClear = () => {
    setCompanyEmail("");
    setErrorEmail(false);
    setMessage("");
    setErrorMessage(false);
    setName("");
    setErrorName(false);
    setConatactNo("");
    setErrorContactNo(false);
    setEmail("");
    setErrorEmail(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg  bg-black w-full min-w-[813px] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div class="flex flex-row w-full justify-between bg-bgColor rounded-lg items-center">
                        <div class="flex flex-row w-full">
                          <span class=" text-lableColor font-DM font-[500] ml-5 text-[28px]">
                            Application for {applyingJob}
                          </span>
                        </div>
                        <CloseButton onClick={onClose} />
                      </div>

                      <div class="w-full my-8  h-auto flex flex-col items-start">
                        {/* <TextFieldComponent
                          error={false}
                          placeholder="Type company email here"
                          isdisabled={false}
                          errortxt="Company email is required!"
                          label="Company Email*"
                          value={companyEmail}
                          onChange={(e) => onCompanyEmailChange(e)}
                        /> */}
                        <div className="w-full justify-between flex flex-row">
                          <TextFieldComponent
                            error={false}
                            placeholder="type here"
                            isdisabled={false}
                            errortxt="Your name is required!"
                            label="Your Name"
                            value={name}
                            onChange={(e) => onNameChange(e)}
                          />
                          <div className="w-[35px]"></div>
                          <TextFieldComponent
                            error={false}
                            placeholder="Type contact number  here"
                            isdisabled={false}
                            errortxt="Contact no is required!"
                            label="Contact Number "
                            value={contactNo}
                            onChange={(e) => onContactNoChange(e)}
                          />
                        </div>
                        <TextFieldComponent
                          error={false}
                          placeholder="Type Email here"
                          isdisabled={false}
                          errortxt="Email address is required!"
                          label="Email Address"
                          value={email}
                          onChange={(e) => onEmailChange(e)}
                        />
                        <TextAreaComponent
                          label="Message"
                          placeholder="Type your message here"
                          draggble={false}
                          isdisabled={false}
                          value={message}
                          onChange={(e) => onMessageChange(e)}
                        />
                      </div>
                      <div>
                        {/* <CheckboxComponent
                        value={attachCV}
                        onChange={(e)=> onChangeAttachCv(e)}
                          text={"Attach a copy of your resume"}
                        /> */}
                        <CheckboxComponent
                          text={
                            "I want to receive a copy of this application to my email"
                          }
                          value={reciveEmail}
                        onChange={(e)=> onChangeReciveEmail(e)}
                        />
                      </div>
                      <div className="mt-2 w-full  items-center justify-center flex flex-row rounded-[16px]">
                        <div className=" min-w-[420px] justify-center items-center flex gap-3">
                          <ButtonComponent
                            isPrimary={false}
                            text="Clear"
                            onClick={handleClear}
                          />
                          <ButtonComponent text="Submit"   onClick={handleApply}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const SaveICcon = () => {
  return (
    <svg
      width="55"
      height="48"
      viewBox="0 0 55 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="54" height="47" rx="4.5" stroke="white" />
      <path
        d="M33.7143 16H22.2857C21.0286 16 20.0114 16.9 20.0114 18L20 34L28 31L36 34V18C36 16.9 34.9714 16 33.7143 16Z"
        fill="white"
      />
    </svg>
  );
};
