import React, { useEffect, useRef } from 'react'
import { Header } from '../Header'
import { Outlet, useNavigate } from 'react-router-dom'
import { SidebarProfileContainer } from '../Sidebar/SideBarProfileContainer'
import { setTopLevelNavigator } from '../../../core/services/NavigationServicd'

export const ProfileLayout = () => {
  const intervalRef = useRef(null);
  const navigate = useNavigate();
  const onNavigate = (path, obj = {}) => {
    if (path) {
        navigate(path, obj);
    }
}
  useEffect(()=>{
    intervalRef.current = onNavigate;
    setTopLevelNavigator(intervalRef);
  },[navigate])
  return (
    <div className="main-container">
    <div>
      <Header />
    </div>
    <div className="grid grid-flow-row-dense 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-1 mt-4 gap-3 p-2">
      <div className="2xl:col-span-2 xl:col-span-2 lg:col-span-2  xl:block lg:block md:hidden sm:hidden small-screen-sidebar">
        <SidebarProfileContainer/>
      </div>
      <div className="w-full 2xl:col-span-10 xl:col-span-10 lg:col-span-10">
        <Outlet />
      </div>
    </div>
  </div>
  )
}
