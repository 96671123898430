export const VideoIcon = ({ isSelected = false }) => {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <rect width="36" height="36" rx="8" fill="#EAF3F6" />
    //   <path
    //     d="M19.7473 12.125H11.0788C9.93545 12.125 9 13.1097 9 14.3132V21.6874C9 22.8909 9.93545 23.8755 11.0788 23.8755H19.7473C20.8906 23.8755 21.826 22.8909 21.826 21.6874V14.3132C21.826 13.0878 20.8906 12.125 19.7473 12.125Z"
    //     fill={isSelected ? '#4283E5':'#2B3674'}
    //   />
    //   <path
    //     d="M26.4618 13.3282C26.337 13.3501 26.2123 13.4157 26.1084 13.4814L22.8655 15.4508V20.5273L26.1292 22.4967C26.732 22.8687 27.4804 22.6499 27.8337 22.0153C27.9377 21.8184 28.0001 21.5995 28.0001 21.3588V14.5974C28.0001 13.7877 27.2725 13.1313 26.4618 13.3282Z"
    //     fill="#2B3674"
    //   />
    // </svg>
    <>
      {isSelected ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          width="22.5"
          viewBox="0 0 576 512"
        >
          <path
            fill="#213250"
            d="M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          width="25"
          viewBox="0 0 640 512"
        >
          <path
            fill="#1d2f4e"
            d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7l-86.4-67.7 13.8 9.2c9.8 6.5 22.4 7.2 32.9 1.6s16.9-16.4 16.9-28.2l0-256c0-11.8-6.5-22.6-16.9-28.2s-23-5-32.9 1.6l-96 64L448 174.9l0 17.1 0 128 0 5.8-32-25.1L416 128c0-35.3-28.7-64-64-64L113.9 64 38.8 5.1zM407 416.7L32.3 121.5c-.2 2.1-.3 4.3-.3 6.5l0 256c0 35.3 28.7 64 64 64l256 0c23.4 0 43.9-12.6 55-31.3z"
          />
        </svg>
      )}
    </>
  );
};
