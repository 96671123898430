import {Map} from 'immutable';
import _ from 'lodash';
import {ModuleEvents} from './Actions';
//import { ModuleEvents as SigninEvent } from "../signin/Actions";

const InitialState = Map({
  loadingAction: {loading: false, action: {}},
  locationList: [],
  candidateStoreDetails:{}
});

export const Reducer = (state = InitialState, action) => {
  const {payload, type} = action;
  switch (type) {
    case ModuleEvents.LOADING_STARTED: {
      return state.set('loadingAction', {loading: true, action: payload});
    }
    case ModuleEvents.LOADING_FINISHED: {
      return state.set('loadingAction', {loading: false, action: payload});
    } 
       
    case ModuleEvents.LOCATION_LIST: {
      if (
        payload &&
        payload.status_code == 1
      ) {
        const data = _.get(payload, "data", {});
        return state.set("locationList", data);
      }
      return state;
    }

    case ModuleEvents.STORE_CANDIDATE_DETAILS: {
      return state.set('candidateStoreDetails', { ...state.get('candidateStoreDetails'), ...payload });
    }
  }
  

  return state;
};
