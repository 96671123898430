export const TemplatesIcon = ({isSelected =  false}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0173 14.668C10.8527 14.668 11.5696 14.6241 12.1685 14.491C12.7751 14.3562 13.3009 14.1212 13.711 13.711C14.1212 13.3009 14.3562 12.7751 14.491 12.1685C14.6241 11.5696 14.668 10.8527 14.668 10.0173V8.0013C14.668 7.2649 14.071 6.66797 13.3346 6.66797H8.0013C7.2649 6.66797 6.66797 7.2649 6.66797 8.0013V13.3346C6.66797 14.071 7.2649 14.668 8.0013 14.668H10.0173Z"
         fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M1.33398 10.0173C1.33398 10.8527 1.37788 11.5696 1.511 12.1685C1.6458 12.7751 1.88079 13.3009 2.29094 13.711C2.70108 14.1212 3.22687 14.3562 3.83342 14.491C3.8915 14.5038 3.94988 14.516 4.0085 14.5273C4.73151 14.667 5.33398 14.055 5.33398 13.3186V8.0013C5.33398 7.2649 4.73703 6.66797 4.00065 6.66797H2.66732C1.93094 6.66797 1.33398 7.2649 1.33398 8.0013V10.0173Z"
         fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M5.98445 1.33398C5.14903 1.33398 4.4322 1.37788 3.83322 1.511C3.22667 1.6458 2.70088 1.88079 2.29074 2.29094C1.88059 2.70108 1.6456 3.22687 1.5108 3.83342C1.49973 3.88322 1.48932 3.93319 1.47954 3.9833C1.33662 4.71576 1.95974 5.33398 2.706 5.33398H13.3178C14.0542 5.33398 14.6661 4.73151 14.5265 4.0085C14.5151 3.94988 14.503 3.8915 14.4901 3.83342C14.3553 3.22687 14.1203 2.70108 13.7101 2.29094C13.3 1.88079 12.7742 1.6458 12.1677 1.511C11.5687 1.37788 10.8519 1.33398 10.0165 1.33398H5.98445Z"
         fill={isSelected ? '#4283E5':'#2B3674'}
      />
    </svg>
  );
};
