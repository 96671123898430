import React, { useEffect } from "react";
import {
  EditProfileForm,
  ProfileDetailCard,
  ResumeCard,
} from "../../../components";
import { ButtonComponent } from "../../../components/atoms";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

export default ({ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resumesList = useSelector((state) =>
    state.profile.get("resumeTemplates")
  );

  const coverLetterList = useSelector((state) =>
    state.profile.get("coverLetterTemplates")
  );
  useEffect(() => {
    dispatch(Actions.profile.getResumeTemplates());
    dispatch(Actions.profile.getCoverLetterTemplates());
  },[dispatch]);
  return (
    <div className="bg-white rounded-lg p-6">
      <div className="resume-templates">
        <p class="text-lableColor font-DM text-2xl font-bold">Resume Templates</p>
        <div className="grid grid-flow-row-dense 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 gap-2 pt-4 pb-4">
          {_.map(resumesList, ((item, index) => {
            return( <ResumeCard details={item} key={index + 1} />)
          }))}
        </div>

        <div className="flex justify-center">
          <div className="w-36">
            <ButtonComponent text="View More" padding="p-2" isPrimary={false} onClick={() => navigate('/view_all/resume_templates')}/>
          </div>
        </div>
      </div>

      <div className="cover-letters">
        <p class="text-lableColor font-DM text-2xl font-bold">Cover letter templates</p>
        <div className="grid grid-flow-row-dense 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 gap-2 pt-4 pb-4">
        {_.map(coverLetterList, ((item, index) => {
            return( <ResumeCard details={item} key={index + 1} />)
          }))}
        </div>

        <div className="flex justify-center items-center">
          <div className="w-36">
            <ButtonComponent text="View More" padding="p-2" isPrimary={false} onClick={()=> navigate('/view_all/cover_letter_ideas')}/>
          </div>
        </div>
      </div>


    </div>
  );
};
