import React, { useState, useEffect } from "react";
import { DropdownComponent, TextAreaComponent, TextFieldComponent } from "../../../components/atoms";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { imageURL } from "../../../core/repository/Repository";
import { IMAGE_URL } from "../../../core/Constant";

const questionTypes = [
  { id: 1, name: "Text" },
  { id: 2, name: "MCQ" },
  { id: 3, name: "Video" },
];

const ScreeningQuestions = ({ getAllJobRecruiter, recruiterDashboard, createmcqQuestions, recruiterJobs, questionList, getScreeningQuestions, updateScreeningQestions, deleteScreeningQuestions,  loadingAction, }) => {
  const [questions, setQuestions] = useState([
    { id: null, type: { id: 1, name: "Text" }, content: "", options: [""], correctAnswer: null, videoFile: null }
  ]);

  const [jobPost, setJobPost] = useState("");
  const [jobs, setJobs] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [expandedQuestionIndex, setExpandedQuestionIndex] = useState(null); // State for expanded question
  const [video, setVideo] = useState(false)
  const [videoUpload, setUploadVideo] = useState(false);
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    getAllJobRecruiter(); // Fetch the dashboard data
  }, []);

  useEffect(() => {
    setJobs(recruiterJobs || []); // Set the jobs from the fetched data
  }, [recruiterJobs]);

  const handleAddQuestion = () => {
    setQuestions([...questions, { type: { id: 1, name: "Text" }, content: "", options: [""], correctAnswer: null, videoFile: null }]);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].content = value;
    setQuestions(newQuestions);
  };

  const handleQuestionTypeChange = (index, type) => {
    const newQuestions = [...questions];
    newQuestions[index].type = type;
    newQuestions[index].content = "";
    newQuestions[index].options = [""];
    newQuestions[index].correctAnswer = null;
    newQuestions[index].videoFile = null;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
  };

  const handleAddOption = (index) => {
    const newQuestions = [...questions];
    if (newQuestions[index].options.length < 4) {
      newQuestions[index].options.push("");
      setQuestions(newQuestions);
    }
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    if (newQuestions[questionIndex].correctAnswer === optionIndex) {
      newQuestions[questionIndex].correctAnswer = null; // Clear the correct answer if it's removed
    } else if (newQuestions[questionIndex].correctAnswer > optionIndex) {
      newQuestions[questionIndex].correctAnswer -= 1; // Adjust the correct answer index if needed
    }
    setQuestions(newQuestions);
  };

  const handleCorrectAnswerChange = (questionIndex, optionIndex) => {
    console.log(questionIndex, optionIndex, 'optionnn select')
    const newQuestions = [...questions];
    newQuestions[questionIndex].correctAnswer = optionIndex;
    console.log(newQuestions[questionIndex].correctAnswer, 'answerrrrrr')
    setQuestions(newQuestions);
  };

  const handleRemoveQuestion = (id, index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
    handleDeleteQuestion(id)
    if (expandedQuestionIndex === index) {
      setExpandedQuestionIndex(null); // Collapse the question if it was the expanded one
    }
  };

  const handleJobPostChange = (e) => {
    setQuestions([])
    setJobPost(e.target.value); // Handle typed value for job post selection
    setShowDropdown(true); // Show dropdown while typing
  };

  const handleJobPostSelect = (job) => {
    setJobPost(job.title); // Set the selected job post
    setSelectedJob(job); // Set the selected job details
    setShowDropdown(false); // Hide the dropdown options
  };

  const handleQuestionExpandToggle = (index) => {
    setExpandedQuestionIndex(expandedQuestionIndex === index ? null : index);
  };

  const handleVideoFileChange = (index, file) => {
    setVideo(true)
    const newQuestions = [...questions];
    newQuestions[index].videoFile = file;
    newQuestions[index].videoPreview = URL.createObjectURL(file); // Create preview URL
    setQuestions(newQuestions);
  };


  const handleDeleteQuestion = async (id) => {
    try {
      await deleteScreeningQuestions({ question_id: id });  // Pass the question id to the delete action
      //  setQuestions((prevQuestions) => prevQuestions.filter((q) => q.id !== id));  // Remove question from local state
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  useEffect(() => {
    const { action, loading } = loadingAction;
    console.log(loading,'lodingggg status')
    const type = action.type;
    if(loading){
      setUploadVideo(loading);
    }
    if ((type == "CREATE_MCQ_QUESTIONS" || type == "UPDATE_QUESTIONS") && !loading) {
      setUploadVideo(loading);
      getScreeningQuestions(selectedJob?.id);
    }
  }, [loadingAction]);




  useEffect(() => {
    if (selectedJob) {
      getScreeningQuestions(selectedJob?.id); // Fetch existing questions for the selected job
    }
  }, [selectedJob]);

  useEffect(() => {
    if (videoUpload) {
      const interval = setInterval(() => {
        setDotCount(prevCount => (prevCount % 3) + 1); // Cycle through 1 to 3 dots
      }, 500); // Change the number of dots every 500ms

      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [videoUpload]);



  useEffect(() => {
  
    if (questionList?.length > 0 && selectedJob != null) {
      const mappedQuestions = questionList.map((question) => {
        if (question.type === "MCQ") {
          return {
            id: question.id,  // Ensure id is mapped here
            type: { id: 2, name: "MCQ" },
            content: question.description,
            options: question.mcqAnswers.map((answer) => answer.option_description),
            correctAnswer: question.mcqAnswers.findIndex((answer) => answer.is_correct),
            videoFile: null,
          };
        } else if (question.type === "Video") {
          return {
            id: question.id,  // Ensure id is mapped here
            type: { id: 3, name: "Video" },
            content: question.description,
            videoFile: question.video_url,
            videoPreview: IMAGE_URL + question.video_url,
          };
        } else {
          return {
            id: question.id,  // Ensure id is mapped here
            type: { id: 1, name: "Text" },
            content: question.description,
            options: [""],
            correctAnswer: null,
            videoFile: null,
          };
        }
      });

      setQuestions(mappedQuestions);  // Set the existing questions with their IDs
    }else{
      setQuestions([]); 
    }
  }, [questionList]);


  const onpostQuestion = async () => {
    for (const question of questions) {
      let payload;

      // If question type is 'MCQ' or 'Text'
      if (question.type.name === "Text" || question.type.name === "MCQ") {
        payload = {
          job_id: selectedJob?.id,
          description: question.content,
          type: question.type.name, // Either "Text" or "MCQ"
        };

        // If MCQ, add options and correct answer
        if (question.type.name === "MCQ") {
          payload.mcqAnswers = question.options.map((option, index) => ({
            option_description: option,
            is_correct: question.correctAnswer === index,
          }));
        }

        try {
          if (question.id) {
            // Update existing question
            await Actions.home.updateScreeningQestions(question.id, payload); // Assuming you have an update API
          } else {
            // Create new question
            await createmcqQuestions(payload);
          }
          console.log("Question submitted:", payload);
        } catch (error) {
          console.error("Error submitting question:", error);
        }
      }

      // If question type is 'Video', send as FormData
      if (question.type.name === "Video") {
        const formData = new FormData();
        formData.append("job_id", selectedJob?.id);
        formData.append("description", question.content);
        formData.append("type", "Video");
        formData.append("video_url", question.videoFile);

        try {
          if (question.id) {
            // Update existing video question
            await Actions.home.updateScreeningQestions(question.id, formData); // Assuming you have an update API for video
          } else {
            // Create new video question
            await createmcqQuestions(formData);
          }
          console.log("Video question submitted:", formData);
        } catch (error) {
          console.error("Error submitting video question:", error);
        }
      }
    }
  };

  const handleSubmitQuestion = async (question) => {
    let payload;

    if (question.type.name === "Text" || question.type.name === "MCQ") {
      payload = {
        job_id: selectedJob?.id,
        description: question.content,
        type: question.type.name,
      };

      if (question.type.name === "MCQ") {
        payload.mcqAnswers = question.options.map((option, index) => ({
          option_description: option,
          is_correct: question.correctAnswer === index,
        }));
      }

      if (question.id) {
        payload.question_id = question.id;
      }

      try {
        if (question.id) {
          console.log(payload, 'payloaddddd')
          await updateScreeningQestions(payload);
        } else {
          await createmcqQuestions(payload);
        }
        console.log("Question submitted:", payload);
      } catch (error) {
        console.error("Error submitting question:", error);
      }
    }

    if (question.type.name === "Video") {
      const formData = new FormData();
      formData.append("job_id", selectedJob?.id);
      formData.append("description", question.content);
      formData.append("type", "Video");
      formData.append("video_url", video ? question.videoFile : '');
      formData.append("question_id", question.id);

      try {
        if (question.id) {
          await updateScreeningQestions(formData);
        } else {
          await createmcqQuestions(formData);
        }
        console.log("Video question submitted:", formData);
      } catch (error) {
        console.error("Error submitting video question:", error);
      }
    }
  };


  function createMarkup() {
    return { __html: selectedJob.description };
  }


  return (
    <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
      <div className="col-span-12">
        <div className="bg-white p-6 rounded-xl">
          <p className="text-[28px] text-darkBlue font-bold font-DM">Screening Questions</p>
          <p className="text-base text-ash font-normal font-DM">Customize Screening Questions for Job Post</p>

          <div className="relative">
            <TextFieldComponent
               placeholder="Please type &  select job post"
              value={jobPost}
              onChange={handleJobPostChange}
              label="Job Post"
            />
            {showDropdown && jobPost && (
              <div className="absolute z-10 bg-white shadow-md max-h-40 overflow-y-auto w-full">
                {jobs
                  .filter((job) => job.title.toLowerCase().includes(jobPost.toLowerCase()))
                  .map((job, index) => (
                    <div
                      key={index}
                      className="p-2 cursor-pointer hover:bg-blue-200"
                      onClick={() => handleJobPostSelect(job)}
                    >
                      {job.title}
                    </div>
                  ))}
              </div>
            )}
          </div>

          {selectedJob && (
            <div className="mt-4 border p-4 rounded-lg bg-gray-100 flex items-start">
              <img
                src={IMAGE_URL + selectedJob.job_image}
                alt={`${selectedJob?.title} image`}
                className="w-20 h-20 rounded-full object-cover mr-4"
              />
              <div>
                <p>
                  <strong>Job Title:</strong> {selectedJob.title}
                </p>
                <p>
                  <strong>Job Description:</strong> <p dangerouslySetInnerHTML={createMarkup()}></p>
                </p>
                <p>
                  <strong>Location:</strong> {selectedJob.location}
                </p>
              </div>
            </div>
          )}

          {questions.map((question, index) => (
            <div key={index} className="my-4">
              <div
                className="cursor-pointer mb-2  text-blue-500 font-bold justify-between flex font-DM"
                onClick={() => handleQuestionExpandToggle(index)}
              >
                {`Question ${index + 1}: ${question.content || question.type.name}`}
                <button
                  className="text-red ml-4 font-DM"
                  onClick={() => handleRemoveQuestion(question.id, index)}
                >
                  Delete
                </button>
              </div>
              {expandedQuestionIndex === index && (
                <div className="p-4 border rounded-lg bg-gray-50">
                  <DropdownComponent
                    label={`Question Type`}
                    value={question.type}
                    dropdownOptions={questionTypes}
                    onOptionSelected={(type) => handleQuestionTypeChange(index, type)}
                  />
                  {question.type.name === "Text" && (
                    <TextAreaComponent
                      value={question.content}
                      onChange={(e) => handleQuestionChange(index, e.target.value)}
                      placeholder="Enter your question"
                    />
                  )}
                  {question.type.name === "MCQ" && (
                    <div>
                      <TextAreaComponent
                        placeholder={`Add an MCQ question #${index + 1}`}
                        value={question.content}
                        onChange={(e) => handleQuestionChange(index, e.target.value)}
                        label="Question"
                      />
                      {question.options.map((option, optionIndex) => (
                        <div key={optionIndex} className="flex items-center mb-2">
                          {/* <input
                          type="text"
                          value={option}
                          onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                          placeholder={`Option ${optionIndex + 1}`}
                          className="border rounded p-2"
                        /> */}
                          <TextFieldComponent
                            placeholder={`Option${optionIndex + 1}`}
                            value={option}
                            onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                            label={`Option${optionIndex + 1}`}
                          />
                          <button
                            className="ml-2 text-red-500 font-DM"
                            onClick={() => handleRemoveOption(index, optionIndex)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        className="mt-2 text-blue-500 font-DM"
                        onClick={() => handleAddOption(index)}
                      >
                        Add Option
                      </button>
                      <div className="mt-2  overflow-hidden">
                        <label className="block">Select Correct Answer</label>
                        {/* <select
                        className="border rounded p-2"
                        value={question.correctAnswer || ""}
                        onChange={(e) => handleCorrectAnswerChange(index, parseInt(e.target.value))}
                      >
                        <option value="">Select Correct Answer</option>
                        {question.options.map((option, optionIndex) => (
                          <option key={optionIndex} value={optionIndex}>
                             {option}
                          </option>
                        ))}
                      </select> */}
                        {/* <div className="grid grid-cols-4 gap-2">
                        {question.options.map((option, optionIndex) => (
                          <button
                            key={optionIndex}
                            className={`p-2 border rounded ${
                              question.correctAnswer === optionIndex
                                ? "bg-blue-500 text-white"
                                : "bg-white"
                            }`}
                            onClick={() =>
                              handleCorrectAnswerChange(index, optionIndex)
                            }
                          >
                            {option}
                          </button>
                        ))}
                        </div> */}
                        <div className="grid grid-cols-4 gap-2">
                          {question.options.map((option, optionIndex) => (
                            <div key={optionIndex} className="flex items-center">
                              <input
                                type="radio"
                                id={`option-${index}-${optionIndex}`}
                                name={`question-${index}-correct-answer`}
                                value={optionIndex}
                                checked={question.correctAnswer === optionIndex}
                                onChange={() => handleCorrectAnswerChange(index, optionIndex)}
                                className="mr-2"
                              />

                              <span
                                className={`p-2`}
                              >
                                {option}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {question.type.name === "Video" && (
                    <div>
                      <TextAreaComponent
                        placeholder={`Add a video question #${index + 1}`}
                        value={question.content} // Description of the video question
                        onChange={(e) => handleQuestionChange(index, e.target.value)}
                        label="Video Question Description"
                      />
                      <input
                        type="file"
                        accept="video/*"
                        onChange={(e) => handleVideoFileChange(index, e.target.files[0])}
                        className="mb-2"
                      />
                      {question.videoFile && (
                        <video
                          controls
                          src={question.videoPreview}
                          className="mt-2 w-full"
                        />
                      )}
                    </div>
                  )}
                  <button
                    className="mt-4 text-blue-500 font-DM"
                    onClick={() => handleSubmitQuestion(question)}
                  >
                    Submit
                  </button>
                  <p className="mt-4 text-red font-DM">
      {videoUpload ? (
        <>
          Video Uploading
          {[...Array(dotCount)].map((_, index) => (
            <span key={index} className="dot">.</span>
          ))}
        </>
      ) : ''}
    </p>

                </div>
              )}
            </div>
          ))}

          <button
            className="mt-4 bg-blue-500 font-DM text-white py-2 px-4 rounded"
            onClick={handleAddQuestion}
          >
            Add Question
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    recruiterJobs: state.jobs.get("recruiterJobs"),
    jobPost: state.jobs.get("jobPost"),
    questionList: state.home.get('questionList'),
    loadingAction: state.home.get("loadingAction"),
  }),
  {
    getAllJobRecruiter: Actions.jobs.getAllJobRecruiter,
    getJobPost: Actions.jobs.getJobPost,
    createmcqQuestions: Actions.home.createmcqQuestions,
    getScreeningQuestions: Actions.home.getScreeningQuestions,
    updateScreeningQestions: Actions.home.updateScreeningQestions,
    deleteScreeningQuestions: Actions.home.deleteScreeningQuestions
  }
)(ScreeningQuestions);
