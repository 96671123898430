export const ScreeningIcon = ({ isSelected = false }) => {
    return (
        <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8"
          cy="8"
          r="8"
          fill={isSelected ? '#4283E5' : '#2B3674'}
        />
        <path
          d="M7.99935 4.52637C7.15935 4.52637 6.52637 5.15935 6.52637 6.02148C6.52637 6.31982 6.77213 6.56567 7.07031 6.56567H7.10435C7.38067 6.56567 7.57813 6.36957 7.57813 6.09335C7.57813 5.89379 7.74435 5.72656 7.99935 5.72656C8.25435 5.72656 8.42057 5.89379 8.42057 6.09335C8.42057 6.32507 8.28035 6.53156 8.07813 6.65007C7.57749 6.93935 7.33569 7.3978 7.33569 7.91836V8.11567C7.33569 8.41385 7.58154 8.65961 7.87982 8.65961H8.12018C8.41836 8.65961 8.6642 8.41385 8.6642 8.11567V7.91836C8.6642 7.5482 8.84902 7.20693 9.17215 7.03082C9.62791 6.77326 9.91402 6.28806 9.91402 5.72656C9.91402 4.85742 9.14228 4.08935 7.99935 4.08935ZM8 10.0898C7.71182 10.0898 7.46606 10.3346 7.46606 10.6228V10.8838C7.46606 11.1719 7.71182 11.4167 8 11.4167H8.26099C8.54917 11.4167 8.79493 11.1719 8.79493 10.8838V10.6228C8.79493 10.3346 8.54917 10.0898 8.26099 10.0898H8Z"
          fill="white"
        />
      </svg>
      );
  };
  