import React from "react";

export const TabButtonComponent = ({
  isApplied = true,
  isThirdTab = false,
  isFourthTab = false,
  onClick = () => {},
  buttonOneName = "Applied Jobs",
  buttonTwoName = "Saved Jobs",
  buttonThreeName = "Archived Jobs",
  buttonFourName='',
  showThirdTab = true,
  showFourthTab = false,
  isFourthEnable = false
}) => {
  return (
    <div className={`tab-btn-container grid ${isFourthEnable ? 'grid-cols-4' : ''} grid-cols-${showThirdTab ? '3' :  '2'}`}>
      <div className="col-span-1" onClick={() => onClick("applied")}>
        <div
          className={`button-content ${
            isApplied ? "bg-[#EDF6FE]" : "bg-white"
          }`}
        >
          <div className="col-span-3 pe-3 ps-3">
            <p
              className={`text-center font-DM ${
                isApplied ? "text-darkBlue" : "text-darkGray"
              }`}
            >
              {buttonOneName}
            </p>
          </div>
        </div>
      </div>
      {showFourthTab ? (
      <div className="col-span-1" onClick={() => onClick("screening")}>
       
          <div
            className={`button-content ${
              isFourthTab ? "bg-[#EDF6FE]" : "bg-white"
            }`}
          >
            <div className="col-span-3 pe-3 ps-3">
              <p
                className={`text-balance align-middle text-center font-DM ${
                  isFourthTab ? "text-darkBlue" : "text-darkGray"
                }`}
              >
                {buttonFourName}
              </p>
            </div>
          </div>
       
      </div>
       ) : null}
      <div className="col-span-1" onClick={() => onClick("qualified")}>
        <div
          className={`button-content ${
            !isApplied && !isThirdTab && !isFourthTab ? "bg-[#EDF6FE]" : "bg-white"
          }`}
        >
          <div className="col-span-3 pe-3 ps-3">
            <p
              className={`text-balance font-DM ${
                !isApplied && !isThirdTab  && !isFourthTab ? "text-darkBlue" : "text-darkGray"
              }`}
            >
              {buttonTwoName}
            </p>
          </div>
        </div>
      </div>
      {showThirdTab ? (
      <div className="col-span-1" onClick={() => onClick("rejected")}>
       
          <div
            className={`button-content ${
              isThirdTab  ? "bg-[#EDF6FE]" : "bg-white"
            }`}
          >
            <div className="col-span-3 pe-3 ps-3">
              <p
                className={`text-balance align-middle text-center font-DM ${
                  isThirdTab ? "text-darkBlue" : "text-darkGray"
                }`}
              >
                {buttonThreeName}
              </p>
            </div>
          </div>
       
      </div>
       ) : null}
  
    </div>
  );
};
