import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ButtonComponent,
  OutlineButtonComponent,
  SkillCardlet,
  TextAreaComponent,
  TextFieldComponent,
} from "../../atoms";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { IMAGES } from "../../../assets";
import _ from "lodash";
import { dateFromNow, getText } from "../../../core/Constant";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";

export const AddNotesModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [titleErrorMsg, setTitleErrorMsg] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setdescriptionError] = useState(false);
  const [descriptionErrorMsg, setdescriptionErrorMsg] = useState("");

  const onChangeTitle = (e) => {
    const text = getText(e);
    setTitle(text);
    setTitleError(title.length <= 0);
    setTitleErrorMsg("Please enter title");
  };

  const onChangeDescription = (e) => {
    const text = getText(e);
    setDescription(text);
    setdescriptionError(description.length <= 0);
    setdescriptionErrorMsg("Please enter a description");
  };

  const handleSave = () => {
    if (!titleError && !descriptionError) {
        dispatch(Actions.home.addNote({
            title: title,
            description: description,
        }));

        setTitle("");
        setDescription("");
        setTitleError(false);
        setdescriptionError(false);
        onClose();
    } else {
      if (titleError) {
        setTitleError(true);
      }
      if (descriptionError) {
        setdescriptionError(true);
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg  bg-black  text-left shadow-xl transition-all my-8 w-[375px]">
                <div className="bg-white p-4">
                  <div className="innter-container ">
                    <TextFieldComponent
                      placeholder="Title"
                      error={titleError}
                      errortxt={titleErrorMsg}
                      onChange={(e) => onChangeTitle(e)}
                      value={title}
                    />
                    <TextAreaComponent
                      placeholder="Note"
                      error={descriptionError}
                      errortxt={descriptionErrorMsg}
                      onChange={(e) => onChangeDescription(e)}
                      value={description}
                      rows={5}
                    />
                  </div>

                  <div className="button-section flex flex-col gap-y-2">
                    <div className="grid grid-cols-3 gap-x-2 justify-end">
                      <div className="">
                        <OutlineButtonComponent
                          isPrimary={true}
                          text="Close"
                          onClick={onClose}
                        />
                      </div>
                      <div className="">
                        <ButtonComponent
                          text="Save"
                          isPrimary={true}
                          onClick={handleSave}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
