import React from "react";

export const RedDot = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="15" height="15" rx="7.5" fill="#E56060" />
    </svg>
  );
};
