import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../../core/modules/Actions';
import { ResumeCard } from '../../../components';

export const ViewCoverLettersScreen = () => {
    const dispatch = useDispatch();
    const coverLetterList = useSelector((state) =>
      state.profile.get("coverLetterTemplates")
    );
    useEffect(() => {
      dispatch(Actions.profile.getCoverLetterTemplates());
    }, [dispatch]);
  
    useEffect(() => {
    }, [coverLetterList]);
  return (
    <div className="subscreen-container">
    <div className="flex flex-col ">
      <span class="text-lableColor font-DM text-3xl font-bold">
        Cover Letter Idas
      </span>
    </div>

    <div className="job-listing-container">
      {coverLetterList?.map((item, index) => {
        return (
          <div className="col-span-1" key={index + 1}>
           <ResumeCard details={item} />
          </div>
        );
      })}
    </div>
  </div>
  )
}
