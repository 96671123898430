import {Map} from 'immutable';
import _ from 'lodash';
import {ModuleEvents} from './Actions';

const InitialState = Map({
  loadingAction: {loading: false, action: {}},
  submitResults: {}
});

export const Reducer = (state = InitialState, action) => {
  const {payload, type} = action;
  switch (type) {
    case ModuleEvents.LOADING_STARTED: {
      return state.set('loadingAction', {loading: true, action: payload});
    }
    case ModuleEvents.LOADING_FINISHED: {
      return state.set('loadingAction', {loading: false, action: payload});
    }   

    case ModuleEvents.SUBMIT_OFFER_ACCEPT: {
      if (payload) {
        return state.set("submitResults", payload);
      }
      return state;
    }
    case ModuleEvents.SUBMIT_OFFER_REJECT: {
      if (payload) {
        return state.set("submitResults", payload);
      }
      return state;
    }

  }
  

  return state;
};
