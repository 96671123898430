import React from "react";

export const FavouriteButton = ({
  text = "text",
  isPrimary = false,
  isDisabled = false,
  // isIcon=false,
  onClick = () => {},
  favourite = false,
}) => {
  return (
    <button
      className="bg-white rounded-full flex   w-8 h-8  items-center justify-center"
      onClick={onClick}
    >
      {favourite ? <SelectedIcon /> : <Icon />}
    </button>
  );
};

const Icon = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6715 1.05543C9.18293 0.0213835 7.34474 0.503938 6.35235 1.68735C5.35995 0.503938 3.52177 0.0156388 2.03318 1.05543C1.24378 1.60692 0.74758 2.53756 0.713749 3.5199C0.634808 5.74885 2.57449 7.53545 5.53475 10.2757L5.59114 10.3274C6.01967 10.7238 6.67939 10.7238 7.10792 10.3216L7.16995 10.2642C10.1302 7.5297 12.0642 5.7431 11.9909 3.51416C11.9571 2.53756 11.4609 1.60692 10.6715 1.05543ZM6.40873 9.41971L6.35235 9.47715L6.29596 9.41971C3.61199 6.94374 1.84147 5.3065 1.84147 3.64629C1.84147 2.49735 2.68726 1.63564 3.81498 1.63564C4.68332 1.63564 5.52911 2.20437 5.82796 2.99139H6.88238C7.17558 2.20437 8.02137 1.63564 8.88972 1.63564C10.0174 1.63564 10.8632 2.49735 10.8632 3.64629C10.8632 5.3065 9.09271 6.94374 6.40873 9.41971Z"
        fill="#2B3674"
      />
    </svg>
  );
};

const SelectedIcon = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3393 0.642831C8.8572 -0.391214 7.02697 0.0913403 6.03887 1.27475C5.05077 0.0913403 3.22055 -0.396959 1.7384 0.642831C0.95241 1.19432 0.458361 2.12496 0.424676 3.10731C0.346077 5.33625 2.27736 7.12285 5.22481 9.86307L5.28096 9.91477C5.70763 10.3112 6.3645 10.3112 6.79117 9.90903L6.85293 9.85158C9.80038 7.1171 11.7261 5.3305 11.6531 3.10156C11.6194 2.12496 11.1253 1.19432 10.3393 0.642831ZM6.09501 9.00711L6.03887 9.06456L7.66632 7.67447C4.99397 5.19851 8.00049 6.16055 8.00049 4.50034C8.00049 3.3514 5.87765 3.00034 7.00049 3.00034C7.86507 3.00034 5.2192 1.79177 5.51675 2.57879L6.00049 9.50034C6.29243 8.71331 7.6359 7.50034 8.50049 7.50034C9.62333 7.50034 8.50049 4.8514 8.50049 6.00034C8.50049 7.66055 8.76737 6.53114 6.09501 9.00711Z"
        fill="#4283E5"
      />
    </svg>
  );
};
