import React from 'react'
import { Outlet } from 'react-router-dom'
import { IMAGES } from '../../../assets'
import { Disclosure } from '@headlessui/react'

export const OfferVerificationLayout = () => {
  return (
    <div className="main-container">
      <div>
        <div className="relative z-50">
          <Disclosure as="nav" className="bg-white sticky">
            <div className="mx-auto px-2 sm:px-6 lg:px-8 sticky">
              <div className="relative flex pt-2 pb-2 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex  items-center">
                    <img
                      className="object-contain 2xl:w-40 xl:w-36 lg:w-32 md:w-32 sm: w-36"
                      src={IMAGES.COMMON.NAVBAR_IMG}
                      alt="Your Company"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Disclosure>
        </div>
      </div>
      <div className="grid grid-flow-row-dense 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-1 mt-4 gap-3 p-2">
        <div className="w-full 2xl:col-span-12 xl:col-span-12 lg:col-span-12">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
