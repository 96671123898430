import { isDisabled } from "@testing-library/user-event/dist/utils";
import React from "react";

export const TextFieldComponent = ({
  placeholder = "",
  isdisabled = false,
  label = "",
  error = false,
  errortxt = "",
  type = "text",
  value = undefined,
  onChange = () => {},
  readOnly = false
}) => {
  return (
    <div className="input-field-wrap">
      <label className="input-label-txt">{label}</label>
      <input
        type={type}
        value={value}
        className={
          isdisabled
            ? "input-field-disabled"
            : error
            ? "error-input-field"
            : "input-field-basic"
        }
        readOnly={readOnly}
        onChange={onChange}
        placeholder={placeholder}
        disabled={isdisabled}
        style={{borderRadius:'8px'}}
      />
      {error && !isdisabled ? (
        <p className="input-error-txt">{errortxt}</p>
      ) : null}
    </div>
  );
};
