export const NotificationIcon = ({isSelected, width = 17, height= 17}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1363 13.9185C9.97335 14.6693 9.30043 15.2289 8.5071 15.2289C7.71376 15.2289 7.04085 14.6693 6.87793 13.9185H10.1363Z" fill="#2B3674"/>
        <path d="M14.117 11.5739C14.117 11.8289 14.0249 12.0414 13.8336 12.2327C12.8916 13.1748 9.90948 13.1748 8.47865 13.1677H8.15281C6.61573 13.1818 4.06573 13.1748 3.18031 12.2964C2.98198 12.091 2.88281 11.8573 2.88281 11.6093L3.42823 6.39601C3.71865 3.64768 5.90031 1.57227 8.4999 1.57227C9.75365 1.57227 10.9578 2.06102 11.8928 2.94643C12.8349 3.83185 13.4299 5.05726 13.5716 6.39601L14.117 11.5739Z" fill="#2B3674"/>
        </svg>
        



    )
}