import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import { IMAGE_URL } from "../../../core/Constant";

const CareerAdvicePost = ({
  getCareerAdvicesPost,
  careerPost
}) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [postImage, setPostImage] = useState("");
  const { id } = useParams();
  const navigateBack = useNavigate();

  useEffect(() => {
    getCareerAdvicesPost(id);
  }, []);

  useEffect(() => {
    setContent(_.get(careerPost, "content"));
    setTitle(_.get(careerPost, "title"));
    setPostImage(_.get(careerPost, "main_image_path"));
  }, [careerPost]);

  function createMarkup() {
    return { __html: content };
  }
  return (
    <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
      <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 ">
        <div className="bg-white p-4 rounded-xl">
          <button type="button" className="text-gray-400" onClick={() => navigateBack(-1)} >
            {"< Back"} </button>
          <p className="text-2xl text-darkBlue font-bold font-DM pb-1">{title}</p>
          <div
            className="w-full bg-no-repeat bg-cover h-[320px] rounded-md"
            style={{
              backgroundImage: `url(${IMAGE_URL + postImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          >
          </div>
          <div
            className="pt-3"
            dangerouslySetInnerHTML={createMarkup()}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    careerPost: state.home.get("careerPost"),
  }),
  {
    getCareerAdvicesPost: Actions.home.getCareerAdvicesPost,
  }
)(CareerAdvicePost);
