import React, { useEffect, useState } from "react";
import { IMAGES } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import { ButtonComponent } from "../../../components/atoms";
import { useLocation, useNavigate } from "react-router-dom";
import { IMAGE_URL } from "../../../core/Constant";

export const ScreeningQuestionsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const jobId = _.get(location, "state.job_id");
  const questions = useSelector((state) =>
    state.home.get("screeningQuestions")
  );
  const [userAnswers, setUserAnswers] = useState([]);
  useEffect(() => {
    dispatch(Actions.home.getQuestions(jobId));
  }, [dispatch]);

  useEffect(() => {

  }, [questions]);

  // const onSelectAnswer = (question, answer) => {
  //   const questionId = question.id;

  //   const existingAnswerIndex = userAnswers.findIndex(
  //     (ans) => ans.question_id === questionId
  //   );
  //   let action = "";

  //   if (existingAnswerIndex !== -1) {
  //     const updatedAnswers = [...userAnswers];

  //     if (updatedAnswers[existingAnswerIndex].mcq_answer) {
  //       const isAlreadySelected = updatedAnswers[existingAnswerIndex].mcq_answer.includes(answer.id);
  
  //       if (isAlreadySelected) {
  //         // Remove the answer id if already selected
  //         updatedAnswers[existingAnswerIndex].mcq_answer = updatedAnswers[existingAnswerIndex].mcq_answer.filter(
  //           (id) => id !== answer.id
  //         );
  //         action = "remove"; // Answer was removed
  //       } else {
  //         // Add the answer id if not already selected
  //         updatedAnswers[existingAnswerIndex].mcq_answer.push(answer.id);
  //         action = "check"; // Answer was checked
  //       }
  //     } else {
  //       // Initialize the mcq_answer array and add the answer id
  //       updatedAnswers[existingAnswerIndex].mcq_answer = [answer.id];
  //       action = "check"; // Answer was checked (first time)
  //     }
  
  //     setUserAnswers(updatedAnswers);
  //   } else {
  //     setUserAnswers((prev) => [
  //       ...prev,
  //       { question_id: questionId, mcq_answer: [answer.id] },
  //     ]);
  //     action = "check";
  //   }
  // };

  const onSelectAnswer = (questionId, answerId, isChecked) => {
    const existingAnswerIndex = userAnswers.findIndex(
      (ans) => ans.question_id == questionId
    );

    if (existingAnswerIndex !== -1) {
      const updatedAnswers = [...userAnswers];
      const existingAnswers = updatedAnswers[existingAnswerIndex].mcq_answer || [];

      console.log('userAnswers:',isChecked);
      console.log('ddd:',userAnswers.some(
        (ans) => ans.question_id == questionId && ans.mcq_answer?.includes(answerId)
      ));
      if (isChecked) {
        // Add the selected answer if it's checked
        updatedAnswers[existingAnswerIndex].mcq_answer = [...existingAnswers, answerId];
      } else {
        // Remove the answer if it's unchecked
        updatedAnswers[existingAnswerIndex].mcq_answer = existingAnswers.filter(
          (id) => id != answerId
        );
      }

      setUserAnswers(updatedAnswers);
    } else {
      setUserAnswers((prev) => [
        ...prev,
        { question_id: questionId, mcq_answer: isChecked ? [answerId] : [] },
      ]);
    }
  };

  const handleTextChange = (questionId, value) => {
    const existingAnswerIndex = userAnswers.findIndex(
      (ans) => ans.question_id === questionId
    );

    if (existingAnswerIndex !== -1) {
      const updatedAnswers = [...userAnswers];
      updatedAnswers[existingAnswerIndex].text_answer = value;
      setUserAnswers(updatedAnswers);
    } else {
      setUserAnswers((prev) => [
        ...prev,
        { question_id: questionId, text_answer: value },
      ]);
    }
  };

  const onClickSubmit = () => {
   const filteredAnswers = userAnswers.filter(
      (answer) => answer.mcq_answer?.length > 0 || answer.text_answer
    );
    const answers = {
      job_id : jobId,
      answer : filteredAnswers
    }
   
    dispatch(Actions.home.answerQuestions(answers));
    navigate("/screening-questions");
  };

  return (
    <div className="subscreen-container">
      <div className="">
        <span class="text-lableColor font-DM text-3xl font-[500]">
          Screening Questions
        </span>
      </div>

      {_.map(questions, (question, index) => {
        return (
          <div className="quiz-container p-2" key={index + 1}>
            <div className="question-wrap">
              <span className="font-DM font-medium">
                {`${index + 1}. ${_.get(question, "description")}`}
              </span>
            </div>

            {/* Resources */}
            {_.get(question, "video_url") !== null ? (
              <div className="w-full my-5">
                {/* <img
                  src={IMAGES.DASHBOARD.BACKGROUND_IMAGE}
                  alt="image"
                  className="object-cover w-96 h-60"
                /> */}
                <video width="400" controls className="w-2/3 h-auto rounded-xl">
                <source src={IMAGE_URL + _.get(question,'video_url')} type="video/mp4"/>
                </video>
              </div>
            ) : null}

            <div className="w-5/6 my-6">
              {/* single answer */}
              {/* <div className="grid sm:grid-cols-2 gap-2">
                <label
                  for="hs-radio-in-form"
                  className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
                >
                  <input
                    type="radio"
                    name="hs-radio-in-form"
                    className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                    id="hs-radio-in-form"
                  />
                  <span class="text-sm text-gray-500 ms-3 dark:text-neutral-400">
                    Default radio
                  </span>
                </label>
              </div> */}

              {/* Multiple answers */}
              {_.get(question, "type") == "MCQ" ? (
                <div class="grid sm:grid-cols-2 gap-2">
                  {_.map(_.get(question, "mcqAnswers"), (answer, index) => {
                    return (
                      <div
                      key={index + 1}
                      className="flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm cursor-pointer"
                      onClick={() => onSelectAnswer(question.id, answer.id, !userAnswers.some(
                        ans => ans.question_id == question.id && ans.mcq_answer?.includes(answer.id)
                      ))}
                    >
                      <input
                        type="checkbox"
                        id={`checkbox-${question.id}-${answer.id}`}
                        className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500"
                        checked={userAnswers.some(
                          (ans) => ans.question_id == question.id && ans.mcq_answer?.includes(answer.id)
                        )}
                        onChange={(e) =>
                          onSelectAnswer(question.id, answer.id, e.target.checked)
                        }
                        onClick={(e) => e.stopPropagation()} // Prevent double triggering from the div click
                      />
                      <label
                        htmlFor={`checkbox-${question.id}-${answer.id}`}
                        className="text-sm text-gray-500 ms-3 dark:text-neutral-400"
                        onClick={() => onSelectAnswer(question.id, answer.id, !userAnswers.some(
                          ans => ans.question_id == question.id && ans.mcq_answer?.includes(answer.id)
                        ))}
                      >
                         {_.get(answer, "id")} {_.get(answer, "option_description")}
                      </label>
                    </div>
                    );
                  })}
                </div>
              ) : null}

              {["Text", "Video"].includes(_.get(question, "type")) ? (
                <div class="max-w-xl space-y-3">
                  <textarea
                    class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                    rows="8"
                    placeholder="Type your answer here"
                    onChange={(e) =>
                      handleTextChange(question.id, e.target.value)
                    }
                  ></textarea>
                </div>
              ) : null}
            </div>

            <hr className="border-gray-300" />
          </div>
        );
      })}

      <div className="flex justify-end">
        <div className="w-1/4">
          <ButtonComponent text="Submit" onClick={onClickSubmit} />
        </div>
      </div>
    </div>
  );
};
