import React from "react";

export const TextAreaComponent = ({
  placeholder = "",
  isdisabled = false,
  label = "",
  error = false,
  errortxt = "",
  type = "text",
  value = undefined,
  onChange = () => {},
  rows = 0,
  cols = 0,
  draggble=false
}) => {
  return (
    <div className="input-field-wrap">
      <label className="input-label-txt">{label}</label>
      <textarea
        type={type}
        value={value}
        className={
          isdisabled
            ? "input-field-disabled resize-none min-h-[150px]"
            : !draggble ? "input-field-basic resize-none min-h-[150px]": error
            ? "error-input-field min-h-[150px]"
            : "input-field-basic min-h-[150px]"
        }

        onChange={onChange}
        placeholder={placeholder}
        disabled={isdisabled}
        rows={rows}
        cols={cols}
        style={{borderRadius:'8px'}}
      />
      {error && !isdisabled ? (
        <p className="input-error-txt">{errortxt}</p>
      ) : null}
    </div>
  );
};
