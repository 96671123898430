import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";

// Debounce function
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};

export const TypeAheadSearch = ({
  listData,
  placeholder,
  selectedItem = () => {},
  error = false,
  errortext = "",
  isLabelVisible = false,
  label = "",
  showOnlyName = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  // Function to filter the list based on the search term
  const filterList = useCallback(
    debounce((term) => {
      if (term) {
        const filtered = listData.filter((item) =>
          item.name.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredData(filtered);
        setShowDropdown(true);
      } else {
        setFilteredData([]);
        setShowDropdown(false);
      }
    }, 300), // Adjust delay as needed
    [listData]
  );

  const handleChange = (event) => {
    const value = event.target.value;
    if (value) {
      setShowDropdown(true);
    }
    setSearchTerm(value);
    filterList(value);
  };

  const handleSelect = (item) => {
    selectedItem(item);
    // setShowDropdown(false);
  };

  // Use effect to handle focus and blur
  useEffect(() => {
    const handleFocus = () => setShowDropdown(true);
    const handleBlur = () => setTimeout(() => setShowDropdown(false), 100);

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  return (
    <div
      className="type-ahead-search-container"
      onFocus={() => setShowDropdown(true)}
      onBlur={() => setTimeout(() => setShowDropdown(false), 250)}
    >
      {isLabelVisible ? (
        <span className="font-DM font-semibold text-darkBlue">{label}</span>
      ) : null}

      <input
        type="text"
        onChange={handleChange}
        value={searchTerm}
        placeholder={placeholder}
      />
      {error ? (
        <p className="mt-1 text-errorColor font-DM">{errortext}</p>
      ) : null}
      {filteredData.length > 0 && showDropdown ? (
        <div className="dropdown-list-wrapper ">
          <ol>
            {_.map(filteredData, (item, index) => {
              return (
                <div key={index + 1} onClick={() => handleSelect(item)}>
                  {!showOnlyName ? <li>
                    {`${_.get(item, "name")}, ${_.get(item, "country_name")}`}
                  </li> : <li>
                    {`${_.get(item, "name")}`},
                  </li>}
                  
                </div>
              );
            })}
          </ol>
        </div>
      ) : null}
    </div>
  );
};
