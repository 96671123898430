export const SettingIcon = ({ isSelected = false }) => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3334 14.5827H5.66673C5.29348 14.5829 4.92676 14.4848 4.60345 14.2983C4.28015 14.1118 4.01168 13.8434 3.82506 13.5202L0.991727 8.56185C0.80522 8.23881 0.707031 7.87236 0.707031 7.49935C0.707031 7.12633 0.80522 6.75989 0.991727 6.43685L3.82506 1.47852C4.01168 1.15527 4.28015 0.886891 4.60345 0.700371C4.92676 0.513852 5.29348 0.415777 5.66673 0.416016H11.3334C11.7066 0.415777 12.0734 0.513852 12.3967 0.700371C12.72 0.886891 12.9884 1.15527 13.1751 1.47852L16.0084 6.43685C16.1949 6.75989 16.2931 7.12633 16.2931 7.49935C16.2931 7.87236 16.1949 8.23881 16.0084 8.56185L13.1751 13.5202C12.9884 13.8434 12.72 14.1118 12.3967 14.2983C12.0734 14.4848 11.7066 14.5829 11.3334 14.5827ZM5.66673 1.83268C5.54191 1.83219 5.41918 1.86468 5.31096 1.92688C5.20275 1.98907 5.11288 2.07875 5.05048 2.18685L2.21714 7.14518C2.15497 7.25286 2.12225 7.37501 2.12225 7.49935C2.12225 7.62369 2.15497 7.74584 2.21714 7.85352L5.05048 12.8118C5.11288 12.9199 5.20275 13.0096 5.31096 13.0718C5.41918 13.134 5.54191 13.1665 5.66673 13.166H11.3334C11.4582 13.1665 11.5809 13.134 11.6892 13.0718C11.7974 13.0096 11.8872 12.9199 11.9496 12.8118L14.783 7.85352C14.8451 7.74584 14.8779 7.62369 14.8779 7.49935C14.8779 7.37501 14.8451 7.25286 14.783 7.14518L11.9496 2.18685C11.8872 2.07875 11.7974 1.98907 11.6892 1.92688C11.5809 1.86468 11.4582 1.83219 11.3334 1.83268H5.66673Z"
          fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M15.456 6.79094L12.5872 1.83261C12.4606 1.61325 12.2775 1.43184 12.057 1.30726C11.8365 1.18269 11.5867 1.1195 11.3335 1.12428H5.66679C5.41357 1.1195 5.16371 1.18269 4.9432 1.30726C4.7227 1.43184 4.53963 1.61325 4.41304 1.83261L1.54429 6.79094C1.41995 7.0063 1.35449 7.2506 1.35449 7.49928C1.35449 7.74795 1.41995 7.99225 1.54429 8.20761L4.41304 13.1659C4.53963 13.3853 4.7227 13.5667 4.9432 13.6913C5.16371 13.8159 5.41357 13.8791 5.66679 13.8743H11.3335C11.5819 13.874 11.8259 13.8084 12.041 13.6841C12.256 13.5598 12.4347 13.3811 12.5589 13.1659L15.4276 8.20761C15.5563 7.99475 15.6267 7.75176 15.6316 7.50307C15.6366 7.25439 15.576 7.00878 15.456 6.79094ZM8.50012 9.62428C8.07984 9.62428 7.66899 9.49965 7.31954 9.26615C6.97008 9.03265 6.69772 8.70077 6.53688 8.31248C6.37604 7.92419 6.33396 7.49692 6.41595 7.08471C6.49795 6.6725 6.70033 6.29386 6.99752 5.99667C7.29471 5.69949 7.67335 5.4971 8.08556 5.41511C8.49777 5.33311 8.92503 5.3752 9.31333 5.53603C9.70162 5.69687 10.0335 5.96923 10.267 6.31869C10.5005 6.66814 10.6251 7.07899 10.6251 7.49928C10.6251 8.06286 10.4012 8.60336 10.0027 9.00188C9.60421 9.40039 9.06371 9.62428 8.50012 9.62428Z"
          fill={isSelected ? '#4283E5':'#2B3674'}
      />
    </svg>
  );
};
