import _ from "lodash";
import React from "react";

export const TagComponent = ({ text, id,onAdd = () =>{}, onClose = () => {}, close, plus = false }) => {
  return (
    <button className={close ? "tag-on-close" : plus ? "tag-wrap mr-2 " : "tag-wrap"} onClick={() => plus ?onAdd(): onClose(id) }>
      <p className="tag-wrap-txt">{text}</p>
      {
        !plus ? <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#2B3674"
          className="w-5 h-5 cursor-pointer font-extrabold"
          onClick={() => onClose()}
        >
          <path
            fillRule="evenodd"
            d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
            clipRule="evenodd"
          />
        </svg> : <AddIcon />
      }

    </button>
  );
};

const AddIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.84366 8.74234L15.1516 8.74224C15.562 8.74233 15.8937 8.41062 15.8937 8.00021C15.8937 7.5899 15.5619 7.25819 15.1516 7.25819L8.84356 7.25819L8.84366 0.950221C8.84356 0.539812 8.51195 0.208198 8.10163 0.208198C7.69122 0.208102 7.35951 0.539812 7.35961 0.950222L7.35951 7.25819L1.05154 7.25829C0.641135 7.25819 0.309424 7.5899 0.309521 8.00031C0.309425 8.20489 0.392448 8.39073 0.526785 8.52507C0.661122 8.65941 0.846868 8.74234 1.05154 8.74234L7.35951 8.74224L7.35951 15.0503C7.35951 15.255 7.44244 15.4407 7.57678 15.5751C7.71111 15.7094 7.89686 15.7923 8.10154 15.7923C8.51195 15.7924 8.84366 15.4607 8.84356 15.0503L8.84366 8.74234Z" fill="#2B3674" />
    </svg>

  )
}
