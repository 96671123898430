

import Repository from "./Repository";
import _ from "lodash";

const getPath = (path)=>{
    return `user/${path}`;
}

//API CALL for Auth flow
class OnboardingRepository extends Repository {
    candidateSignupComplete = async (params) => {
        try {
            const data = await this.postData(getPath(`candidatesignupcompletion`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };


    getLocationList = async () => {
        try {
            const data = await this.getData(getPath('locations'));
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }
    

}

export default new OnboardingRepository("onboard");
