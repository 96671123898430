import Repository from "./Repository";
import _ from "lodash";

const getPath = (path)=>{
    return `jobpost/${path}`;
}
const getRecruiterPath = (path)=>{
    return `recruiter/${path}`;
}
class DashboardRepository extends Repository {
  candidateDashboard = async (params) => {
    try {
      const data = await this.getData(getPath("candidatedashboard"), params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };
  getAllNotifications = async () => {
    try {
      const data = await this.getData("notification/getallnotifications");
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getUnreadNotifications = async () => {
    try {
      const data = await this.getData("notification/getunreadnotifications");
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getReadNotifications = async () => {
    try {
      const data = await this.getData("notification/getreadnotifications");
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  markAllNotificationsAsRead = async () => {
    try {
      const data = await this.putData(
        "notification/markallnotificationsasread"
      );
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  markNotificationAsRead = async (param) => {
    try {
      const data = await this.putData(
        "notification/marknotificationsasread",
        param
      );
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getRecruiterDashboard= async (params) =>{
    try {
        const data = await this.getData(getRecruiterPath('dashboard'), params);
        return _.get(data, `data`);
    } catch (error) {
        return { error }
    }
  }
    initiateScreening = async (id,params) =>{
        try {
            const data = await this.putData(getPath(`screeningInitiate/${id}`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }
}

export default new DashboardRepository("dashboard");
