import React, { Fragment, useEffect, useRef } from "react";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { Dialog, Transition } from "@headlessui/react";
// import { ButtonComponent, SkillCardlet } from "../../atoms";
// import { IMAGES } from "../../../assets";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { convertTo12Hour, dateFromNow, IMAGE_URL } from "../../../core/Constant";
// import { dateFromNow } from "../../../core/Constant";
// import { useDispatch } from "react-redux";
// import { Actions } from "../../../core/modules/Actions";
// import { JobApplyModal } from "./JobApplyModal";

export const InterviewMoreDetailsModal = ({
  details,
  onClose,
  open = false,
}) => {
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("details", details);
  }, [details]);

  function createMarkup() {
    return { __html: _.get(details, "job.description") };
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black w-full max-w-lg md:max-w-2xl lg:max-w-3xl text-left shadow-xl transition-all my-8">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full">
                  <div className="grid grid-flow-dense grid-cols-12 items-center mb-2">
                    <div className="col-span-10 flex flex-col ">
                      <span className="font-DM text-3xl">
                        {_.get(details, "title")}
                      </span>
                      <span className="font-DM text-md text-gray-500">
                        {_.get(details, "interview_type")}
                      </span>
                    </div>

                    <div className="col-span-2 flex justify-end">
                      <CloseButton onClick={onClose} />
                    </div>
                  </div>
                  <hr />
                  <div className="mt-2 flex flex-col gap-1 w-full overflow-auto ">
                    <div className="w-full flex justify-center p-2">
                      <img
                        src={IMAGE_URL + _.get(details, "job.job_image")}
                        alt="job-Image"
                        className="object-cover w-52 rounded-lg"
                      />
                    </div>
                    <span className="font-DM text-xl font-semibold">
                          {_.get(details, "job.title")}
                        </span>
                    <div className="flex flex-col md:flex-row justify-between items-end w-full gap-2">
                        
                      <div className="flex flex-col space-y-1">
                        

                        <span className="font-DM text-sm text-gray-500">
                          {_.get(details, "job.location")}
                        </span>

                        <span className="font-DM text-sm text-gray-500">
                          {_.get(details, "job.job_type")}
                        </span>

                        <span className="font-DM text-sm text-gray-500">
                          {_.get(details, "job.work_type")}
                        </span>
                        <span className="font-DM text-sm text-gray-500">
                        {_.get(details, "date")}
                        </span>
                        <span className="font-DM text-sm text-gray-500">
                        {`${convertTo12Hour(_.get(details, "time_from")) } - ${convertTo12Hour(_.get(details, "time_to")) }`}
                        </span>
                        {_.get(details, "link") != "" ? 
                         <a href={_.get(details, "link")} target="_blank"><span className="font-DM text-sm text-blue-500">
                         {_.get(details, "link")}
                         </span></a>
                         :''}
                       
                      </div>
                      <div className="flex flex-col space-y-1 text-right">
                        

                        <span className="font-DM text-sm text-gray-500 text-right">
                          {_.get(details, "job.Company.name")}
                        </span>

                        <span className="font-DM text-sm text-gray-500 text-right">
                          {_.get(details, "job.Company.company_size")}
                        </span>

                        <span className="font-DM text-sm text-gray-500 text-right">
                          {_.get(details, "job.Company.email")}
                        </span>
                        {/* <span className="font-DM text-sm text-gray-500">
                          {dateFromNow(_.get(details, "job.createdAt"))}
                        </span> */}
                      </div>
                    </div>

                    <span className="font-DM text-lg text-gray-600" dangerouslySetInnerHTML={createMarkup()}>
                      {/* {_.get(details, "job.description")} */}
                    </span>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
