import React, { useEffect, useState } from "react";
import { ButtonComponent, TabButtonComponent, TextFieldComponent } from "../../../components/atoms";
import { ScreeningCard } from "../../../components/molecule";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { onToastNotify } from "../../../core/Constant";
import { imageURL } from "../../../core/repository/Repository";
import { InterviewPostModal } from "../../../components/molecule/Modals/InterviewPostModal";
import { AnswerViewModal, ConversationModal } from "../../../components/molecule/Modals";
import { IMAGE_URL } from "../../../core/Constant";

const QualifiedCandidiateScreen = ({
  isInitial = false,
  getAllJobRecruiter,
  getQualifiedCandidates,
  getRejectedCandidates,
  getAppliedCandidates,
  recruiterJobs,
  appliedList,
  qualifiedList,
  rejectedList,
  markasQualified,
  markasRejected,
  offerJob,
  createConversation,
  getAnswers,
  answersCandidiate,
  getScreeninginitiateCandidate,
  screeningInitiate,
  initiateScreening
}) => {
  const [activeTab, setActiveTab] = useState("applied");
  const [jobPost, setJobPost] = useState("");
  const [jobs, setJobs] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [appliedCandidates, setAppliedCandidates] = useState([]);
  const [qualifiedCandidates, setQualifiedCandidates] = useState([]);
  const [rejectedCandidates, setRejectedCandidates] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConversationModalOpen, setIsConversationModalOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [conversationTitle, setConversationTitle] = useState("");
  const [isAnswerModal, setIsAnswerModal] = useState(false)
  const [answers, setAnswers] = useState([]);
  const [screend,setScreened] = useState([])



  useEffect(() => {
    getAllJobRecruiter();
    setAppliedCandidates([])
  }, []);

  useEffect(() => {
    setJobs(recruiterJobs || []);
  }, [recruiterJobs]);

  const handleJobPostChange = (e) => {
    setJobPost(e.target.value);
    setShowDropdown(true);
  };

  const handleJobPostSelect = (job) => {
    console.log(job)
    setJobPost(job.title);
    setSelectedJob(job);
    getAppliedCandidates(job.id);
    setShowDropdown(false);
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    setCandidateList([]);

    if (selectedJob) {
      if (tabName === "applied") {
        getAppliedCandidates(selectedJob.id);
      } else if (tabName === "qualified") {
        getQualifiedCandidates(selectedJob.id);
      } else if (tabName === "rejected") {
        getRejectedCandidates(selectedJob.id);
      } else if (tabName === "screening") {
        getScreeninginitiateCandidate(selectedJob.id)
      }


    } else {
      onToastNotify("Please Select Job");
    }
  };

  useEffect(() => {
    if (activeTab === "applied" && selectedJob != null) {
      getAppliedCandidates(selectedJob.id);
    }else{
      setAppliedCandidates([])
    }
  }, [activeTab, selectedJob]);

  useEffect(() => {
    if(selectedJob != null){
      setAppliedCandidates(appliedList || []);
      if (activeTab === "applied") {
        setCandidateList(appliedList || []);
      }
      setQualifiedCandidates([]);
      setRejectedCandidates([]);
    }else{
      resetListArray();
    }
   
  }, [appliedList]);

  useEffect(() => {
    if(selectedJob != null){
      setQualifiedCandidates(qualifiedList || []);
      if (activeTab === "qualified") {
        setCandidateList(qualifiedList || []);
      }
      setAppliedCandidates([]);
      setRejectedCandidates([]);
    }else{
      resetListArray();
    }
  }, [qualifiedList]);

  useEffect(() => {
    if(selectedJob != null){
      setRejectedCandidates(rejectedList || []);
      if (activeTab === "rejected") {
        setCandidateList(rejectedList || []);
      }
      setAppliedCandidates([]);
      setQualifiedCandidates([]);
    }else{
      resetListArray();
    }
   
  }, [rejectedList]);

  useEffect(() => {
    if(selectedJob != null){
      if (activeTab === "screening") {
        setCandidateList(screeningInitiate || []);
      }
      setAppliedCandidates([]);
      setQualifiedCandidates([]);
    }else{
      resetListArray();
    }
  }, [screeningInitiate]);

  const handleOpenModal = (job) => {
    setSelectedJobId(job.job_id);
    setSelectedCandidate(job);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedJobId(null);
  };

  const onCreateConversation = (jobId, candidateId) => {
    setSelectedJobId(jobId);
    setSelectedCandidate(candidateId);
    setIsConversationModalOpen(true);
  };

  const handleCreateConversation = (title) => {
    if (title && selectedJobId && selectedCandidate) {
      createConversation({ name: title, job_id: selectedJobId, candidate_id: selectedCandidate });
      setIsConversationModalOpen(false);
    } else {
      onToastNotify("Please provide a valid title.");
    }
  };

  const onViewAnswers = (jobId, candidateId) => {
    setSelectedJobId(jobId);
    setSelectedCandidate(candidateId);
    console.log(jobId, candidateId)
    getAnswers({ user_id: candidateId, job_id: jobId })
    setIsAnswerModal(true);
  };

  const resetListArray = ()=>{
    setAppliedCandidates([]);
    setCandidateList([]);
    setQualifiedCandidates([]);
    setRejectedCandidates([]);
  }

  function createMarkup() {
    return { __html: selectedJob.description };
}

const markAsQualified = (id, feedback) => {
  console.log("Candidate ID:", id, "Feedback:", feedback);
  markasQualified(id,{feedback:feedback})

};

  return (
    <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
      <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 ">
        <div className="bg-white p-4 rounded-xl">
          <p className="text-2xl text-darkBlue font-bold font-DM">Candidates Status</p>

          <div className="relative">
            <TextFieldComponent
              placeholder="Please type &  select job post"
              value={jobPost}
              onChange={handleJobPostChange}
              label="Job Post"
            />
            {showDropdown && jobPost && (
              <div className="absolute z-10 bg-white shadow-md max-h-40 overflow-y-auto w-full">
                {jobs
                  .filter((job) => job.title.toLowerCase().includes(jobPost.toLowerCase()))
                  .map((job, index) => (
                    <div
                      key={index}
                      className="p-2 cursor-pointer hover:bg-blue-200"
                      onClick={() => handleJobPostSelect(job)}
                    >
                      {job.title}
                    </div>
                  ))}
              </div>
            )}
          </div>

          {selectedJob && (
            <div className="mt-4 border p-4 rounded-lg bg-gray-100 flex flex-col sm:flex-row items-center sm:items-start">
              <img
                src={IMAGE_URL + selectedJob.job_image}
                alt={`${selectedJob?.title} image`}
                className="w-16 h-16 sm:w-20 sm:h-20 rounded-full object-cover mb-4 sm:mb-0 sm:mr-4"
              />
              <div className="text-center sm:text-left">
                <p>
                  <strong>Job Title:</strong> {selectedJob.title}
                </p>
                <p>
                  <strong>Job Description:</strong><p dangerouslySetInnerHTML={createMarkup()}></p>
                   {/* {selectedJob.description} */}
                </p>
                <p>
                  <strong>Location:</strong> {selectedJob.location}
                </p>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between gap-y-2 flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-row mt-2">
            <div>
              <TabButtonComponent
                buttonOneName="Applied"
                buttonTwoName="Qualified"
                buttonThreeName="Rejected"
                buttonFourName="Screened"
                isApplied={activeTab === "applied"}
                isThirdTab={activeTab === "rejected"}
                onClick={handleTabChange}
                showFourthTab={true}
                isFourthTab={activeTab === "screening"}
                isFourthEnable={true}
              />
            </div>
          </div>
        </div>

        {candidateList.length > 0 ? (
          candidateList.map((item, index) => (
            <ScreeningCard
              onCreateConversation={() => onCreateConversation(selectedJob.id, item.Candidate.user_id)}
              onViewAnswers={() => onViewAnswers(selectedJob.id, item.Candidate.user_id)}
              onOfferJob={() => offerJob(item.id)}
              onInterview={() => handleOpenModal(item)}
              key={index}
              candidate={item}
              onMarkAsQualified={markAsQualified}
              onMarkAsRejected={() => markasRejected(item.id)}
              onInitiateScreening={() => initiateScreening(selectedJob.id, { appliedIds: [item.id] })}
              tabName={activeTab} />
          ))
        ) : (
          <p className="text-lg mt-2 text-gray-700 font-semibold p-4 bg-white rounded-lg shadow-md border border-gray-200">
            No candidates available.
          </p>
        )}
      </div>

      {isModalOpen && (
        <InterviewPostModal
          jobId={selectedJobId}
          isOpen={isModalOpen}
          candidate={selectedCandidate}
          onClose={handleCloseModal}
        />
      )}

      {isConversationModalOpen && (
        <ConversationModal
          isOpen={isConversationModalOpen}
          onClose={() => setIsConversationModalOpen(false)}
          onSubmit={handleCreateConversation}
        />
      )}
      {isAnswerModal && (
        <AnswerViewModal
          isOpen={isAnswerModal}
          onClose={() => setIsAnswerModal(false)}
          data={answersCandidiate}
        // onSubmit={handleCreateConversation}
        />
      )}

    </div>
  );
};

export default connect(
  (state) => ({
    recruiterJobs: state.jobs.get("recruiterJobs"),
    appliedList: state.home.get("appliedList"),
    qualifiedList: state.home.get("qualifiedList"),
    rejectedList: state.home.get("rejectedList"),
    answersCandidiate: state.home.get("answersCandidiate"),
    screeningInitiate:state.home.get('screeningInitiate')
  }),
  {
    getAllJobRecruiter: Actions.jobs.getAllJobRecruiter,
    getAppliedCandidates: Actions.home.getAppliedCandidates,
    getQualifiedCandidates: Actions.home.getQualifiedCandidates,
    getRejectedCandidates: Actions.home.getRejectedCandidates,
    markasQualified: Actions.home.markasQualified,
    markasRejected: Actions.home.markasRejected,
    offerJob: Actions.home.offerJob,
    createConversation: Actions.home.createChat,
    getAnswers: Actions.home.getAnswers,
    getScreeninginitiateCandidate: Actions.home.getScreeninginitiateCandidate,
    initiateScreening: Actions.home.initiateScreening,
  }
)(QualifiedCandidiateScreen);
