import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JobCard } from "../../../components";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import { IMAGE_URL } from "../../../core/Constant";

export const JobOffersScreen = () => {
  const dispatch = useDispatch();
  const jobOffers = useSelector((state) => state.jobs.get("jobOffers"));

  useEffect(() => {
    dispatch(Actions.jobs.getAllJobOffers());
  }, [dispatch]);

  useEffect(() => {
    // console.log("jobs offers: ", jobOffers);
  }, [jobOffers]);


  return (
    <div className="subscreen-container">
      <div className="flex flex-col ">
        <span className="text-lableColor font-DM text-3xl font-[500]">
          Job Offers
        </span>
      </div>

      <div className="grid grid-flow-row-dense grid-cols-1 gap-2">
      {jobOffers && jobOffers.length > 0 ? (
        _.map(jobOffers, (quiz, index) => {
          function createMarkup() {
            return { __html: _.get(quiz, "job.description") };
          }
          return (
            <div className="w-full bg-blue-50 rounded-xl p-2" key={index + 1}>
              <div className="grid grid-cols-1 sm:grid-cols-12 gap-3">
                <div className="sm:col-span-2">
                  <img
                    src={IMAGE_URL + _.get(quiz, "job.job_image")}
                    alt="job-image"
                    className="object-cover w-full h-full rounded-xl"
                  />
                </div>
                <div className="sm:col-span-7">
                  <div className="flex flex-col h-full items-start gap-1">
                    <div className="flex flex-wrap gap-2 items-baseline">
                      <span className="font-DM font-semibold text-darkBlue text-xl">
                        {_.get(quiz, "job.title")}
                      </span>
                      <span className="font-DM font-medium text-darkBlue text-sm">
                        {_.get(quiz, "job.work_type")}
                      </span>
                    </div>
                    <span className="font-DM font-semibold text-darkBlue text-sm">
                      {_.get(quiz, "job.job_type")}
                    </span>

                    <span className="font-DM font-normal text-darkBlue text-sm">
                      {_.get(quiz, "job.Company.name")}
                    </span>

                    <span className="font-DM font-normal text-darkBlue text-sm">
                      {_.get(quiz, "job.location")}
                    </span>

                    <span className="font-DM font-normal text-darkBlue text-sm" dangerouslySetInnerHTML={createMarkup()}>
                      {/* {_.get(quiz, "job.description")} */}
                    </span>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <div className="flex flex-col h-full justify-center items-end gap-4">
                  <div className="p-2">
                        <span className="font-DM font-semibold text-green-500">
                        {_.get(quiz, "candidate_status")}
                        </span>
                      </div>
                   

                    {/* <ButtonComponent
                      text="Attempt Quiz"
                      isDisabled={_.get(quiz, "is_screening_attempted")}
                      onClick={() => onClickAttempt(quiz)}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="flex justify-center items-center max-h-svh min-h-[150px]">
         <span className="font-DM text-3xl text-gray-400 text-center">
         No job offers</span>
       </div>
    )}
      </div>
    </div>
  );
};
