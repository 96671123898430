import React, { useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Header } from "../Header";
import { SidebarContainer } from "../Sidebar/SidebarContainer";
import { useDispatch } from "react-redux";
import { setTopLevelNavigator } from "../../../core/services/NavigationServicd";


const MainLayout = ({ }) => {
  const intervalRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const onNavigate = (path, obj = {}) => {
    if (path) {
        navigate(path, obj);
    }
}

  useEffect(()=>{
    intervalRef.current = onNavigate;
    setTopLevelNavigator(intervalRef);
  },[navigate])

  return (
    <div className="main-container">
      <div>
        <Header />
      </div>
      <div className="flex-grow">
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
