export const CandidateIcon = ({ isSelected = false }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.71297 8.00016C6.27815 8.00016 6.83064 7.83256 7.30056 7.51855C7.77049 7.20454 8.13674 6.75823 8.353 6.23606C8.56926 5.71389 8.62582 5.13932 8.51553 4.585C8.40523 4.03069 8.13303 3.52153 7.73335 3.12192C7.33367 2.72231 6.82447 2.4502 6.27013 2.34C5.7158 2.2298 5.14124 2.28646 4.6191 2.50282C4.09697 2.71917 3.65073 3.0855 3.3368 3.55548C3.02288 4.02546 2.85537 4.57798 2.85547 5.14316C2.85626 5.90072 3.15759 6.62703 3.69332 7.16266C4.22905 7.69829 4.9554 7.9995 5.71297 8.00016Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M9.4525 10.3603C8.72841 9.61759 7.79936 9.10759 6.78402 8.89544C5.76869 8.68329 4.71316 8.77862 3.75226 9.16926C2.79136 9.5599 1.96873 10.2281 1.38943 11.0885C0.81013 11.949 0.500469 12.9625 0.5 13.9998C0.5 14.1324 0.552678 14.2596 0.646447 14.3534C0.740215 14.4471 0.867392 14.4998 1 14.4998H10.43C10.5626 14.4998 10.6898 14.4471 10.7836 14.3534C10.8773 14.2596 10.93 14.1324 10.93 13.9998C10.9319 13.4696 10.8501 12.9424 10.6875 12.4378C10.4454 11.6576 10.0222 10.9457 9.4525 10.3603Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M11.75 8.25C12.9236 8.25 13.875 7.2986 13.875 6.125C13.875 4.95139 12.9236 4 11.75 4C10.5764 4 9.625 4.95139 9.625 6.125C9.625 7.2986 10.5764 8.25 11.75 8.25Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M11.7507 8.83594C11.0804 8.83846 10.4232 9.02098 9.84766 9.36444C9.95616 9.46144 10.0657 9.55694 10.1682 9.66194C10.849 10.3617 11.3547 11.2125 11.6442 12.1449C11.7433 12.4519 11.8164 12.7667 11.8627 13.0859H15.0007C15.1333 13.0859 15.2604 13.0333 15.3542 12.9395C15.448 12.8457 15.5007 12.7185 15.5007 12.5859C15.4996 11.5917 15.1042 10.6385 14.4011 9.93546C13.6981 9.23242 12.7449 8.837 11.7507 8.83594Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
    </svg>
  );
};
