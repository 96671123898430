import { Card } from "@material-tailwind/react";
import {
    ButtonComponent,
    DropdownComponent,
    DropdownWithTextInput,
    IconTextFieldComponent,
    TextAreaComponent,
    TextFieldComponent,
} from "../../atoms";
import { FaFacebook, FaInstagram, FaLinkedin, FaTrash } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";

export const RecruiterProfileForm = ({ details }) => {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [about, setAbout] = useState("");
    const [size, setSize] = useState("");
    const [industry, setIndustry] = useState("");
    const [ email,setEmail] = useState('')
    const [companyLinks, setCompanyLinks] = useState([]);
    const [companyid,setCompanyId] = useState();
    const industryList = useSelector((state) => state.common.get('industryList'));

    useEffect(() => {
        dispatch(Actions.common.getindustryList());
      }, [dispatch]);

      const industryOptions = industryList?.map((industry) => ({
        id: industry.id,
        name: industry.name,
      }));
    // Set initial values
    useEffect(() => {
        if (details) {
            setName(details.name);
            setAbout(details.about);
            setSize({ id: 0, name: details.company_size });
            setCompanyLinks(details.CompanyLinks || []);
            setCompanyId(details.id)
            setEmail(details.email)
            // Find and set the industry based on industry_id from details
            if (industryList?.length > 0) {
                const matchingIndustry = industryList.find(
                    (industry) => industry.id === details.industry_id
                );
                if (matchingIndustry) {
                    setIndustry(matchingIndustry);
                }
            }
        }
    }, [details, industryList]);

    const dropdownOptions = [
        {id:1,name:'1-10 Employees'},
        {id:2,name:'11-50 Employees'},
        {id:3,name:'51-250 Employees'},
        {id:3,name:'251 Employees and above'},
    ]


    const handleWorkmode = (option) =>{
        setIndustry(option)
     }
 
     const handleSize = (option)=>{
         setSize(option)
 
     }
 
    // Handle form submission (Edit Profile)
    const handleEditProfile = (e) => {
        e.preventDefault();

        const payload = {
            name,
            about,
            email,
            company_size: size.name,
            industry_id: industry.id,
            company_links: companyLinks.map((link, index) => ({
                id: link.id,
                name: link.name,
                url: link.url,
            })),
        };

        dispatch(Actions.profile.recruiterEdit(companyid,payload))

       // dispatch({ type: "EDIT_PROFILE", payload }); // Dispatch the edit profile action
    };

    return (
        <Card className="bg-white py-4 px-8" shadow={false}>
            <form className="mb-2" onSubmit={handleEditProfile}>
                <div className="w-full my-8 h-auto flex flex-col">
                    <TextFieldComponent
                        error={false}
                        placeholder="type here"
                        isdisabled={false}
                        errortxt="label is required!"
                        label="Company name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextAreaComponent
                        error={false}
                        placeholder="type here"
                        isdisabled={false}
                        errortxt="label is required!"
                        label="About company"
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                    />
                    <DropdownComponent
                        error={false}
                        placeholder="type here"
                        isdisabled={false}
                        errortxt="label is required!"
                        label="Company Size"
                        dropdownOptions={dropdownOptions}
                        value={size}
                        onOptionSelected={handleSize}
                    />
                    <DropdownComponent
                        error={false}
                        placeholder="type here"
                        isdisabled={false}
                        errortxt="label is required!"
                        label="Company Size"
                        dropdownOptions={industryOptions}
                        value={industry}
                        onOptionSelected={handleWorkmode}
                    />
                    {companyLinks.map((link, index) => (
                        <div
                            key={index}
                            className="w-full 2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-2/3 mb-4 relative group"
                        >
                            <DropdownWithTextInput
                                label={`company links ${index + 1}`}
                                selectedOption={link.name}
                                value={link.url}
                                isedit={true}
                                onChange={(e) => {
                                    const updatedLinks = [...companyLinks];
                                    updatedLinks[index] = {
                                        ...updatedLinks[index],
                                        url: e.target.value,
                                    };
                                    setCompanyLinks(updatedLinks);
                                }}
                            />
                            <button
                                className="absolute right-0 top-0 mt-2 mr-2 text-red-500 hover:text-red-700 opacity-0 group-hover:opacity-100 transition-opacity"
                                onClick={() => {
                                    const updatedLinks = companyLinks.filter((_, i) => i !== index);
                                    setCompanyLinks(updatedLinks);
                                }}
                            >
                                <FaTrash />
                            </button>
                        </div>
                    ))}
                </div>

                <ButtonComponent  text="Edit Profile" type="submit" />
            </form>
        </Card>
    );
};
