import { ListItem, ListItemPrefix } from "@material-tailwind/react";
import React, { useContext, useState } from "react";
// import { PresentationChartBarIcon } from "@heroicons/react/24/solid";
import _ from "lodash";
import { Link } from "react-router-dom";
import { AddIcon } from "../../../assets/svg";
import { USER_ROLE } from "../../../core/Constant";
import { Context } from "../../../core/Context/Context";
import { navigateTo } from "../../../core/services/NavigationServicd";
import { getUserType } from "../../../core/UtilMethods";
import { SUBMENUS } from "../../../Menus/SubMenu";

export const SidebarProfileContainer = () => {
  // const [isExpand, setIsExpand] = useState(true);

  // const handleClickPrimaryOption = (clickOption, list = []) => {
  //   if (clickOption == "Candidate" && list.length > 0) {
  //     setIsExpand(true);
  //   } else {
  //     setIsExpand(false);
  //   }
  // };

  const oncreateJob = () => {
    navigateTo("/recruiter_createjob");
  };

  return (
    <div className="sidebar-primary-container flex flex-col gap-y-2">
      {getUserType() == USER_ROLE.recruiter ? (
        <>
        <button
          onClick={oncreateJob}
          className=" p-2 rounded-md bg-primaryColor w-full"
        >
          <div className="flex items-start justify-center">
            <p className="font-DM text-white font-semibold ">Post a job</p>
            <AddIcon />
          </div>
        </button>
        <div className="sidebar-container">
          {_.map(_.get(SUBMENUS, "ProfileSubMenuRecruiter"), (item, index) => {
            return (
              <Link to={_.get(item, "url")} key={index + 1}>
                <ListItem className="hover:bg-bgColor active:bg-bgColor">
                  <ListItemPrefix>{_.get(item, "icon")}</ListItemPrefix>
                  <span className="sidebar-text">{item?.name}</span>
                </ListItem>
              </Link>
            );
          })}
        </div>
        </>   
      ) : (
        <div className="sidebar-container">
          {_.map(_.get(SUBMENUS, "ProfileSubMenu"), (item, index) => {
            return (
              <Link to={_.get(item, "url")} key={index + 1}>
                <ListItem className="hover:bg-bgColor active:bg-bgColor">
                  <ListItemPrefix>{_.get(item, "icon")}</ListItemPrefix>
                  <span className="sidebar-text">{item?.name}</span>
                </ListItem>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
