import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  CandidateSkillsAndInterest,
  CandidateStepOne,
  CandidateStepThree,
  CandidateStepTwo,
} from "../../../components/organism";
import { AUTH_PROCESS_STEPS } from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";
import { useNavigate } from "react-router-dom";
import { getOnboardingStep, setLinkdinUser } from "../../../core/UtilMethods";

const CandidateOnboardingProcess = ({ locationList, getAllLocations }) => {
  const [image, setImage] = useState("bg-create-account");
  const authState = useSelector((state) => state.auth.get("authstep"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getAllLocations();
  }, []);

  useEffect(() => {}, [authState, locationList]);

  useEffect(() => {
    dispatch(
      Actions.auth.authStep({
        step: AUTH_PROCESS_STEPS.onboarding_pick_location_can,
      })
    );
  }, []);

  useEffect(() => {
    // console.log("step: ", getOnboardingStep());
    if (AUTH_PROCESS_STEPS.onboarding_profile_can == getOnboardingStep())  {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      // const status = params.get("state");
      setLinkdinUser(token);
      if (token) {
        dispatch(
          Actions.auth.authStep({
            step: AUTH_PROCESS_STEPS.onboarding_profile_can,
          })
        );
      }
    }
  }, []);

  const onClickLogin = () => {
    dispatch(Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signIn }));
    navigate("/");
  };

  return (
    <div>
      {AUTH_PROCESS_STEPS.onboarding_skillNinterest_can ==
      _.get(authState, "step") ? (
        <CandidateSkillsAndInterest />
      ) : (
        <div className="main-onboarding-process-screen">
          <div className={`left-side-content ${image}`}></div>
          <div className="right-side-content">
            {AUTH_PROCESS_STEPS.onboarding_pick_location_can ==
            _.get(authState, "step") ? (
              <CandidateStepOne
                listData={locationList}
                onClickLogin={onClickLogin}
              />
            ) : null}
            {AUTH_PROCESS_STEPS.onboarding_pick_jobType_can ==
            _.get(authState, "step") ? (
              <CandidateStepTwo onClickLogin={onClickLogin} />
            ) : null}
            {AUTH_PROCESS_STEPS.onboarding_profile_can ==
            _.get(authState, "step") ? (
              <CandidateStepThree onClickLogin={onClickLogin} />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    locationList: state.onboardingProcess.get("locationList"),
  }),
  {
    getAllLocations: Actions.onboardingProcess.getAllLocations,
  }
)(CandidateOnboardingProcess);
