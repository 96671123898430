import React, { useState } from "react";

export const DropdownWithTextInput = ({
  label,
  options,
  selectedOption,
  onOptionChange,
  value,
  onChange = () => {},
  isedit = false
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    onOptionChange(option.value);
    setIsOpen(false);
  };

  return (
    <>
      <h6 className="font-DM text-darkBlue text-md font-semibold">{label}</h6>
      <div className="flex items-center space-x-0 border-2 border-darkGray rounded-md">
        <div className="relative inline-block w-60 bg-[#D6E2F2]">
          {
            isedit ?  <></>  :         <button
            type="button"
            className="appearance-none w-full bg-[#D6E2F2] border-none text-darkBlue font-medium font-DM py-2 px-4 pr-8 rounded leading-tight focus:shadow-none focus:border-none focus:outline-none flex items-center justify-between"
            onClick={() => setIsOpen(!isOpen)}
          >
            {
              options ?             <div className="flex items-center">
              {options.find((opt) => opt.value === selectedOption)?.icon}
              <span className="ml-2">
                {options.find((opt) => opt.value === selectedOption)?.label ||
                  "Select an option"}
              </span>
            </div> :<></>
            }
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M10 12l-6-6h12l-6 6z" />
            </svg>
          </button>
          }
          {isOpen && (
            <ul className="absolute left-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
              {options.map((option, index) => (
                <li
                  key={index}
                  className="cursor-pointer flex items-center px-4 py-2 hover:bg-gray-200"
                  onClick={() => handleSelect(option)}
                >
                  {option.icon}
                  <span className="ml-2">{option.label}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <input
          type="text"
          className="w-full bg-white border-none text-gray-700 py-2 px-4 rounded leading-tight focus:shadow-none focus:border-none focus:outline-none"
          placeholder="Enter text here"
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
};
