import { createAction } from "../../core/AppUtils";
import { AUTH_PROCESS_STEPS } from "../../core/Constant";
import AuthRepository from "../../core/repository/AuthRepository";

export const ModuleEvents = {
  LOADING_STARTED: "LOADING_STARTED",
  LOADING_FINISHED: "LOADING_FINISHED",
  NETWORK_ERROR: "NETWORK_ERROR",
  RECRUITER_SIGN_UP: "RECRUITER_SIGN_UP",
  LOGIN_USER: "LOGIN_USER",
  STORE_USER_DETAILS: "STORE_USER_DETAILS",
  SIGN_OUT: "SIGN_OUT",
  SIGN_UP_COMPLETE: "SIGN_UP_COMPLETE",
  REGISTER_CANDIDATE: "REGISTER_CANDIDATE",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD_COMMON: "RESET_PASSWORD_COMMON",
  STORE_AUTH_STEP: "STORE_AUTH_STEP",
  STORE_PROFILE_PIC: "STORE_PROFILE_PIC",
  TOKEN_VERIFY: 'TOKEN_VERIFY',
};

export default {
  networkError: createAction(ModuleEvents.NETWORK_ERROR, (error) => error),
  loadingStarted: createAction(
    ModuleEvents.LOADING_STARTED,
    (action) => action
  ),
  loadingFinished: createAction(
    ModuleEvents.LOADING_FINISHED,
    (action) => action
  ),

  recruitersignUp: createAction(
    ModuleEvents.RECRUITER_SIGN_UP,
    async (params) => {
      const data = await AuthRepository.recruitersignUp(params);
      return { ...data, params };
    },
    {
      loading: true,
      errorMessage: "Error occured while signing up. Please try again.",
    }
  ),

  logIn: createAction(
    ModuleEvents.LOGIN_USER,
    async (params) => {
      return await AuthRepository.logIn(params);
    },
    {
      loading: true,
      errorMessage: "Error occured while login up. Please try again.",
    }
  ),

  storeUserDetails: createAction(
    ModuleEvents.STORE_USER_DETAILS,
    (params) => params
  ),

  signOut: createAction(ModuleEvents.SIGN_OUT, async () => {
    return await AuthRepository.onSignOut();
  }),

  signUpCompleteCandidate: createAction(
    ModuleEvents.SIGN_UP_COMPLETE,
    async (params) => {
      return await AuthRepository.candidateSignupComplete(params);
    }
  ),

  registerCandidate: createAction(
    ModuleEvents.REGISTER_CANDIDATE,
    async (params) => {
      return await AuthRepository.registerCandidate(params);
    }
  ),

  forgotPassword: createAction(ModuleEvents.FORGOT_PASSWORD, async (params) => {
    return await AuthRepository.forgotPassword(params);
  }),

  resetPassword: createAction(
    ModuleEvents.RESET_PASSWORD_COMMON,
    async (params, urlToken) => {
      return await AuthRepository.resetForgotPassword(params, urlToken);
    }
  ),
  authStep: createAction(ModuleEvents.STORE_AUTH_STEP, (params) => params),

  storeProfilePic: createAction(ModuleEvents.STORE_PROFILE_PIC  , (params) => params),

  userTokenVerify: createAction(
    ModuleEvents.TOKEN_VERIFY,
    async () => {
      return await AuthRepository.userTokenVerify();
    }
  ),
};
