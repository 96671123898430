import React, {useState, Fragment } from "react";
import { Menu, Transition } from '@headlessui/react'


export const CountryDropdownComponent = ({
  label,
  name,
  id,
  isdisabled,
  error,
  errortxt,
  placeholder = "",
  type = "text",
  onChange = () => {},
  value = undefined,
  dropdownOptions = [
    { option: "LKR" },
  ],
}) => {
  const [select, setSelect] = useState(false);
  const [selectedOption, setSelectedOption] = useState("LKR");

 
  const onclickdropdown = (value) => {
    console.log(value);
    setSelect(value);
  };
  const optionSelected = (value) => {
    setSelect(false);
    setSelectedOption(value);
  };
  
  return (
    <div className="input-field-wrap items-start">
      <label className="input-label-txt">{label}</label>
      <div className="w-full flex flex-row">
      <Menu>
        <Menu.Button
          onClick={() => onclickdropdown(select ? false : true)}
          className={
            isdisabled
              ? "input-field-disabled"
              : error
              ? "error-input-field"
              : "input-field-country  max-w-[80px]"
          }
        >
          <div className="flex justify-between opacity-55 font-normal">
            <p>{selectedOption}</p>
            {select ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="lableColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        </Menu.Button>
        <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
         <Menu.Items className="dropdown-option-wrap">
          {dropdownOptions?.map((item, index) => {
            return (
              <Menu.Item key={index + 1}>
                {({ active }) => (
                  <p
                    className={`pt-2 pb-2 ps-2 pe-2 rounded-lg ${
                      active && "bg-primaryColor text-white"
                    }`}
                    onClick={() => optionSelected(item?.option)}
                  >
                    {item?.option}
                  </p>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
       
      </Menu>
      <input
        type={type}
        value={value}
        className={
          isdisabled
            ? "input-field-disabled"
            : error
            ? "error-input-field"
            : "input-field-picker"
        }
        onChange={onChange}
        placeholder={placeholder}
        disabled={isdisabled}
        style={{borderRadius:'8px'}}
      />
      </div>

      {error && !isdisabled ? (
        <p className="input-error-txt">{errortxt}</p>
      ) : null}
    </div>
  );
};
