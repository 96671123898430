import React from "react";

export const HeadingComponent = ({headingText, description}) => {
  return (
    <>
      <div className="heading-container">
        <h1 className="primary-heading">{headingText}</h1>
        <p className="small-text-description">{description}</p>
      </div>
    </>
  );
};
