import { useNavigate } from "react-router-dom";

let _navigator;

const setTopLevelNavigator = (navigatorRef) => {
    _navigator = navigatorRef;
  };
  // { state: { forumId: _.get(item, 'id', ''), forum: item } }
  const navigateTo = (path, obj = {}) => {
    if (_navigator?.current) {
        _navigator?.current(path, obj);
    } else {
        console.error("Navigator reference is undefined");
    }
};



const goBack = ()=>{

}

const goTo = (path) => {
}

export { navigateTo, setTopLevelNavigator, goBack, goTo };