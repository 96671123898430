import React, { useEffect, useState } from "react";
import {
  CandidateStepOne,
  CandidateStepThree,
  CandidateStepTwo,
  RecruiterStepOne,
  RecruiterStepThree,
  RecruiterStepTwo,
} from "../../../components/organism";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";

const OnboardingProcessScreen = ({getindustryList,industryList}) => {
  const [image, setImage] = useState("bg-create-account");
  const [onBoardingProcessState, setOnboardingProcessState] = useState(0);
  const [recruiterStep, setRecruiterStep] = useState(0);
  useEffect(() => {
    if(localStorage.getItem("type") == 'candidate'){
      setRecruiterStep(0);
      setOnboardingProcessState(1);
    }else{
      setRecruiterStep(4);
      setOnboardingProcessState(0);
    }

  },[])

  return (
    <div className="main-onboarding-process-screen">
      <div className={`left-side-content ${image}`}></div>
      <div className="right-side-content">
        {onBoardingProcessState == 1 ? <CandidateStepOne setUpdateCandidateStep={setOnboardingProcessState}/> : null}
        {onBoardingProcessState == 2 ? <CandidateStepTwo backStep={setOnboardingProcessState} setCandidateStep={setOnboardingProcessState}/> : null}
        {onBoardingProcessState == 3 ? <CandidateStepThree /> : null}
        {recruiterStep == 4 ? <RecruiterStepOne setUpdateCandidateStep={setRecruiterStep}/> : null}
        {recruiterStep == 5 ? <RecruiterStepTwo setUpdateCandidateStep={setRecruiterStep}/> : null}
        {recruiterStep == 6 ? <RecruiterStepThree setUpdateCandidateStep={setRecruiterStep}/> : null}
      </div>
    </div>
  );
};

export default connect(
  state => ({
    industryList: state.common.get("industryList"),
  }),
  {
    getindustryList: Actions.common.getindustryList,


  },
)(OnboardingProcessScreen);
