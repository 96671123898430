import React, { useState, useEffect } from "react";
import { ButtonComponent, TextFieldComponent } from "../../../components/atoms";
import { EMAIL_REGEX, getText, onToastNotify } from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { SupportTicketCard } from "../../../components/molecule/Cards/SupportTicketCard";
import { CreateTicketModal } from "../../../components/molecule/Modals/CreateTicketModal";

export default ({ }) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const supportTickets = useSelector((state) =>
        state.profile.get("supportTickets")
    );

    useEffect(() => {
        dispatch(Actions.profile.getSupportTicketList());
    }, [dispatch]);

    useEffect(() => {
    }, [supportTickets]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    return (<>
        <div className="subscreen-container">
            <div className="flex flex-row justify-between">
                <span class="text-lableColor font-DM text-2xl font-bold">Help <br />
                    <span class="text-ash font-DM text-sm font-normal">Let Us Know How We Can Support You</span>
                </span>
                <div className="">
                    <ButtonComponent width="w-full" onClick={handleOpenModal} padding="p-2" text="Create Ticket" isPrimary={true} />
                </div>
            </div>


            {supportTickets?.length > 0 ?
                supportTickets?.map((item, index) => {
                    return (
                        <SupportTicketCard key={index} ticketDetails={item} />
                    );
                })
                : (<>
                    <div className="flex justify-center items-center max-h-svh min-h-[180px]">
                        <span className="font-DM text-3xl text-gray-400 text-center">No tickets available</span>
                    </div></>)}
            {isModalOpen && (
                <CreateTicketModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    </>)
}