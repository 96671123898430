import Repository from "./Repository";
import _ from "lodash";

const getPath = (path) => {
  return `screeningQuestions/${path}`;
};
class ScreeningQuestionsRepository extends Repository {
  getAvailableScreeningQuestions = async () => {
    try {
      const data = await this.getData(getPath(`getCandidateScreeningInitiateJobs`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getScreeningQuestions = async (param) => {
    try {
      const data = await this.getData(getPath(`getScreeningQuestionsForJob/${param}`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  answerScreeningQuestions = async (param) => {
    try {
      const data = await this.postData(getPath(`answerScreeningQuestions`), param);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

}
export default new ScreeningQuestionsRepository("screeningQuestions");
