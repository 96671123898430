import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { FavouriteButton } from "../../atoms";
import { IMAGES } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";
import { dateFromNow, IMAGE_URL } from "../../../core/Constant";
import { AddIcon } from "../../../assets/svg";

export const ProfileDetailCard = ({ recruiter = false, details = {}  }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("details: ", details);
  }, [details]);

  const onDrop = useCallback((acceptedFiles) => {
    console.log("selectedFiles", acceptedFiles);
    const image = _.map(acceptedFiles, (file) => {
      return file;
    });
    updateProfileImg(image)
  }, []);
  const { getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop, maxFiles:1,accept:'image/*'});

  const updateProfileImg = (file) => {
    const obj = file[0];
    if (file.length == 1) {
      // const blob = new Blob(file, { type: file.type });

      const data = new FormData();
      data.append("profile_image",obj, _.get(file, "name"));
      dispatch(
        Actions.profile.updateCandidateProfilePhoto(
          data,
          { "Content-Type": "multipart/form-data" },
          (ProgressEvent) => {
            const { loaded, total } = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log("percentage: ", percent);
          }
        )
      );
    }
  };

  return (
    <Card className="w-full  flex-col h-full relative">
      <img
        src={IMAGES.DASHBOARD.BACKGROUND_IMAGE}
        alt="card-image"
        className="w-full h-[150px] object-cover rounded-t-lg"
      />

      <div
        style={{
          backgroundImage: `url(${ recruiter ?
            IMAGES.COMMON.COMPANY_IMAGE :    IMAGE_URL + _.get(details, "profile_image")
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          top: 50,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          width: "142px",
          height: "142px",
          borderRadius: "71px",
        }}
      >
        <div className="items-end justify-end relative">
          {recruiter ? <></> :           <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? null : (
              <IconButton
                variant="outlined"
                className="rounded-full border-2 border-white bg-primaryColor"
              >
                <AddIcon />
              </IconButton>
            )}
          </div>}

        </div>
      </div>
      <CardBody className="mt-20 flex flex-col  h-full items-center">
        <Typography className="mb-2 font-DM font-[500] text-lableColor text-[36px] text-center">

          { recruiter ? `${_.get(details, "name")}` : `${_.get(details, "first_name")} ${_.get(details, "last_name")}`}
        </Typography>
        {recruiter ? (
          <>
            <Typography className="font-DM font-[400] text-ash text-[14px] text-left">
            {_.get(details, "about")}
            </Typography>
          </>
        ) : (
          <div className="w-full flex flex-col justify-between h-full">
            <Typography className="font-DM font-medium text-ash text-[16px] text-center">
              {_.get(details, "position")}
            </Typography>
            <Typography className="font-DM font-[400] text-ash text-[14px] text-left">
              {_.get(details, "bio")}
            </Typography>
            <div className="flex flex-row w-full items-center justify-between">
              <div>
                <Typography className="font-DM font-[400] text-ash text-[14px] ">
                  Profile Status
                </Typography>
                <Typography
                  className={`font-DM font-[400] ${
                    _.get(details, "status")
                      ? "text-textGreen"
                      : "text-errorColor"
                  } text-[14px]`}
                >
                  {_.get(details, "status") ? "Active" : "Inactive"}
                </Typography>
              </div>
              <div>
                <Typography className="font-DM font-[400] text-ash text-[14px] text-left">
                  Last update
                </Typography>
                <Typography className="font-DM font-[400] text-ash text-[14px] text-left">
                  {dateFromNow(_.get(details, "updatedAt"))}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
