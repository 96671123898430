import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ButtonComponent, OutlineButtonComponent, TextAreaComponent, TextFieldComponent } from "../../atoms";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { AttachmentUploadCard } from "../Cards/AttachmentUploadCard";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import _ from "lodash";

export const CreateTicketModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setdescriptionError] = useState(false);
  const [file, setFile] = useState({});
  const [isSelected, setIsSelected] = useState(false);

  const newTicket = useSelector((state) => state.profile.get("newTicket"));

  useEffect(() => {
    if (newTicket && title) {
      setTitle("");
      setDescription("");
      setTitleError(false);
      setdescriptionError(false);
      onClose();
    }
  }, [newTicket]);

  const handleSave = () => {
    if (!titleError && !descriptionError) {
      const data = new FormData();
      if (file) {
        const blob = new Blob([file], { type: file.type });
        data.append("ticket_attachment", blob, _.get(file, "name"));
      }

      data.append("title", title || "");
      data.append("description", description || "");

      dispatch(
        Actions.profile.createSupportTicket(data, {
          "Content-Type": "multipart/form-data",
        })
      );
    } else {
      if (!title) {
        setTitleError(true);
      }
      if (!description) {
        setdescriptionError(true);
      }
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 z-50 flex items-center justify-center" initialFocus={cancelButtonRef} onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-40" />
      </Transition.Child>
  
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* Modal Panel */}
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full sm:max-w-lg sm:mx-auto mx-2">
              <div className="bg-white p-4">
                
                <div className="flex flex-row w-full justify-between bg-bgColor rounded-lg items-center">
                  <h2 className="text-labelColor font-medium ml-5 text-[28px]">Create Ticket</h2>
                  <CloseButton onClick={onClose} />
                </div>
  
              
                <div className="mt-4">
                  <TextFieldComponent
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    error={titleError}
                    errortxt="Please enter a title"
                  />
                  <TextAreaComponent
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={6}
                    error={descriptionError}
                    errortxt="Please enter a description"
                  />
                  <AttachmentUploadCard
                    buttonLabel="Upload attachment"
                    setFileNFileSelected={(file, selected) => {
                      setFile(file);
                      setIsSelected(selected);
                    }}
                    file={file}
                    isFileSelected={isSelected}
                    removeSelectedFile={() => setFile(null)}
                  />
                </div>
  
                {/* Footer Buttons */}
                <div className="mt-4 flex justify-end space-x-4">
                  <OutlineButtonComponent text="Close" onClick={onClose} />
                  <ButtonComponent text="Save" isPrimary onClick={handleSave} />
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
  
  );
};
