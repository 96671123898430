import React from "react";

export const StepperComponent = ({ totalSteps = 3, currentStep = 1 }) => {
  return (
    <div className="stepper-container">
      <div className="stepper-text-wrap">
        <div className="stepper-text-wrap-inner">
          <p className="stepper-txt">
            {currentStep} of {totalSteps}
          </p>
          <div className="stepper-space-dot"></div>
        </div>
      </div>

      <div className="stepper-wrapper">
        {Array.from({ length: totalSteps })?.map((_, index) => {
          return (
            <div
              className={
                currentStep >= index + 1
                  ? "stepper-block-complete"
                  : "stepper-block"
              }
              key={index + 1}
            ></div>
          );
        })}
      </div>
    </div>
  );
};
