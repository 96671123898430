import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import _ from "lodash";
import { Provider } from "react-redux";
import { appHistory, store } from "./core/modules/StoreCreator";
import SplashScreen from "./module/common/screen/SplashScreen";
import { ThemeProvider } from "@material-tailwind/react";
import { Context } from "./core/Context/Context";
import { ApplicationRoutes } from "./routes/ApplicationRoutes";


function App() {
  const [loading, setLoading] = useState(true);
  const contextValue = { data: window.location.pathname };

  useEffect(() => {
    _.delay(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider>
        {loading ? (
          <SplashScreen />
        ) : (
          <>
            <Context.Provider value={contextValue}>
              <BrowserRouter history={appHistory}>
               <ApplicationRoutes/>
              </BrowserRouter>
            </Context.Provider>
          </>
        )}
      </ThemeProvider>
    </Provider>
  );
}

export default App;
