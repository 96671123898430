export const MessagesIcon = ({isSelected=false}) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_2445)">
        <path
          d="M7.99951 0.865723C3.58851 0.865723 -0.000488281 4.45472 -0.000488281 8.86572V16.3657C-0.000488281 16.6417 0.223512 16.8657 0.499512 16.8657H7.99951C12.4105 16.8657 15.9995 13.2767 15.9995 8.86572C15.9995 4.45472 12.4105 0.865723 7.99951 0.865723ZM8.49951 10.8657H4.49951C4.22351 10.8657 3.99951 10.6417 3.99951 10.3657C3.99951 10.0897 4.22351 9.86572 4.49951 9.86572H8.49951C8.77551 9.86572 8.99951 10.0897 8.99951 10.3657C8.99951 10.6417 8.77551 10.8657 8.49951 10.8657ZM11.4995 7.86572H4.49951C4.22351 7.86572 3.99951 7.64172 3.99951 7.36572C3.99951 7.08972 4.22351 6.86572 4.49951 6.86572H11.4995C11.7755 6.86572 11.9995 7.08972 11.9995 7.36572C11.9995 7.64172 11.7755 7.86572 11.4995 7.86572Z"
          fill={isSelected ? '#4283E5':'#2B3674'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2445">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(-0.000488281 0.865723)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
