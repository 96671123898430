import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ButtonComponent, SkillCardlet } from "../../atoms";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { IMAGES } from "../../../assets";
import _ from "lodash";
import { dateFromNow } from "../../../core/Constant";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { JobApplyModal } from "./JobApplyModal";

export const JobViewModal = ({ details, onClose, open = false }) => {
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const [openApply, setOpenApply] = useState(false);
  useEffect(() => {}, [details]);

  const handleSave = () => {
    dispatch(Actions.jobs.saveJob(_.get(details, "id")));
  };

  function createMarkup() {
    return { __html: _.get(details, "description") };
  }
  return (
<div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black max-w-3xl w-full text-left shadow-xl transition-all my-8">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div class="flex flex-row w-full justify-between items-center">
                          <div class="flex gap-x-2 items-center">
                            <img
                              className="w-14 h-14 rounded-full border-white border-2 object-cover"
                              src={IMAGES.DASHBOARD.JOB_IMAGE}
                              alt="Company Logo"
                            />
                            <div className="flex flex-col">
                              <div className="flex items-center gap-x-1">
                                <span className="text-lableColor font-DM font-semibold text-xl">
                                  {_.get(details, "Company.name")}
                                </span>
                                <span className="text-textlightColor font-DM font-normal text-sm">
                                  {dateFromNow(_.get(details, "createdAt"))}
                                </span>
                              </div>
                              <div className="flex flex-row items-center">
                                <span className="text-textlightColor font-DM font-normal text-sm">
                                  {_.get(details, "location")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <CloseButton onClick={onClose} />
                        </div>
                        <div
                          className="bg-cover flex flex-col justify-between mt-6 rounded-lg p-4"
                          style={{
                            backgroundImage: `url(${IMAGES.DASHBOARD.BACKGROUND_IMAGE})`,
                            minHeight: "122px",
                            backgroundSize: "cover",
                          }}
                        >
                          <div className="grid grid-cols-3 w-full">
                            <div className="col-span-2">
                              <span className="text-white font-DM font-bold text-lg">
                                {_.get(details, "title")}
                              </span>
                              <div className="flex gap-x-6">
                                <span className="text-white font-DM font-light text-sm">
                                  {_.get(details, "location")}
                                </span>
                                <span className="text-white font-DM font-light text-sm">
                                  {_.get(details, "job_type")}
                                </span>
                              </div>
                            </div>
                            <div className="col-span-1 flex items-center w-full sm:flex-row flex-col gap-2 sm:gap-4">
                              {/* <div className="flex items-center w-full gap-2"> */}
                                <button className="" onClick={handleSave}>
                                  <SaveICcon isSaved={_.some(_.get(details, 'CandidateJobs'), { type: 'Applied Job' })} />
                                </button>
                                <ButtonComponent
                                  background="bg-white"
                                  fontColor="text-primaryColor"
                                  text="Apply Now"
                                  isDisabled={_.some(_.get(details, 'CandidateJobs'), { type: 'Applied Job' })}
                                  onClick={() => { onClose(); setOpenApply(true); }}
                                  className="w-full sm:w-auto mt-2 sm:mt-0"
                                />
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="my-4 flex-wrap flex flex-row items-start">
                          {_.map(_.get(details, "Skills"), (skill) => (
                            <div key={_.get(skill, "id")}>
                              <SkillCardlet text={_.get(skill, "name")} />
                            </div>
                          ))}
                        </div>
                        <div className="mt-2 w-full min-h-[402px] px-4 py-4 flex flex-col rounded-lg bg-bgColor">
                          <span className="text-lableColor pb-4 font-DM font-medium text-xl">
                            Job Description
                          </span>
                          <span className="text-sm text-gray-500" dangerouslySetInnerHTML={createMarkup()}>
                            {/* {_.get(details, "description")} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <JobApplyModal jobId={_.get(details, "id")} open={openApply} onClose={() => setOpenApply(false)} applyingJob={_.get(details, "title")} />
    </div>
  );
};

const SaveICcon = ({isSaved = false}) => {
  return (
    <svg
      width="55"
      height="48"
      viewBox="0 0 55 48"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="54" height="47" rx="4.5" stroke="white" />
      <path
        d="M33.7143 16H22.2857C21.0286 16 20.0114 16.9 20.0114 18L20 34L28 31L36 34V18C36 16.9 34.9714 16 33.7143 16Z"
        fill={isSaved ? '#4283E5':'#D1D8EB'}
      />
    </svg>
  );
};
