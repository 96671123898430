import { Map } from "immutable";
import _ from "lodash";
import { ModuleEvents } from "./Actions";
//import { ModuleEvents as SigninEvent } from "../signin/Actions";

const InitialState = Map({
  loadingAction: { loading: false, action: {} },
  candidateAllJobs: [],
  jobPost: {},
  appliedJobsCandidate: [],
  savedJobsCandidate: [],
  recruiterJobs: {},
  jobTitleList: [],
  jobOffers: [],
  acceptJobOffer: [],
  rejectJobOffer: [],
});

export const Reducer = (state = InitialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ModuleEvents.LOADING_STARTED: {
      return state.set("loadingAction", { loading: true, action: payload });
    }
    case ModuleEvents.LOADING_FINISHED: {
      return state.set("loadingAction", { loading: false, action: payload });
    }

    case ModuleEvents.GET_ALL_JOB_CANDIDATE: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("candidateAllJobs", data);
      }
      return state;
    }

    case ModuleEvents.GET_ALL_APPLYED_JOBS_CANDIDATE: {
      console.log("payload apply", payload);
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("appliedJobsCandidate", data);
      }
      return state;
    }

    case ModuleEvents.GET_ALL_SAVED_JOBS_CANDIDATE: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("savedJobsCandidate", data);
      }
      return state;
    }

    case ModuleEvents.GET_JOB_POST: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("jobPost", data);
      }
      return state;
    }
    case ModuleEvents.GET_ALL_JOB_RECRUITER: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("recruiterJobs", data);
      }
      return state;
    }

    case ModuleEvents.GET_ALL_JOB_TITLES: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data", {});
        return state.set("jobTitleList", data);
      }
      return state;
    }

    case ModuleEvents.GET_JOB_OFFERS: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data",[]);
        
        return state.set("jobOffers", data);
      }
      return state;
    }

    case ModuleEvents.ACCEPT_JOB_OFFERS: {
      console.log("payyyy: ", payload)
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data");
        console.log("ddd", data)
        return state.set("acceptJobOffer", data);
      }
      return state;
    }

    case ModuleEvents.REJECT_JOB_OFFERS: {
      if (payload && payload.status_code == 1) {
        const data = _.get(payload, "data");
        return state.set("rejectJobOffer", data);
      }
      return state;
    }
  }

  return state;
};
