import React from "react";

export const CheckboxComponent = ({ text,value, onChange = () => {} }) => {
  return (
    <div>
      <div class="inline-flex items-center">
        <label
          class="relative flex items-center p-3 rounded-full cursor-pointer"
          htmlFor="checkbox"
        >
          <input
            type="checkbox"
            class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primaryColor checked:bg-primaryColor checked:before:bg-primaryColor hover:before:opacity-10"
            id="checkbox"
            value={value}
            onChange={onChange}
          />
        </label>
        <span class="text-lableColor font-DM font-semibold  text-md">
          {text}
        </span>
      </div>
    </div>
  );
};
