import React from "react";

export const OutlineButtonComponent = ({
  text = "text",
  isPrimary = true,
  isDisabled = false,
  padding = 'p-2',
  isMore = false,
  // isIcon=false,
  onClick = () => { },
}) => {
  return (
    <button
      className={
        isDisabled
          ? isPrimary
            ? "btn-disabled-more-primary"
            : "btn-disabled-more-secondary"
          : isPrimary
            ? `btn-more-primary ${padding}`
            : `btn-more-secondary ${padding}`
      }
      onClick={onClick}
    >
      {text}
    </button>
  );
};
