import React, { useEffect, useState } from "react";
import { ButtonComponent, TabButtonComponent, TextFieldComponent } from "../../../components/atoms";
import { AnswerViewModal, ScreeningCard } from "../../../components/molecule";
import { connect } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { IMAGE_URL, onToastNotify } from "../../../core/Constant";
import { imageURL } from "../../../core/repository/Repository";
import { InterviewPostModal } from "../../../components/molecule/Modals/InterviewPostModal";

const OffersScreen = ({
  isInitial = false,
  getAllJobRecruiter,
  getOfferRejectedCandidates,
  getOfferAcceptededCandidates,
  getOfferedCandidates,
  recruiterJobs,
  markasQualified,
  markasRejected,
  offeredList,
  OfferAcceptList,
  OfferRejectList,
  getAnswers,
  answersCandidiate,
  markasHired
}) => {
  const [activeTab, setActiveTab] = useState("applied"); 
  const [jobPost, setJobPost] = useState("");
  const [jobs, setJobs] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [appliedCandidates, setAppliedCandidates] = useState([]);
  const [qualifiedCandidates, setQualifiedCandidates] = useState([]);
  const [rejectedCandidates, setRejectedCandidates] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState(null)
  const [selectedcandidiate,setselectedCandidiate] = useState([])
  const [isAnswerModal, setIsAnswerModal] = useState(false)

  useEffect(() => {
    getAllJobRecruiter();
  }, []);

  useEffect(() => {
    setJobs(recruiterJobs || []); 
  }, [recruiterJobs]);

  const handleJobPostChange = (e) => {
    setJobPost(e.target.value); 
    setShowDropdown(true); 
  };

  const handleJobPostSelect = (job) => {
    setJobPost(job.title); 
    setSelectedJob(job); 
    getOfferedCandidates(job.id);
    setShowDropdown(false); 
    console.log(job)
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);

    setCandidateList([]);

    if (selectedJob) {
      if (tabName === "applied") {
        getOfferedCandidates(selectedJob.id);
      } else if (tabName === "qualified") {
        getOfferAcceptededCandidates(selectedJob.id);
      } else if (tabName === "rejected") {
        getOfferRejectedCandidates(selectedJob.id);
      }
    } else {
      onToastNotify("Please Select Job");
    }
  };

  useEffect(() => {
    if (activeTab === "applied" && selectedJob != null) {
      getOfferedCandidates(selectedJob.id);
    }else{
      setAppliedCandidates([]);
    }
  }, [activeTab, selectedJob]);

  useEffect(() => {
    if(selectedJob != null){
      setAppliedCandidates(offeredList || []);
      if (activeTab === "applied") {
        setCandidateList(offeredList || []); // Update only if on applied tab
      }
      setQualifiedCandidates([]);
      setRejectedCandidates([]);
    }else{
      resetListArray();
    }
   
  }, [offeredList]);

  useEffect(() => {
    console.log('activeTab:',activeTab);
    console.log('OfferAcceptList:',OfferAcceptList);
    if(selectedJob != null){
      setQualifiedCandidates(OfferAcceptList || []);
      if (activeTab === "qualified") {
        setCandidateList(OfferAcceptList || []);
      }
      setAppliedCandidates([]);
      setRejectedCandidates([]);
    }else{
      resetListArray();
    }
  
  }, [OfferAcceptList]);

  useEffect(() => {
    if(selectedJob != null){
      setRejectedCandidates(OfferRejectList || []);
      if (activeTab === "rejected") {
        setCandidateList(OfferRejectList || []);
      }
      setAppliedCandidates([]);
      setQualifiedCandidates([]);
    }else{
      resetListArray();
    }
   
  }, [OfferRejectList]);

  const handleOpenModal = (job) => {
    // console.log(job,'jooooooobbbbb')
    setSelectedJobId(job.job_id)
    setselectedCandidiate(job)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedJobId(null)
  }

  const onViewAnswers = (jobId, candidateId) => {
    setSelectedJobId(jobId);
    setselectedCandidiate(candidateId);
    console.log(jobId, candidateId)
    getAnswers({ user_id: candidateId, job_id: jobId })
    setIsAnswerModal(true);
    console.log(answersCandidiate,'answerssss')
  };

  const resetListArray = ()=>{
    setAppliedCandidates([]);
    setCandidateList([]);
    setQualifiedCandidates([]);
    setRejectedCandidates([]);
  }

  function createMarkup() {
    return { __html: selectedJob.description };
  }
  return (
    <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
      <div className="col-span-12 2xl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 ">
        <div className="bg-white p-4 rounded-xl">
          <p className="text-2xl text-darkBlue font-bold font-DM">Offers</p>

          {/* Job Post Autocomplete */}
          <div className="relative">
            <TextFieldComponent
              placeholder="Please type &  select job post"
              value={jobPost}
              onChange={handleJobPostChange}
              label="Job Post"
            />
            {showDropdown && jobPost && (
              <div className="absolute z-10 bg-white shadow-md max-h-40 overflow-y-auto w-full">
                {jobs
                  .filter((job) => job.title.toLowerCase().includes(jobPost.toLowerCase()))
                  .map((job, index) => (
                    <div
                      key={index}
                      className="p-2 cursor-pointer hover:bg-blue-200"
                      onClick={() => handleJobPostSelect(job)}
                    >
                      {job.title}
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* Display selected job details */}
          {selectedJob && (
            <div className="mt-4 border p-4 rounded-lg bg-gray-100 flex items-start">
              <img
                src={IMAGE_URL + selectedJob.job_image}
                alt={`${selectedJob?.title} image`}
                className="w-20 h-20 rounded-full object-cover mr-4"
              />
              <div>
                <p>
                  <strong>Job Title:</strong> {selectedJob.title}
                </p>
                <p>
                  <strong>Job Description:</strong> <p dangerouslySetInnerHTML={createMarkup()}></p>
                </p>
                <p>
                  <strong>Location:</strong> {selectedJob.location}
                </p>
              </div>
            </div>
          )}


          <div className="flex items-center justify-between gap-y-2 flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-row mt-2">
            <div>
              <TabButtonComponent
                buttonOneName="Offered"
                buttonTwoName="Offer Accepted"
                buttonThreeName="Offer Rejected"
                isApplied={activeTab === "applied"}
                isThirdTab={activeTab === "rejected"}
                onClick={handleTabChange} // Directly pass the handler
              />
            </div>
          </div>
        </div>

        {candidateList.length > 0 ? (
          candidateList.map((item, index) => (
            <ScreeningCard        onViewAnswers={() => onViewAnswers(selectedJob.id, item.Candidate.user_id)} onInterview={() => handleOpenModal(item)} key={index} candidate={item} onMarkAsQualified={() => markasQualified(item.id)}
              onMarkAsRejected={() => markasRejected(item.id)} tabName={activeTab} isOffered={true} onOfferJob={() => markasHired(item.id)} />
          ))
        ) : (
          <p class="text-lg mt-2 text-gray-700 font-semibold p-4 bg-white rounded-lg shadow-md border border-gray-200">
            No candidates available.
          </p>

        )}
      </div>
      {isModalOpen && (
          <InterviewPostModal
            jobId={selectedJobId}
            isOpen={isModalOpen}
            candidate={selectedcandidiate}
            onClose={handleCloseModal}
          />
        )}
            {isAnswerModal && (
        <AnswerViewModal
          isOpen={isAnswerModal}
          onClose={() => setIsAnswerModal(false)}
          data={answersCandidiate}
        // onSubmit={handleCreateConversation}
        />
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    recruiterJobs: state.jobs.get("recruiterJobs"),
    offeredList: state.home.get("offeredList"),
    OfferAcceptList: state.home.get("OfferAcceptList"),
    OfferRejectList: state.home.get("OfferRejectList"),
    answersCandidiate: state.home.get("answersCandidiate"),
  }),
  {
    getAllJobRecruiter: Actions.jobs.getAllJobRecruiter,
    getOfferedCandidates: Actions.home.getOfferedCandidates,
    getOfferRejectedCandidates: Actions.home.getOfferRejectedCandidates,
    getOfferAcceptededCandidates: Actions.home.getOfferAcceptededCandidates,
    markasQualified: Actions.home.markasQualified,
    markasRejected: Actions.home.markasRejected,
    getAnswers: Actions.home.getAnswers,
    markasHired: Actions.home.markasHired,
  }
)(OffersScreen);
