import React from "react";
import ReactPlayer from "react-player";

export const VideoPlayerComponent = ({
  videoUrl = "https://www.youtube.com/watch?v=ysz5S6PUM-U",
}) => {
  return (
    <div className="video-player-wrapper">
      <ReactPlayer url={videoUrl}   className='react-player' controls width="100%" height="100%" />
    </div>
  );
};
