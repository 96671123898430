import React, { useEffect, useState } from "react";
import { ButtonComponent, TagComponent } from "../../atoms";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";

const SkillsModal = ({ skills, onSelectSkill, onClose }) => {
  const [selectedModalSkills, setSelectedModalSkills] = useState([]);

  // Toggle skill selection
  const handleSkillClick = (skill) => {
    if (selectedModalSkills.some((selected) => selected.id === skill.id)) {
      setSelectedModalSkills(
        selectedModalSkills.filter((selected) => selected.id !== skill.id)
      );
    } else {
      setSelectedModalSkills([...selectedModalSkills, skill]);
    }
  };

  // Save selected skills and close modal
  const handleSave = () => {
    onSelectSkill(selectedModalSkills); // Pass all selected skills
    onClose(); // Close the modal
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-96 shadow-lg relative">
        <button
          className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
          onClick={onClose}
        >
          ✕
        </button>
        <h3 className="text-lg font-medium mb-4 text-gray-800">Select Skills</h3>
        <div className="max-h-60 overflow-y-auto border border-gray-200 rounded p-2">
          {skills.map((skill) => (
            <div
              key={skill.id}
              className={`cursor-pointer p-2 rounded-lg m-2 transition duration-150 ${
                selectedModalSkills.some((selected) => selected.id === skill.id)
                  ? "bg-gray-300"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => handleSkillClick(skill)}
            >
              {skill.name}
            </div>
          ))}
        </div>
        <div className="flex justify-between mt-4">
          <button
            className="bg-red-500 text-black px-4 py-2 rounded border hover:bg-gray-300 transition duration-150"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-150"
            onClick={handleSave}
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
};



export const InterviewCard = ({
  text = "label",
  onClose,
  close = false,
  isrecruiter = false,
  title = '',
  data = [],
  isexpand = false,
  onfindClick = () => {},
  onViewJob = () => {},
  onBackClick = () => {}
}) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const skills = useSelector((state) => state.common.get('skillData'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.common.getskillList());
  }, [dispatch]);

  // Update multiple selected skills
  const handleSkillSelect = (skills) => {
    const newSkills = skills.filter(
      (skill) => !selectedSkills.some((selected) => selected.id === skill.id)
    );
    setSelectedSkills([...selectedSkills, ...newSkills]);
  };

  const handleRemoveSkill = (idToRemove) => {
    setSelectedSkills((prevSkills) =>
      prevSkills.filter((skill) => skill.id !== idToRemove)
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFindCandidatesClick = () => {
    setIsExpanded(!isExpanded);
    onfindClick();
  };

  
  function createMarkup() {
    return { __html: data.description };
  }



  const onSearchcandidiate = () =>{
    const selectedSkillIds = selectedSkills.map((skill) => skill.id);
       dispatch(Actions.home.searchCandidates(data.id,{skill_ids:selectedSkillIds}))
  }

  return (
    <div className="bg-bgColor rounded-[12px] p-3 sm:p-4 md:p-5 my-3">
      {/* Main Content */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
        {/* <div className="grid grid-cols-6 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 sm:grid-cols-6 gap-4 w-full"> */}
        <div className="w-full md:w-3/4">
            {/* Job Details */}
            <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4">
              <span className="text-lableColor font-DM text-[24px] font-[700]">
                {data?.title}
              </span>
              <span
                className={
                  isrecruiter
                    ? `text-darktextColor font-DM text-[16px] font-[500] ml-4 ${data.active_status ?  'bg-lighterGreen' : ' bg-yellow-300'} py-1 px-4 rounded-lg`
                    : "text-primaryColor font-DM text-[18px] font-[500] ml-4"
                }
              >

                {data?.
active_status  ? 'Active' : 'Closed'}
              </span>
            </div>
            <div className="flex flex-row items-center mb-2">
              <span className="text-ash font-DM text-[18px] font-[500]">
                {data?.location}
              </span>
              <span className="text-lighterGreen font-DM text-[18px] font-[500] ml-4">
                {data?.job_type}
              </span>
            </div>
            <span className="text-lableColor font-DM text-[16px] font-[400]" dangerouslySetInnerHTML={createMarkup()}>
             
            </span>
          </div>
          <div className="w-full md:w-1/4 flex flex-col items-end gap-2">
            <ButtonComponent text="Find Candidates" onClick={handleFindCandidatesClick} />
            <ButtonComponent text="View job" isPrimary={false} onClick={onViewJob} />
          </div>
        {/* </div> */}
      </div>

      {/* Expanded Section */}
      {isExpanded && (
        <div className="my-3 w-full">
          <div className="bg-white p-3 w-full rounded-2xl">
            <span className="text-lableColor font-DM text-[14px] font-normal">
              Find candidates who applied for the {data?.title}
            </span>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-12 mt-2 items-center">
            <div className="col-span-1 lg:col-span-8 grid grid-flow-row-dense grid-cols-2 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-1">
              {selectedSkills.map((skill) => (
                <TagComponent
                  key={skill.id}
                  text={skill.name}
                  id={skill.id}
                  onClose={handleRemoveSkill}
                />
              ))}
            </div>
            <div className="col-span-1 lg:col-span-4 flex flex-col mt-4 items-end">
              <div className="flex flex-row items-center justify-end">
                <TagComponent text={'Add filters'} plus={true} onAdd={openModal} />
              </div>
              <div className="grid grid-flow-row-dense grid-cols-1 gap-2 mt-3 w-full 2xl:w-fit xl:w-fit lg:w-full md:w-fit ">
                {/* <div className="col-span-2 2xl:col-span-1 xl:col-span-1 lg:col-span-2 md:col-span-1">
                  <ButtonComponent
                    isPrimary={false}
                    padding={"p-2"}
                    fontsize="text-sm"
                    text="Back"
                    onClick={onBackClick}
                  />
                </div> */}
                <div className="col-span-2 2xl:col-span-1 xl:col-span-1 lg:col-span-2 md:col-span-1">
                  <ButtonComponent
                    padding={"p-2"}
                    fontsize="text-sm"
                    text="Search candidates"
                    onClick={onSearchcandidiate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Skills Selection */}
      {isModalOpen && (
        <SkillsModal
          skills={skills}
          onSelectSkill={handleSkillSelect}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

