import React from "react";
export const CornerColumnHeading = ({
  heading,
  buttonTxt,
  onClick = () => { },
}) => {
  return (
    <div className="heading-n-view-all">
      <div className="">
        <p className="sub-heading">{heading}</p>
      </div>
      {/* <div className="view-all-btn">
        <p>{buttonTxt}</p>
      </div> */}
    </div>
  );
};
