import React, { useEffect, useState } from "react";
import { ButtonComponent, FavouriteButton } from "../../atoms";
import { JobViewModal } from "../Modals";
import _ from "lodash";
import { dateFromNow, IMAGE_URL } from "../../../core/Constant";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";

export const JobCard = ({item}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleSave = () => {
    dispatch(Actions.jobs.saveJob(_.get(item,'id')));
  }
  
  return (
    <>
     <div class=" bg-white  rounded-xl shadow-md ">
      <div class="flex flex-col items-center p-3">
        <div className="w-full bg-no-repeat bg-cover h-36 relative rounded-md" style={{backgroundImage:`url(${IMAGE_URL + _.get(item,'job_image')})`}}>
          <div class='absolute right-0 p-2'>
            <FavouriteButton favourite={_.some(_.get(item,'CandidateJobs'), { type: 'Saved Job' })} onClick={handleSave}/>
          </div>
        </div>
        <div class='w-full pt-2'>
          <div class={'flex flex-row w-full  bg-white justify-between'}>
            <div class={'flex flex-col flex-auto '}>
              <span class="text-darkBlue font-DM text-lg line-clamp-1 " title={_.get(item,'title')}>{_.get(item,'title')}</span>
              <span class="text-textlightColor font-DM text-xs w-2/3 line-clamp-2"> {_.get(item,'Company.name')}, {_.get(item,'location')}</span>
            </div>
            <span class="text-textlightColor font-DM text-xs mt-1">{dateFromNow(_.get(item,'createdAt'))}</span>
          </div>
          <div class="flex items-center flex-row w-full  justify-between">
            <span class="text-primaryColor font-DM font-semibold text-sm ps-2 ">{_.get(item,'work_type')}</span>
            <div >
              <ButtonComponent padding="p-2" text="View now" onClick={()=>setOpen(true)} />
            </div>
          </div>
        </div>
      </div>
    </div>

    <JobViewModal open={open} details={item} onClose={() => setOpen(false)}/>
    </>
   
  );
};
