import { useState } from "react";
import { useDispatch } from "react-redux";
import { IMAGES } from "../../../assets";
import {
  AUTH_PROCESS_STEPS,
  EMAIL_REGEX,
  getText,
} from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";
import {
  ButtonComponent,
  HeadingComponent,
  TextFieldComponent,
} from "../../atoms";

export const SignUpForm = () => {
  const dispatch = useDispatch();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");

  const onEmailChange = (e) => {
    const text = getText(e);
    let isValid = false;
    const reg = EMAIL_REGEX;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    console.log("isValid", isValid);
    setEmail(text);
    setIsEmailValid(isValid);
    setEmailError(isValid ? false : true);
  };

  const onClickCreateAccount = () => {
    if (isEmailValid) {
      dispatch(Actions.auth.storeUserDetails({ email }));
      dispatch(
        Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signUpFormCandidate })
      );
    } else {
      setEmailError(true);
    }
  };

  const onClickBackBtn = () => {
    dispatch(
      Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.create_account })
    );
  };

  const backToLogin = () => {
    dispatch(Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signIn }));
  };

  const handleSignInWithGoogle = () => {
    window.location.href = "https://api.r60.archnix.dev/auth/google"; 
  };

  const handleMicrosoftLogin = () => {
    window.location.href = "https://api.r60.archnix.dev/auth/microsoft";
  };

  return (
    <div className="sign-in-form-container">
      <div className="back-button-container">
        <button onClick={onClickBackBtn}>
          <div className="button-content flex gap-x-2 items-center">
            <img src={IMAGES.COMMON.LEFT_ARRROW} alt="logo" />
            <p className="font-DM">Back</p>
          </div>
        </button>
      </div>
      <HeadingComponent
        headingText={"Sign up as a candidate"}
        description={""}
      />
      <div className="sign-in-top-section">
        <div onClick={handleSignInWithGoogle} className="sso-button-container">
          <div className="sso-button-content">
            <div className="button-logo">
              <img src={IMAGES.ONBORADING.GOOGLE_ICON} alt="btn-logo" />
            </div>
            <div className="button-text">Sign up with Google</div>
          </div>
        </div>

        <div onClick={handleMicrosoftLogin} className="sso-button-container">
          <div className="sso-button-content">
            <div className="button-logo">
              <img src={IMAGES.ONBORADING.MICROSOFT_ICON} alt="btn-logo" />
            </div>
            <div className="button-text">Sign up with Microsoft</div>
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <div class="flex-grow border-t border-gray-300"></div>
        <span class="mx-4 text-gray-500 text-md">or</span>
        <div class="flex-grow border-t border-gray-300"></div>
      </div>

      <div className="sign-in-content-wrapper">
        <TextFieldComponent
          type="text"
          label="Email"
          placeholder="Enter your email address"
          value={email}
          error={emailError}
          errortxt="Please enter a valid email"
          onChange={onEmailChange}
        />
      </div>

      <div className="submit-button">
        <ButtonComponent
          text="Create account "
          width="w-full"
          isPrimary={true}
          onClick={() => onClickCreateAccount()}
        />
      </div>
      <div>
        <p className="font-DM font-medium text-lableColor">
          Already have an account?
          <span
            className="text-primaryColor font-semibold cursor-pointer"
            onClick={backToLogin}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};
