import Repository from "./Repository";
import _ from "lodash";

const getPath = (path)=>{
    return `user/${path}`;
}

//API CALL for Auth flow
class ProfileRepository extends Repository {


    getCandidateDetails = async () => {
        try {
            const data = await this.getData(getPath('getloggedincandidate'));
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }

    getCompanyDetails= async () => {
        try {
            const data = await this.getData('company/getRecruiterCompany');
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }

    getResumeTemplates = async () => {
        try {
            const data = await this.getData('template/getactiveresumetemplates');
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }




    getCoverLetterTemplates = async () => {
        try {
            const data = await this.getData('template/getactivecoverlettertemplates');
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }




    getCandidateCv = async () => {
        try {
            const data = await this.getData(getPath('getcv'));
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }


    updateCandidate = async (params) => {
        try {
            const data = await this.putData(getPath(`updatecandidate`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };

    updateCandidateProfilePhoto= async (params) => {
        try {
            const data = await this.putData(getPath(`updateprofileimage`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };



    uploadVideo = async (params, token,options) => {
        try {
            const data = await this.putData(getPath(`uploadvideo`), params, token, options);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };

    updateCandidateCv = async (params) => {
        try {
            const data = await this.putData(getPath(`updatecv`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };


    candidatePwChange = async (params) => {
        try {
            const data = await this.putData(getPath(`passwordchange`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    };


    inviteFriends = async (params) => {
        try {
            const data = await this.postData(getPath(`invitefriends`), params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }; 
    
    recruiterEdit = async (id,params) => {
        console.log(params,'parammmmm')
        try {
            const data = await this.putData(`company/updatecompany/${id}`, params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }; 

    getSupportTicketList = async () => {
        try {
            const data = await this.getData(`supportTicket/list`);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }; 

    
    createSupportTicket = async (params) => {
        try {
            const data = await this.postData(`supportTicket/create`,params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }; 

    addTicketReply = async (params) => {
        try {
            const data = await this.postData(`supportTicket/reply`,params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }; 

}

export default new ProfileRepository("profile");
