export const SearchIcon = ({isSelected = false}) => {
    return (
       <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2_149)">
<path d="M14.5213 12.7518L11.1893 9.28631C12.046 8.26788 12.5154 6.98648 12.5154 5.65249C12.5154 2.53576 9.97968 0 6.86294 0C3.74621 0 1.21045 2.53576 1.21045 5.65249C1.21045 8.76923 3.74621 11.305 6.86294 11.305C8.03301 11.305 9.14803 10.9521 10.1013 10.2821L13.4587 13.7739C13.599 13.9196 13.7877 14 13.99 14C14.1814 14 14.3631 13.927 14.5009 13.7943C14.7939 13.5124 14.8032 13.045 14.5213 12.7518ZM6.86294 1.47456C9.1667 1.47456 11.0409 3.34873 11.0409 5.65249C11.0409 7.95625 9.1667 9.83043 6.86294 9.83043C4.55918 9.83043 2.68501 7.95625 2.68501 5.65249C2.68501 3.34873 4.55918 1.47456 6.86294 1.47456Z" fill="#99A2BF"/>
</g>
<defs>
<clipPath id="clip0_2_149">
<rect width="14" height="14" fill="white" transform="translate(0.96875)"/>
</clipPath>
</defs>
</svg>

    )
}