export const DashboardIcon = ({isSelected = false}) => {
    return (
        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_426_1461)">
        <path d="M6.01154 0H1.13729C0.510155 0 0 0.402622 0 0.897565V3.20567C0 3.70071 0.510155 4.10333 1.13729 4.10333H6.01154C6.6388 4.10333 7.14895 3.70071 7.14895 3.20567V0.897565C7.14895 0.402622 6.6388 0 6.01154 0Z" fill="#2B3674"/>
        <path d="M6.01154 5.12891H1.13729C0.510155 5.12891 0 5.53153 0 6.02656V11.4121C0 11.9071 0.510155 12.3097 1.13729 12.3097H6.01154C6.6388 12.3097 7.14895 11.9071 7.14895 11.4121V6.02656C7.14895 5.53153 6.6388 5.12891 6.01154 5.12891Z" fill="#2B3674"/>
        <path d="M14.4604 8.20654H9.58614C8.95889 8.20654 8.44873 8.60917 8.44873 9.1042V11.4123C8.44873 11.9072 8.95889 12.3099 9.58614 12.3099H14.4604C15.0875 12.3099 15.5977 11.9072 15.5977 11.4123V9.1042C15.5977 8.60917 15.0875 8.20654 14.4604 8.20654Z" fill="#2B3674"/>
        <path d="M14.4604 0H9.58614C8.95889 0 8.44873 0.402622 8.44873 0.897565V6.28314C8.44873 6.77818 8.95889 7.1808 9.58614 7.1808H14.4604C15.0875 7.1808 15.5977 6.77818 15.5977 6.28314V0.897565C15.5977 0.402622 15.0875 0 14.4604 0Z" fill="#2B3674"/>
        </g>
        <defs>
        <clipPath id="clip0_426_1461">
        <rect width="15.5976" height="12.3099" fill="white"/>
        </clipPath>
        </defs>
        </svg>


    )
}