import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../../assets";
import { HeadingComponent } from "../../atoms";
import { AUTH_PROCESS_STEPS, onToast, onToastNotify, USER_ROLE } from "../../../core/Constant";
import { getUserType, setToken, setUserType } from "../../../core/UtilMethods";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { navigateTo } from "../../../core/services/NavigationServicd";

export const CreateAccountForm = () => {
  const dispatch = useDispatch();
  const [sso, setSso] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState('');
  const [profileToken, setProfileDataToken] = useState(null);
  const [signupProcess, setSignupProcess] = useState(false);
  const [microsoft_id, setMicrosoft] = useState();
  const [google_id, setGoogle] = useState();
  const [message, setMessage] = useState();

  const onClickButton = () => {
    setUserType(USER_ROLE.candidate);
    dispatch(Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signUp }));
  };
  const onClickButtonRecruiter = () => {
    setUserType(USER_ROLE.recruiter);
    dispatch(
      Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signUpFormRecruiter })
    );
  };
  const handleClickLogin = () => {
    dispatch(Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signIn }));
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      setProfileDataToken(token);
    }
    const process = params.get('process');
    const resource = params.get('res');
    // console.log(process, resource,token, 'processssssssss')
    if (process) {
      if (resource == 'google') {
        setGoogle(process);
      } else {
        setMicrosoft(process);
      }

      setSignupProcess(process);
      setSso(true)
    }
    const urlEmail = params.get('email');

    if (urlEmail) {
      setEmail(urlEmail);
    }
    const urlMessage = params.get('message');
    if (urlMessage) {
      setMessage(urlMessage);
    }

    if (signupProcess == 'true' || signupProcess == true ) {
      // console.log(google_id, microsoft_id, 'ssoooooo')
      dispatch(Actions.auth.storeUserDetails({ email, google_id, microsoft_id, signupProcess }));
      if (getUserType() == USER_ROLE.recruiter) {
        navigateTo("/onboarding-process");
        // console.log('recruiterrr')
      }
      else {
        // console.log('candidiateee')
        navigateTo("/onboarding-process-candidate")
      }
      // console.log(urlMessage,'url messageeeeee')
      onToast('Authentication',{message:urlMessage,status_code:process ? 1 : 0})
    }else{
       // console.log(urlMessage,'url messageeeeee')
      if(urlMessage != "" && signupProcess == 'false'){
        navigateTo("/");
        onToast('Authentication',{message:urlMessage,status_code:0})
      }
    }
  }, [signupProcess, message]);
  return (
    <div className="create-your-account-container">
      <div>
        <HeadingComponent
          headingText={"Create your account"}
          description={"Enter your email and password to sign in!"}
        />
      </div>
      <div className="process-select-section">
        <div
          className="process-select-btn-container"
          onClick={() => {
            onClickButton();
          }}
        >
          <div className="process-select-upper-section">
            <div>
              <h2 className="primary-heading-md">Continue as a Candidate</h2>
            </div>
            <div>
              <img src={IMAGES.ONBORADING.ICON_ONBOARDING_ARROW} alt="logo" />
            </div>
          </div>
          <div className="w-auto">
            <p className="small-text-description">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga,
              aperiam! Consequatur, recusandae.
            </p>
          </div>
        </div>

        <div
          className="process-select-btn-container"
          onClick={() => {
            onClickButtonRecruiter();
          }}
        >
          <div className="process-select-upper-section">
            <div>
              <h2 className="primary-heading-md">Continue as a Recruiter</h2>
            </div>
            <div>
              <img src={IMAGES.ONBORADING.ICON_ONBOARDING_ARROW} alt="logo" />
            </div>
          </div>
          <div className="w-auto">
            <p className="small-text-description">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga,
              aperiam! Consequatur, recusandae.
            </p>
          </div>
        </div>
      </div>
      <div>
        <p className="font-DM font-medium text-lableColor">
          Already have an Account?
          <span
            className="text-primaryColor font-semibold cursor-pointer ps-1"
            onClick={handleClickLogin}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};
