import React, { useEffect, useState } from "react";
import {
  ButtonComponent,
  DropdownComponent,
  HeadingComponent,
  StepperComponent,
  TextFieldComponent,
} from "../../atoms";
import { IMAGES } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { useNavigate } from "react-router-dom";
import { getFile, getText, onToast, onToastNotify } from "../../../core/Constant";

export const RecruiterStepOne = ({ setUpdateCandidateStep = () => {} }) => {
  const userDetails = useSelector((state) => state.auth.get('userDetails'));
  const industryList = useSelector((state) => state.common.get('industryList'));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [company, setCompany] = useState("");
  const [isValidCompany,setIsvalidCompany] = useState(false)
  const [errorCompany,setErrorCompany] = useState()
  const [isValidImage,setIsvalidImage] = useState(false)
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    dispatch(Actions.common.getindustryList());
  }, [dispatch]);
  
  useEffect(() => {
    console.log(industryList, 'industry list');
  }, [industryList]);


  const handleImageUpload = (event) => {
    const file = getFile(event);
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(file);
      setSelectedImage(imageUrl);
      setIsvalidImage(true)
    }
  };

  // Map the industryList to dropdown options
  const dropdownOptions = industryList?.map((industry) => ({
    id: industry.id,
    name: industry.name,
  }));

  const onsetCompany = (e) =>{
    const text = getText(e);
    setCompany(text)
    setErrorCompany("");
    setIsvalidCompany(true)
  }

  const handleIndustrySelect = (option) => {
    setSelectedIndustry(option);
    setError(""); 
  };

  const onClickCreateAccount = () => {
   if(isValidCompany && isValidImage && selectedIndustry){
    dispatch(Actions.auth.storeUserDetails({
      image,
      company,
      industryId: selectedIndustry.id, 
    }));
    setUpdateCandidateStep(5);
   }
   if(!isValidImage){
    onToastNotify("Select Image",{message:'Please Select Image',status_code:0})
   }
    if (company== "") {
      setErrorCompany("Company name is required.");
      return;
    }
    if (!selectedIndustry) {
      setError("Please select an industry.");
      return;
    }

  };

  return (
    <div className="sign-in-form-container">
      <div className="back-button-container">
        <StepperComponent totalSteps={3} currentStep={1} />
      </div>

      <div className="upload-photo-container relative rounded-full cursor-pointer bg-[#E4F1FC] w-24 h-24">
        <input
          type="file"
          accept="image/*"
          className="absolute inset-0 opacity-0 cursor-pointer"
          onChange={handleImageUpload}
        />
        <div className="upload-photo-inner-content flex flex-col gap-y-2 justify-center h-full items-center p-2">
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected"
              className="rounded-full w-full h-full object-cover"
            />
          ) : (
            <>
              <img src={IMAGES.COMMON.UPLOAD_ICON} alt="icon-upload" />
              <p className="font-DM text-sm text-center">Upload photo</p>
            </>
          )}
        </div>
        <div className="absolute bottom-0 right-0">
          <img src={IMAGES.COMMON.ADD_ICON} alt="icon-add" className="w-6" />
        </div>
      </div>

      <div className="w-full 2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-2/3 mt-4">
        <TextFieldComponent
          label="What is your company name?"
          placeholder="Enter your company name"
          value={company}
          error={errorCompany}
          onChange={onsetCompany}
          errortxt={errorCompany}
        />
      </div>

      <div className="w-full 2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-2/3 mt-4">
        <DropdownComponent
          label="What industry do you work in?"
          dropdownOptions={dropdownOptions}
          onOptionSelected={handleIndustrySelect}
          error={!!error}
          errortxt={error}
        />
      </div>

      <div className="submit-button flex items-center gap-3 w-full 2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-1/3 mt-6">
        <ButtonComponent
          padding="p-2"
          text="Continue"
          width="w-full"
          isPrimary={true}
          onClick={onClickCreateAccount}
        />
      </div>
      
      <div className="mt-4">
        {/* <p className="font-DM font-medium text-lableColor">
          Already have an account?
          <span
            className="text-primaryColor font-semibold cursor-pointer"
            onClick={() => navigate("/login")} // Assuming you want to navigate to login
          >
            {" "}
            Login
          </span>
        </p> */}
      </div>
    </div>
  );
};
