import React, { useCallback} from "react";
import { CloudIcon, PdfIcon } from "../../../assets/svg";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import _ from "lodash";

export const AttachmentOnClick = ({setFileNFileSelected = () => {}, file = {}, isFileSelected = false, removeSelectedFile = () => {},attached = false}) => {
    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles.length, 'jjjjh')
        if (acceptedFiles.length > 0) {
          setFileNFileSelected(acceptedFiles, true);
        } else {
          Swal.fire({
            title: "Files not selected",
            text: "Please select a  file (.pdf, png, jpg or .doc)",
            icon: "info",
          });
          setFileNFileSelected(null, false);
        }
      }, []);
      const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div>
    {!isFileSelected ? (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="bg-[#EAF3F6] border border-dashed flex items-center justify-center rounded-xl border-gray-500 p-4 h-20">
            <span className="font-DM text-gray-400 font-medium text-center">
              Drag and drop files here
            </span>
          </div>
        ) : (
            <button disabled={attached} class="flex items-center justify-center h-10 w-8 text-gray-400">
            <svg
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.82107 2.19995C7.90377 2.19995 6.13637 2.34844 4.71776 2.52438C3.44103 2.68271 2.47831 3.58675 2.3097 4.78565C2.12234 6.11779 1.96421 7.77746 1.96421 9.57789C1.96421 11.3783 2.12234 13.038 2.3097 14.3701C2.47831 15.569 3.44103 16.4731 4.71776 16.6314C6.13637 16.8073 7.90377 16.9558 9.82107 16.9558C11.7384 16.9558 13.5058 16.8073 14.9244 16.6314C16.2011 16.4731 17.1638 15.569 17.3325 14.3701C17.5198 13.038 17.6779 11.3783 17.6779 9.57789C17.6779 7.77746 17.5198 6.11779 17.3325 4.78565C17.1638 3.58675 16.2011 2.68271 14.9244 2.52438C13.5058 2.34844 11.7384 2.19995 9.82107 2.19995ZM4.46057 0.695769C2.30309 0.963341 0.647331 2.51817 0.36239 4.54415C0.166501 5.93696 0 7.67925 0 9.57789C0 11.4765 0.166501 13.2188 0.36239 14.6116C0.647331 16.6376 2.30308 18.1924 4.46057 18.46C5.94379 18.644 7.79918 18.8003 9.82107 18.8003C11.843 18.8003 13.6984 18.644 15.1816 18.46C17.3391 18.1924 18.9948 16.6376 19.2798 14.6116C19.4756 13.2188 19.6421 11.4765 19.6421 9.57789C19.6421 7.67925 19.4756 5.93696 19.2798 4.54415C18.9948 2.51817 17.3391 0.963341 15.1816 0.695769C13.6984 0.51182 11.843 0.355469 9.82107 0.355469C7.79918 0.355469 5.9438 0.51182 4.46057 0.695769Z"
                fill="#99A2BF"
              />
              <path
                d="M6.87651 8.65577C7.96132 8.65577 8.84073 7.82996 8.84073 6.81128C8.84073 5.7926 7.96132 4.9668 6.87651 4.9668C5.79171 4.9668 4.9123 5.7926 4.9123 6.81128C4.9123 7.82996 5.79171 8.65577 6.87651 8.65577Z"
                fill="#99A2BF"
              />
              <path
                d="M15.8346 10.2303L17.5968 11.8851C17.536 12.7485 17.4472 13.5497 17.3494 14.2612L14.4457 11.5346C14.0622 11.1744 13.4403 11.1744 13.0568 11.5346L10.9241 13.5373C9.77348 14.6177 7.90797 14.6177 6.75736 13.5373L6.58886 13.379C6.20532 13.0189 5.58348 13.0189 5.19995 13.379L2.83399 15.6008C2.5599 15.2518 2.37652 14.833 2.31144 14.3703C2.27595 14.1179 2.2415 13.8538 2.20898 13.5792L3.81104 12.0748C4.96165 10.9943 6.82716 10.9943 7.97777 12.0748L8.14627 12.233C8.52981 12.5932 9.15164 12.5932 9.53518 12.233L11.6679 10.2303C12.8185 9.14983 14.684 9.14983 15.8346 10.2303Z"
                fill="#99A2BF"
              />
            </svg>
          </button>
        )}
      </div>
    ) : (
      <div className="flex flex-row items-center min-h-[57px] max-w-[345px] rounded-[13px]  justify-between bg-liteGray2">
        <div className="pl-4">
          <PdfIcon />
        </div>
        <span className="text-lableColor  ml-2 font-DM font-[500]] text-[16px]">
          {_.get(file,'name')}
        </span>
        {/* <button className="bg-darkGray rounded-tr-[13px] rounded-br-[13px] w-[50px] items-center justify-center" onClick={removeSelectedFile}>
          <Icon />
        </button> */}
      </div>
    )}
  </div>
  )
}
