import React, { useState } from "react";
import { DialogComponent } from "../../atoms";
import { NotesModal } from "../Modals";
import _ from "lodash";
import { dateFromNow } from "../../../core/Constant";

export const NotesCard = ({ details }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="bg-white rounded-md h-28 overflow-hidden w-full p-3">
        <div className={"w-full"}>
          <div className="h-14">
          <span className="text-lableColor font-semibold font-DM text-sm line-clamp-3">
            {_.get(details, "title")}
          </span>
          </div>
          <div className="flex items-center flex-row w-full mt-2 justify-between h-5">
            <span className="text-textlightColor font-DM font-normal text-xs">
              {dateFromNow(_.get(details, "created_at"))}
            </span>
            <div>
              <span className="text-primaryColor font-DM font-medium text-sm cursor-pointer" onClick={() => setOpen(true)}>
                View
              </span>
            </div>
          </div>
        </div>
      </div>

      <NotesModal open={open} noteDetails ={details} onClose={()=>setOpen(false)}/>
    </>
  );
};
