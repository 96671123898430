import React, { useEffect, useState } from 'react';
import { getToken, setToken, setUserType } from '../../core/UtilMethods';
import { Navigate, Outlet } from 'react-router-dom';
import { connect, useDispatch, useSelector} from "react-redux";
import { Actions } from '../../core/modules/Actions';
import _ from "lodash";

export const PrivateRoute = () => {
    const [loading, setLoading] = useState(true); 
    const [newToken, setNewToken] = useState(null);
    const dispatch = useDispatch();
    const path = window.location.pathname;

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('a_token');
        const type = params.get('r_state');
        if (token) {
            setToken(token);
            setNewToken(token); 
            setUserType(type)
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        dispatch(Actions.auth.userTokenVerify());
    }, [path]);

    const auth = getToken();

    if (loading) {
        return <div>Loading...</div>; 
    }

    return (
        <>
            {auth || newToken ? <Outlet /> : <Navigate to="/" />}
        </>
    );
};
