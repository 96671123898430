import React, { useEffect, useState } from "react";
import {
  ButtonComponent,
  TextFieldComponent,
} from "../../../components/atoms";
import { useDispatch } from "react-redux";
import { EMAIL_REGEX, getText, onToastNotify, PASSWORD_REGEX } from "../../../core/Constant";
import Swal from "sweetalert2";
import { Actions } from "../../../core/modules/Actions";
import { getCurrentUserDetails } from "../../../core/UtilMethods";
import _ from "lodash";

export default ({}) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [passwordIsValid, setPasswordIsvalid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordIsValid, setNewPasswordIsvalid] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordIsValid, setConfirmPasswordIsvalid] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [userEmail] = useState(_.get(getCurrentUserDetails(), 'email'));

  const onChangePassword = (e) => {
    const text = getText(e);
    let isValid = false;
    const reg = PASSWORD_REGEX;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setPassword(text);
    setPasswordIsvalid(isValid);
    setPasswordError(false);
  };

  const onChangeNewPassword = (e) => {
    const text = getText(e);
    let isValid = false;
    const reg = PASSWORD_REGEX;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setNewPassword(text);
    setNewPasswordIsvalid(isValid);
    setNewPasswordError(false);
  };

  const onChangeConfirmPassword = (e) => {
    const text = getText(e);
    let isValid = false;

    if (newPassword === text) {
      isValid = true;
      console.log('vv: ', isValid)
    } else {
      isValid = false;
    }

    setConfirmPassword(text);
    setConfirmPasswordIsvalid(isValid);
    setConfirmPasswordError(false);
  };

  const handleEdit = () => {
    if (
      password &&
      newPassword &&
      confirmPassword &&
      newPasswordIsValid &&
      passwordIsValid &&
      confirmPasswordIsValid
    ) {
      dispatch(
        Actions.profile.candidatePwChange({
          current_password: password,
          new_password: newPassword,
          confirm_password: confirmPassword,
        })
      );
    } else {
      onToastNotify("Please confirm entered details!")
    }
  };

  return (
    <div className="subscreen-container">
      <div className="flex flex-col ">
        <span class="text-lableColor font-DM text-2xl font-bold">
          Account Settings
        </span>
        <span class="text-ash font-DM text-sm font-normal">
          {/* In publishing and graphic design, Lorem ipsum is a placeholder */}
        </span>
      </div>
      <div className="two-col-inputs">
        <div className="input-field-wrap">
          <TextFieldComponent readOnly={true} value={userEmail} label="Email" />
        </div>
      </div>
      <div className="two-col-inputs">
        <div className="input-field-wrap">
          <TextFieldComponent
            value={password}
            error={passwordError}
            errortxt="Please enter a valid Password"
            type="password"
            placeholder="Enter your current password"
            onChange={(e) => onChangePassword(e)}
            label="Current Password"
          />
        </div>
        <div className="input-field-wrap">
          <TextFieldComponent
            value={newPassword}
            error={newPasswordError}
            errortxt="Please enter a valid Password"
             placeholder="Enter your New password"
            type="text"
            onChange={(e) => onChangeNewPassword(e)}
            label="New Password"
          />
        </div>
        <div className="input-field-wrap">
          <TextFieldComponent
            type="text"
            value={confirmPassword}
            error={confirmPasswordError}
            errortxt="Password do not match"
             placeholder="Confirm your password"
            onChange={(e) => onChangeConfirmPassword(e)}
            label="Confirm Password"
          />
        </div>
      </div>
      <div className="w-1/5">
        <ButtonComponent
          width="w-full"
          padding="p-2"
          text="Edit"
          isPrimary={true}
          onClick={()=> handleEdit()}
        />
      </div>
    </div>
  );
};
