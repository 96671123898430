export const HelpIcon = ({isSelected = false}) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0C3.35476 0 0 3.35438 0 7.5C0 11.6452 3.35438 15 7.5 15C11.6452 15 15 11.6456 15 7.5C15 3.35476 11.6456 0 7.5 0ZM7.27808 11.0103C6.85356 11.0103 6.52778 10.6549 6.52778 10.2501C6.52778 9.83549 6.86344 9.48999 7.27808 9.48999C7.69274 9.48999 8.03821 9.83552 8.03821 10.2502C8.03821 10.6549 7.70256 11.0103 7.27808 11.0103ZM8.40349 7.17C7.86053 7.59451 7.85062 7.89067 7.85062 8.40401C7.85062 8.59163 7.75189 8.80881 7.26817 8.80881C6.86338 8.80881 6.72521 8.66071 6.72521 8.14737C6.72521 7.29835 7.10036 6.89358 7.38665 6.64679C7.71243 6.37034 8.26529 6.06434 8.26529 5.53125C8.26529 5.07709 7.8704 4.85991 7.37678 4.85991C6.36981 4.85991 6.58702 5.62011 6.05388 5.62011C5.78733 5.62011 5.46155 5.44236 5.46155 5.05737C5.46155 4.52429 6.07362 3.73447 7.4064 3.73447C8.67003 3.73447 9.50918 4.43543 9.50918 5.36341C9.50918 6.29139 8.67003 6.9627 8.40349 7.17Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
    </svg>
  );
};
