import React, { useState } from "react";
import { IMAGES } from "../../../assets/images";
import { ButtonComponent, TagComponent, TextAreaComponent } from "../../atoms";
import { imageURL } from "../../../core/repository/Repository";
import { getText, onToast, onToastNotify } from "../../../core/Constant";
import { IMAGE_URL } from "../../../core/Constant";
import moment from "moment";

export const ScreeningCard = ({
  candidate,
  tabName,
  onClose,
  close = false,
  isrecruiter = false,
  onMarkAsQualified,
  onMarkAsRejected,
  onOfferJob,
  onInterview,
  onCreateConversation,
  onViewAnswers,
  onInitiateScreening,
  isOffered = false,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [selectedCandidatesIds, setSelectedCandidatesIds] = useState([]);
  const [feedback, setFeedback] = useState("");

  const handlePlayPause = () => {
    const video = document.getElementById(`screeningVideo-${candidate.id}`);
    if (isVideoPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsVideoPlaying(!isVideoPlaying);
  };

 
  const handleOpenCV = () => {
    if (candidate?.Candidate.cv_url) {
      window.open(IMAGE_URL + candidate?.Candidate.cv_url
        , "_blank"); 
    } else {
      onToastNotify('CV Not Available')
    }
  };

  const onChangeFeedback = (e) => {
    const text = getText(e);
    setFeedback(text);
  }; 


  return (
    <div className="bg-white rounded-lg p-4 my-3">
      <div className="grid grid-cols-1 md:grid-cols-12 w-full gap-3 items-center">
        <div className="h-full col-span-12 md:col-span-3 flex items-center relative rounded-xl bg-black">
        {candidate?.Candidate.video_url != null && candidate?.Candidate.video_url != "" ? (<>
          <video
            id={`screeningVideo-${candidate.id}`} // Use candidate id for unique video id
            className="h-full w-full rounded-xl"
            onClick={handlePlayPause}
            controls={isVideoPlaying ? "controls" : ""}
          >
            <source
              src={IMAGE_URL + candidate?.Candidate.video_url || ""}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          {!isVideoPlaying && (
            <button
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-3"
              onClick={handlePlayPause}
            >
              <svg
                width="100"
                height="100"
                viewBox="0 0 125 125"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="125" height="125" rx="62.5" fill="#4283E5" />
                <g clipPath="url(#clip0_758_13992)">
                  <path
                    d="M52.0612 38.1466C47.3731 35.4574 43.5723 37.6604 43.5723 43.0631V82.9331C43.5723 88.3412 47.3731 90.5413 52.0612 87.8547L86.9095 67.8695C91.5991 65.1793 91.5991 60.821 86.9095 58.1315L52.0612 38.1466Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_758_13992">
                    <rect
                      width="52"
                      height="52"
                      fill="white"
                      transform="translate(41 37)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
           )}
         </>) : (
          <div className="h-full w-full flex items-center justify-center bg-white">
            <p className="text-gray-500">No video available</p>
          </div>
         )}
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col mb-4">
            <div className="flex flex-row w-full justify-between">
              <span className="text-lableColor font-DM text-[24px] font-medium">
                {candidate?.candidate_name}
              </span>
              { candidate.is_screening_attempted && tabName != 'applied' ?                <span
                onClick={onViewAnswers}
                className={
                  `text-darktextColor font-DM text-[16px] font-[500] ml-4  bg-lighterGreen py-1 px-4 rounded-lg`
                }
              >

                {'View Answers'}
              </span> : <></>
              }
            </div>
          
            <span className="text-lighterGreen font-DM text-[16px] font-[500]">
              {candidate?.matching_percentage}% Matching profile
            </span>
            <span className="text-ash font-DM text-[12px] font-medium">
              Applied at: {moment(candidate?.applied_at).format('MMM,DD YYYY')}
              <span className="float-right">Contact No: {candidate?.contact_number}</span>
            </span>
            {tabName == 'qualified' || tabName == 'rejected' || (tabName=='applied' && isOffered==true) ? 
             <TextAreaComponent placeholder="No feedback" value={candidate?.feedback??""} isdisabled={true}/>
            :
            <TextAreaComponent placeholder="Feedback to candidate" value={feedback}  onChange={(e) => onChangeFeedback(e)} />
            }
           
          </div>
        </div>
        <div className="flex flex-col col-span-12 md:col-span-3 gap-2">
          {tabName == "qualified" ? (
             isOffered ? (<>
             
             <ButtonComponent
                text="Hire"
                width="w-full"
                isPrimary={false}
                onClick={onOfferJob}

              />
             </>):(
            <>
              <ButtonComponent onClick={onInterview} text="Schedule Interview" width="w-full" />
              <ButtonComponent
                text="Create Conversation"
                width="w-full"
                isPrimary={false}
                onClick={onCreateConversation}

              />

              <ButtonComponent
                text="Offer"
                width="w-full"
                isPrimary={false}
                onClick={onOfferJob}

              />
              <ButtonComponent
                text="Reject"
                width="w-full"
                isPrimary={false}
                onClick={onMarkAsRejected}

              />
            </>)
          ) : (
             tabName == "screening" ? (<>
              <ButtonComponent
                text="Mark as Qualified"
                // onClick={onMarkAsQualified}
                onClick={() => onMarkAsQualified(candidate.id, feedback)}
                isPrimary={false}
                width="w-full"
              />
              <ButtonComponent
                text="Reject"
                width="w-full"
                isPrimary={false}
                onClick={onMarkAsRejected}
              />
             </>):(<></>)
          )}
          {tabName === "applied" && (
            isOffered ? (<></>):(
            <>
              <ButtonComponent onClick={onInitiateScreening} width="w-full"  text={"Initiate Screenings"} isPrimary={false} />
              <ButtonComponent
                text="Mark as Qualified"
                onClick={() => onMarkAsQualified(candidate.id, feedback)}
                isPrimary={false}
                width="w-full"
              />
              <ButtonComponent
                text="Reject"
                width="w-full"
                isPrimary={false}
                onClick={onMarkAsRejected}
              />
            </>)
          )}
          <ButtonComponent
            icon={true}
            text="Download CV / Resume"
            width="w-full"
            isPrimary={false}
            onClick={handleOpenCV}
          />
        </div>
      </div>
    </div>
  );
};
