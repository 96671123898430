import React from "react";

export const ChipCardlet = ({
    text = "",
    isPrimary = false,
    isDisabled = false,
    // isIcon=false,
    onClick = () => { },
    video = false
}) => {
    return (
        <div class="border h-fit w-fit p-2 rounded-2xl flex justify-center ">
            <span class="text-grey3 font-DM font-normal text-sm text-center">{text}</span>
        </div>

    );
};




