import {
  HomeIcon,
  InterviewIcon,
  JobOffers,
  MessagesIcon,
  VideoIcon,
  ProfileIcon,
  TemplatesIcon,
  UpdateCVIcon,
  SettingIcon,
  InviteFriendsIcon,
  HelpIcon,
  MenuVideoIcon,
  CandidateIcon,
  ScreeningIcon,
} from "../assets/svg";

const DashboardSubMenu = [
  { icon: <HomeIcon />, name: "Dashboard", url: "/dashboard" },
  { icon: <MessagesIcon />, name: "Messages", url: "/messages" },
  { icon: <InterviewIcon />, name: "Screening Questions", url: "/screening-questions" },
  { icon: <InterviewIcon />, name: "Interviews", url: "/interviews" },
  { icon: <JobOffers />, name: "Job Offers", url: "/job_offers" },
];

const ProfileSubMenu = [
  { icon: <ProfileIcon />, name: "Profile", url: "/profile" },
  { icon: <MenuVideoIcon />, name: "Video 60s", url: "/video" },
  { icon: <InterviewIcon />, name: "Skills", url: "/skill-requrement" },
  { icon: <TemplatesIcon />, name: "Templates", url: "/templates" },
  { icon: <UpdateCVIcon />, name: "Update CV", url: "/cv" },
  { icon: <SettingIcon />, name: "Settings", url: "/settings" },
  {
    icon: <InviteFriendsIcon />,
    name: "Invite Friends",
    url: "/invite-friends",
  },
  { icon: <HelpIcon />, name: "Help", url: "/help" },
];

const RecruitSubMenuDashboard = [
  { icon: <HomeIcon />, name: "Home", url: "/recruiter_dashboard" },
  {
    icon: <CandidateIcon />,
    name: "Candidate",
    url: "/recruiter_screeningquestion",
    nest_menu: [
      { name: "Screenings", url: "/recruiter_screeningquestion" },
      { name: "Qualified", url: "/recruiter_qualifiedcandidate" },
      { name: "Message", url: "/recruiter_messagescreen" },
      { name: "Interviews", url: "/recruiter_interviewscreen" },
      { name: "Offers", url: "/recruiter_offersscreen" },
      // { name: "Invite Friends", url: "/invite-friends" },
      { name: "Hired", url: "/recruiter-hired" }
    ],
  },
];

const RecruitmobileMenuDashboard = [
  { icon: <HomeIcon />, name: "Home", url: "/recruiter_dashboard" },
  { icon: <ScreeningIcon />, name: "Screenings", url: "/recruiter_screeningquestion" },
  { icon: <JobOffers />, name: "Qualified", url: "/recruiter_qualifiedcandidate" },
  { icon: <JobOffers />, name: "Offers", url: "/recruiter_offersscreen" },
  { icon: <JobOffers />, name: "Hired", url: "/recruiter-hired" },
  { icon: <InterviewIcon />, name: "Interviews", url: "/recruiter_interviewscree" },
  { icon: <MessagesIcon />, name: "Message", url: "/recruiter_messagescreen" },

];

const ProfileSubMenuRecruiter = [
  { icon: <HomeIcon />, name: "Home", url: "/recruiter_dashboard" },
  { icon: <ProfileIcon />, name: "Profile", url: "/profile" },
  // { icon: <SettingIcon />, name: "Settings", url: "/settings" },
];

export const SUBMENUS = {
  DashboardSubMenu,
  ProfileSubMenu,
  RecruitSubMenuDashboard,
  RecruitmobileMenuDashboard,
  ProfileSubMenuRecruiter
};
