import Repository from "./Repository";
import _ from "lodash";

const getPath = (path) => {
  return `interviews/${path}`;
};
class InterviewRepository extends Repository {
  getInterviewsCandidate = async () => {
    try {
      const data = await this.getData(getPath(`getInterviewsWithoutFeedback`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getInterviewDetailsCandidate = async (param) => {
    try {
      const data = await this.getData(getPath(`getInterviewByIdForCandidate/${param}`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };


  getJobOffersCandidate = async () => {
    try {
      const data = await this.getData(getPath(`getjoboffersforcandidate`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };
}
export default new InterviewRepository("interview");
