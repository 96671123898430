import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ListItem } from "@material-tailwind/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../assets";
import {
  DashboardIcon,
  MyJobsIcon,
  NotificationIcon,
  SalaryGuideIcon,
  SearchIcon
} from "../../../assets/svg";
import { IMAGE_URL, USER_ROLE } from "../../../core/Constant";
import { Actions } from "../../../core/modules/Actions";
import { getUserType } from "../../../core/UtilMethods";
import { SUBMENUS } from "../../../Menus/SubMenu";
import { NotificationView } from "../../molecule/Modals";
import _ from "lodash";

const navigation = [
  {
    name: "Dashboard",
    url: "/dashboard",
    current: true,
    icon: <DashboardIcon />,
  },
  { name: "My Jobs", url: "/my-jobs", current: false, icon: <MyJobsIcon /> },
  { name: "Search", url: "/job-search", current: false, icon: <SearchIcon /> },
  // {
  //   name: "Salary Guide",
  //   url: "/salary_expectation",
  //   current: false,
  //   icon: <SalaryGuideIcon />,
  // },
];

const navigationRecruiter = [
  {
    name: "Manage Jobs",
    url: "/recruiter-managejobs",
    current: false,
    icon: <></>,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Header = () => {
  const [isBellClick, setIsBellCLick] = useState(false);
  const [logoUrl, setLogoUrl] = useState(getUserType() == USER_ROLE.candidate ? "/dashboard" : "/recruiter_dashboard");
  const dispatch = useDispatch();
  const profilePicture = useSelector((state) =>
    state.auth.get("profile_picture")
  );
  const unreadNotifications = useSelector((state) =>
    state.home.get("unreadNotifications")
  );
  const tokenDetails = useSelector((state) =>
    state.auth.get("tokenDetails")
  );
  useEffect(() => {
    dispatch(Actions.home.getUnreadNotifications());
    dispatch(Actions.auth.userTokenVerify());
  }, [dispatch]);

  useEffect(() => {}, [tokenDetails]);

  const handleBellClick = (value) => {
    setIsBellCLick(value ? false : true);
  };

  const signOut = () => {
    dispatch(Actions.auth.signOut());
  };

  return (
    <div className="relative z-50">
      <Disclosure as="nav" className="bg-white sticky">
        {({ open }) => (
          <>
            <div className="mx-auto px-2 sm:px-6 lg:px-8 sticky">
              <div className="relative flex pt-2 pb-2 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex  items-center">
                    <Link to={logoUrl}>
                      <img
                        className="object-contain 2xl:w-40 xl:w-36 lg:w-32 md:w-32 sm: w-36"
                        src={IMAGES.COMMON.NAVBAR_IMG}
                        alt="Your Company"
                      />
                    </Link>
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {getUserType() == USER_ROLE.candidate ? (
                      <>
                        {navigation?.map((item, index) => (
                          <div
                            className="flex items-center justify-center flex-row"
                            key={index + 1}
                          >
                            {item.icon}
                            <Link
                              key={item.name}
                              to={item?.url}
                              className={classNames(
                                item.current
                                  ? "text-lableColor border-b-2 border-lableColor"
                                  : "text-gray-300    hover:text-lableColor  hover:border-b-2 hover:border-lableColor",
                                "px-3 py-2 text-sm font-medium  font-DM"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {navigationRecruiter?.map((item, index) => (
                          <div
                            className="flex items-center justify-center flex-row"
                            key={index + 1}
                          >
                            {item.icon}
                            <Link
                              key={item.name}
                              to={item?.url}
                              className={classNames(
                                item.current
                                  ? "text-lableColor border-b-2 border-lableColor"
                                  : "text-gray-300    hover:text-lableColor  hover:border-b-2 hover:border-lableColor",
                                "px-3 py-2 text-sm font-medium  font-DM"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                <div className=" inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button
                    type="button"
                    className="relative  p-2  rounded-md bg-bgColor  text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    onClick={() => handleBellClick(isBellClick)}
                  >
                    {unreadNotifications.length > 0 && (
                      <span className="absolute -top-2 -right-2 text-white text-xs font-bold w-4 h-4 flex items-center justify-center rounded-full" style={{ backgroundColor: 'red' }}>
                        {unreadNotifications.length}
                      </span>
                    )}
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <NotificationIcon />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex rounded-full  text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 ">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-md"
                          src={IMAGE_URL + _.get(tokenDetails, 'data.user.profile_image')}
                          alt="profile_image"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"

                    >
                      <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={"/profile"}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Your Profile
                            </Link>
                          )}
                        </Menu.Item>
                        {getUserType() != USER_ROLE.recruiter ? <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={"/settings"}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Settings
                            </Link>
                          )}
                        </Menu.Item> : null}

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              onClick={signOut}
                            >
                              Sign out
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>

              <div className=" container mx-auto p-2 bg-white  grid grid-flow-row-dense grid-cols-2  md:grid-cols-4 2xl:hidden xl:hidden lg:hidden justify-center items-center gap-1">
                {/* {SUBMENUS?.DashboardSubMenu?.map((item, index) => {
                  return (
                    <div className="col-span-1" key={index + 1}>
                      <Link to={item?.url}>
                        <ListItem className="hover:bg-bgColor active:bg-bgColor flex items-center gap-x-2 font-DM font-semibold text-darkBlue ">
                          {item?.icon} {item?.name}
                        </ListItem>
                      </Link>
                    </div>
                  );
                })} */}
                {getUserType() == USER_ROLE.recruiter ? (
                  SUBMENUS?.RecruitmobileMenuDashboard?.map((item, index) => {
                    return (
                      <div className="col-span-1" key={index + 1}>
                        <Link to={item?.url}>
                          <ListItem className="hover:bg-bgColor active:bg-bgColor flex items-center gap-x-2 font-DM font-semibold text-darkBlue">
                            {item?.icon} {item?.name}
                          </ListItem>
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  SUBMENUS?.DashboardSubMenu?.map((item, index) => {
                    return (
                      <div className="col-span-1" key={index + 1}>
                        <Link to={item?.url}>
                          <ListItem className="hover:bg-bgColor active:bg-bgColor flex items-center gap-x-2 font-DM font-semibold text-darkBlue">
                            {item?.icon} {item?.name}
                          </ListItem>
                        </Link>
                      </div>
                    );
                  })
                )}



              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {getUserType() == USER_ROLE.candidate ? (
                  <>
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.url}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "block rounded-md px-3 py-2 text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </>
                ) : (
                  <>
                    {navigationRecruiter?.map((item, index) => (<Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.url}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>))}
                  </>)}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {isBellClick ? (
        <div className="absolute top-12 right-20" style={{ zIndex: 1000 }}>
          <NotificationView />
        </div>
      ) : null}
    </div>
  );
};
