import { createAction } from "../../core/AppUtils";
import OnboardingRepository from "../../core/repository/OnboardingRepository";

export const ModuleEvents = {
  LOADING_STARTED: 'LOADING_STARTED',
  LOADING_FINISHED: 'LOADING_FINISHED',
  NETWORK_ERROR:'NETWORK_ERROR',
  LOCATION_LIST:'LOCATION_LIST',
  CANDIDATE_SIGNUP_COMPLETE:'CANDIDATE_SIGNUP_COMPLETE',
  STORE_CANDIDATE_DETAILS:'STORE_CANDIDATE_DETAILS'


};

export default {
  networkError: createAction(ModuleEvents.NETWORK_ERROR, error => error),
  loadingStarted: createAction(ModuleEvents.LOADING_STARTED, action => action),
  loadingFinished: createAction(ModuleEvents.LOADING_FINISHED, action => action),

  getAllLocations: createAction(ModuleEvents.LOCATION_LIST, async () => {
    return await OnboardingRepository.getLocationList();
  }),
  candidateSignUpComplete: createAction(ModuleEvents.CANDIDATE_REGISTER, async () => {
    return await OnboardingRepository.registerCandidate();
  }),

  candidateStoreDetails:createAction(ModuleEvents.STORE_CANDIDATE_DETAILS,(params) => params),

};



