import React from "react";
import { IMAGES } from "../../../assets/images";


export const AverageSalaryCard = ({ text = "label", onClose, close = false }) => {
  return (
    <div class=" bg-bgColor rounded-[12px] flex flex-row items-center my-3 justify-between drop-shadow-md overflow-hidden max-w-[800px] p-[15px]">
      <div class="flex flex-row">
        <div class="flex flex-col bg-white p-1 items-center justify-center rounded-lg w-[150px]">
          <span class="text-primaryColor font-DM font-[700] w-28 text-center">$75,000/ y</span>
        </div>
        <div class="flex flex-col p-4 rounded-lg">
          <span class="text-lableColor font-DM font-[400] text-[14px]">Average publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form.</span>
        </div>
      </div>
    </div>

  );
};
