import React from "react";
import { ButtonComponent } from "../../atoms";
import { Button } from "@material-tailwind/react";

export const SubHeading = ({ title, buttonText, isClear,onClick,onClearClick }) => {
  return (
    <div className="subheading-container">
      <div>
        <p className="sub-heading">{title}</p>
      </div>
      <div>
        {
          isClear ? <Button onClick={onClearClick} className="bg-transparent shadow-none flex flex-row items-center justify-between">
            <span className="font-DM text-lableColor font-normal capitalize text-[16px] mr-4">Clear result</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#2B3674"
              className="w-5 h-5 cursor-pointer font-extrabold"
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
          </Button> :
            <ButtonComponent onClick={onClick} isPrimary={false} text={buttonText} />
        }
      </div>
    </div>
  );
};
