export const ProfileIcon = ({isSelected = false}) => {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_291_1144)">
        <path d="M7.39317 7.64937C8.38583 7.64937 9.2454 7.29335 9.94772 6.59091C10.65 5.88859 11.0061 5.02926 11.0061 4.03648C11.0061 3.04406 10.65 2.18461 9.94761 1.48206C9.24517 0.779852 8.38572 0.423828 7.39317 0.423828C6.4004 0.423828 5.54106 0.779852 4.83874 1.48217C4.13641 2.18449 3.78027 3.04394 3.78027 4.03648C3.78027 5.02926 4.13641 5.8887 4.83885 6.59102C5.54129 7.29323 6.40074 7.64937 7.39317 7.64937Z" fill="#2B3674"/>
        <path d="M13.7147 11.9576C13.6945 11.6653 13.6535 11.3465 13.5932 11.0098C13.5323 10.6706 13.4539 10.3499 13.3601 10.0569C13.2631 9.75394 13.1313 9.45479 12.9683 9.16812C12.7992 8.87057 12.6005 8.61148 12.3776 8.39827C12.1445 8.17523 11.8591 7.9959 11.529 7.8651C11.2001 7.73498 10.8356 7.66906 10.4457 7.66906C10.2926 7.66906 10.1445 7.73189 9.85851 7.91808C9.6825 8.03287 9.47662 8.16562 9.24683 8.31244C9.05033 8.43764 8.78414 8.55494 8.45535 8.66114C8.13457 8.76494 7.80888 8.81758 7.48741 8.81758C7.16595 8.81758 6.84036 8.76494 6.51924 8.66114C6.1908 8.55506 5.92461 8.43776 5.72834 8.31256C5.50072 8.1671 5.29472 8.03435 5.11608 7.91797C4.83044 7.73177 4.68224 7.66895 4.52911 7.66895C4.1391 7.66895 3.77472 7.73498 3.44593 7.86521C3.11611 7.99579 2.83058 8.17512 2.59724 8.39839C2.37442 8.61171 2.17564 8.87068 2.00672 9.16812C1.84387 9.45479 1.71204 9.75382 1.61499 10.057C1.52126 10.3501 1.44287 10.6706 1.38199 11.0098C1.32168 11.346 1.28071 11.665 1.26045 11.9579C1.24054 12.245 1.23047 12.5429 1.23047 12.8437C1.23047 13.6267 1.47938 14.2606 1.97021 14.7281C2.45499 15.1894 3.09643 15.4234 3.87646 15.4234H11.0991C11.8791 15.4234 12.5203 15.1895 13.0052 14.7281C13.4961 14.2609 13.745 13.6269 13.745 12.8436C13.7449 12.5414 13.7347 12.2432 13.7147 11.9576Z" fill="#2B3674"/>
        </g>
        <defs>
        <clipPath id="clip0_291_1144">
        <rect width="15" height="15" fill="white" transform="translate(0 0.423828)"/>
        </clipPath>
        </defs>
        </svg>

    )
}