import React from "react";
import { ButtonComponent, FavouriteButton } from "../../atoms";
import { IMAGES } from "../../../assets";
import _ from "lodash";
import { IMAGE_URL } from "../../../core/Constant";
import { useNavigate } from "react-router-dom";

export const CareerAdviceCard = ({
  post = [],
  text = "label",
  onClose,
  close = false,
}) => {

  const navigate = useNavigate()

  function stripHTML(myString) {
    let el = document.createElement("div");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
 }

  const truncate = (input) =>
    input?.length > 100 ? `${input.substring(0, 70)}...` : input;

  return (
    <div class="w-full bg-white rounded-xl ">
      <div class="flex flex-col items-center p-3">
        <div
          className="w-full bg-no-repeat bg-cover h-36 rounded-md"
          style={{backgroundImage:`url(${IMAGE_URL + _.get(post,'main_image_path')})`}}
        >
          {/* <div class="w-full flex justify-end p-3">
            <FavouriteButton favourite={false} />
          </div> */}
        </div>
        <div class="w-full">
          <div class={"flex flex-row w-full  bg-white justify-between"}>
            <div class={"flex flex-col flex-auto "}>
              <span class="text-lableColor font-DM font-semibold text-lg">
              {_.get(post,'title')}
              </span>
              <span class="text-textlightColor font-DM font-medium text-xs mt-1">
              {truncate(stripHTML(_.get(post,'content')))}
              </span>
            </div>
          </div>
          <div class="flex items-center flex-row w-full mt-2 justify-end ">
            <span class="text-primaryColor cursor-pointer font-DM font-medium text-justify text-sm"
             onClick={()=>navigate(`/career_post/${_.get(post,'id')}/${_.get(post,'title').replace(/ /g, '-')}`)}
            >
              Explore now
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
