import React, { useEffect, useState } from "react";
import {
  EditProfileForm,
  ProfileDetailCard,
  ResumeCard,
  SalaryChart,
} from "../../../components";
import {
  ButtonComponent,
  DropdownComponentCandidate,
  TabButtonComponent,
} from "../../../components/atoms";
import {
  AverageSalaryCard,
  InterviewCandidateCard,
  InterviewCard,
  PdfUploadCard,
} from "../../../components/molecule/Cards";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import _, { set } from "lodash";

export default ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [skillList, setSkillList] = useState([]);
  const [jobRoleList, setJobRoleList] = useState([]);
  const interviews = useSelector((state) =>
    state.home.get("candidateInterviews")
  );

  const skills = useSelector((state) => state.home.get("SkillList"));

  const jobRoles = useSelector((state) => state.common.get("jobRoleList"));

  useEffect(() => {
    dispatch(Actions.home.getInterviewsCandidate());
    dispatch(Actions.home.getSkills());
    dispatch(Actions.common.getjobRoleList());
  }, [dispatch]);

  useEffect(() => {
    let sortedSkills = _.map(_.get(skills,'list'), (skill) => {
      return {
        option: _.get(skill, "name"),
        value: _.get(skill, "id")
      }
    });
    setSkillList(sortedSkills);

    let sortedJobRoles = _.map(jobRoles, (jobRole) => {
      return {
        option: _.get(jobRole, "name"),
        value: _.get(jobRole, "id")
      }
    });
    setJobRoleList(sortedJobRoles);
    console.log('roles', jobRoles, ';;;;; sorted' , sortedJobRoles)
  }, [interviews, jobRoles, skills]);

  useEffect(()=>{},[skillList, jobRoleList])

  return (
    <div className="subscreen-container">
      <div className="grid grid-flow-row-dense grid-cols-2 sm:grid-cols-2 gap-2">
        <div className="col-span-1">
          <span class="text-lableColor font-DM text-3xl font-[500]">
            Interviews
          </span>
        </div>

        <div className="col-span-1 flex sm:justify-end">
          <div className="w-full sm:w-1/3">
            <ButtonComponent
              text="View calender"
              onClick={() => navigate("/calender")}
              isPrimary={false}
            />
          </div>
        </div>
      </div>
      {/* <div className="grid grid-flow-row-dense grid-cols-2">
        <div className="col-span-4 2xl:col-span-3 xl:col-span-1 lg:col-span-2 md:col-span-2 grid grid-flow-row-dense grid-cols-2 gap-2 ">
          <div className="col-span-2 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1">
            <DropdownComponentCandidate defaultOption={'Select a skill'} dropdownOptions={skillList}/>
          </div>
          <div className="col-span-2 2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1">
            <DropdownComponentCandidate defaultOption="Select a job role" dropdownOptions={jobRoleList}/>
          </div>
        </div>
      </div> */}
      <div className="grid grid-flow-row-dense grid-cols-1 ">
      {interviews && interviews.length > 0 ? (
        _.map(interviews, (interview, index) => {
          return (
            <div className="col-auto" key={index + 1}>
              <InterviewCandidateCard interview={interview} isrecruiter={false} />
            </div>
          );
        })
      ) : (
          <div className="flex justify-center items-center max-h-svh min-h-[150px]">
           <span className="font-DM text-3xl text-gray-400 text-center">
           No interviews available</span>
         </div>
      )}
      </div>
    </div>
  );
};
