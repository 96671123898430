export const InterviewIcon = ({ isSelected = false }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6822 4.50082H6.84471C6.38514 4.50082 6.01221 4.12788 6.01221 3.66832V2.39988C6.01221 1.94032 6.38514 1.56738 6.84471 1.56738H11.6822C12.1418 1.56738 12.5147 1.94032 12.5147 2.39988V3.66832C12.5147 4.12845 12.1418 4.50082 11.6822 4.50082Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M17.2762 10.562C16.7587 10.0445 15.915 10.0445 15.3975 10.562L14.7832 11.1796L16.6631 13.0589L17.2762 12.4458C17.7993 11.9232 17.7993 11.0851 17.2762 10.562Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M14.1879 11.7769L10.9085 15.0506C10.841 15.1237 10.796 15.2137 10.7791 15.3094L10.5316 16.7662C10.4754 17.0869 10.7566 17.3625 11.0773 17.3119L12.5341 17.0644C12.6298 17.0475 12.7198 17.0025 12.7873 16.935L16.0666 13.6556L14.1879 11.7769Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
      <path
        d="M14.0587 3.03564H13.3584V3.66846C13.3584 4.59264 12.6064 5.34471 11.6822 5.34471H6.84467C5.91992 5.34471 5.16842 4.59264 5.16842 3.66846V3.03564H4.45686C3.34873 3.03564 2.44873 3.93564 2.44873 5.04377V15.3094C2.44873 16.4175 3.34873 17.3175 4.45686 17.3175H9.77642C9.69092 17.0976 9.65886 16.8591 9.70048 16.6211L9.94742 15.1682C9.99129 14.9106 10.1094 14.6726 10.2872 14.4786L13.8675 10.9033L14.7984 9.96733C15.1444 9.62083 15.5882 9.41552 16.0657 9.35646V5.04939C16.0669 3.93002 15.1725 3.03564 14.0587 3.03564ZM8.38761 11.3792H5.94692C5.66736 11.3792 5.44067 11.1525 5.44067 10.873C5.44067 10.5934 5.66736 10.3667 5.94692 10.3667H8.38761C8.66717 10.3667 8.89386 10.5934 8.89386 10.873C8.89386 11.1525 8.66717 11.3792 8.38761 11.3792ZM11.3756 8.50596H5.94748C5.66792 8.50596 5.44123 8.27927 5.44123 7.99971C5.44123 7.72014 5.66792 7.49346 5.94748 7.49346H11.3756C11.6552 7.49346 11.8819 7.72014 11.8819 7.99971C11.8819 8.27927 11.6552 8.50596 11.3756 8.50596Z"
        fill={isSelected ? '#4283E5':'#2B3674'}
      />
    </svg>
  );
};
