import React from "react";

export const SkillCardlet = ({
    text = "",
    isPrimary = false,
    isDisabled = false,
    // isIcon=false,
    onClick = () => { },
    video = false
}) => {
    return (
        <div class="flex mr-1 mb-2 py-3 px-6 flex-row align-middle border rounded-full bg-white border-ash w-auto h-[34px] justify-center items-center">
            <span class="text-lableColor font-DM font-[400] text-[16px] text-center">{text}</span>
        </div>

    );
};




