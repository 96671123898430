import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ButtonComponent,
  OutlineButtonComponent,
  SkillCardlet,
  TextAreaComponent,
  TextFieldComponent,
} from "../../atoms";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { IMAGES } from "../../../assets";
import _ from "lodash";
import { dateFromNow, getText } from "../../../core/Constant";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import Swal from "sweetalert2";

export const NotesModal = ({ open, onClose, noteDetails }) => {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    console.log(noteDetails);
  }, [noteDetails]);

  const [title, setTitle] = useState(_.get(noteDetails, "title"));
  const [titleError, setTitleError] = useState(false);
  const [titleErrorMsg, setTitleErrorMsg] = useState("");

  const [description, setDescription] = useState(
    _.get(noteDetails, "description")
  );
  const [descriptionError, setdescriptionError] = useState(false);
  const [descriptionErrorMsg, setdescriptionErrorMsg] = useState("");

  const onChangeTitle = (e) => {
    const text = getText(e);
    setTitle(text);
    setTitleError(title.length <= 0);
    setTitleErrorMsg("Please enter title");
  };

  const onChangeDescription = (e) => {
    const text = getText(e);
    setDescription(text);
    setdescriptionError(description.length <= 0);
    setdescriptionErrorMsg("Please enter a description");
  };

  const handleSave = () => {
    if (!titleError && !descriptionError) {
      dispatch(
        Actions.home.updateNote(_.get(noteDetails, "id"), {
          title: title,
          description: description,
        })
      );
      onClose();
    } else {
      if (titleError) {
        setTitleError(true);
      }
      if (descriptionError) {
        setdescriptionError(true);
      }
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      console.log("result", result);

      if (result.isConfirmed && result.value) {
        dispatch(Actions.home.removeNote(_.get(noteDetails, "id")));
        onClose();
      } else {
        onClose();
      }
    });
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg  bg-black  text-left shadow-xl transition-all my-8 w-[375px]">
                <div className="bg-white p-4">
                  <div className="innter-container ">
                    <TextFieldComponent
                      placeholder="Title"
                      value={title}
                      onChange={(e) => onChangeTitle(e)}
                      error={titleError}
                      errortxt={titleErrorMsg}
                    />
                    <TextAreaComponent
                      placeholder="Add note here."
                      rows={5}
                      value={description}
                      onChange={(e) => onChangeDescription(e)}
                      error={descriptionError}
                      errortxt={descriptionErrorMsg}
                    />
                  </div>

                  <div className="button-section flex flex-col gap-y-2">
                    <span className="font-DM font-normal text-md text-[#99A2BF] ">
                      Updated {dateFromNow(_.get(noteDetails, "updated_at"))}
                    </span>
                    <div className="grid grid-cols-3 gap-x-2">
                      <div className="">
                        <button
                          className="rounded-lg border-2 p-2 border-primaryColor"
                          onClick={handleDelete}
                        >
                          <img src={IMAGES.COMMON.DELETE} />
                        </button>
                      </div>
                      <div className="">
                        <OutlineButtonComponent
                          isPrimary={true}
                          text="Close"
                          onClick={onClose}
                        />
                      </div>
                      <div className="">
                        <ButtonComponent
                          text="Save"
                          isPrimary={true}
                          onClick={handleSave}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
