import { useEffect, useState } from "react";
import { Card } from "@material-tailwind/react";
import {
  ButtonComponent,
  DropdownComponent,
  DropdownComponentCandidate,
  TextAreaComponent,
  TextFieldComponent,
} from "../../atoms";
import { getText } from "../../../core/Constant";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import Swal from "sweetalert2";

export const EditProfileForm = ({ details = {} }) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [firstNameIsValid, setFirstNameIsValid] = useState(true);
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState('');

  const [lastName, setLastName] = useState("");
  const [lastNameIsValid, setLastNameIsValid] = useState(true);
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState('');
  

  const [displayName, setDisplayName] = useState();
  const [displayNameIsValid, setDisplayNameValid] = useState(true);
  const [displayNameError, setDisplayNameError] = useState(false);
  const [displayNameErrorMsg, setDisplayNameErrorMsg] = useState("");

  const [status, setStatus] = useState(false);
  const [statusOption, setStatusOption] = useState("Select status");
  const [statusIsValid, setSatusValid] = useState(true);
  const [statusError, setSatusError] = useState(false);
  const [statusErrorMsg, setSatusErrorMsg] = useState("");

  const [position, setPosition] = useState();
  const [positionIsValid, setPositionIsValid] = useState(true);
  const [positionError, setPositionError] = useState(false);
  const [positionErrorMsg, setPositionErrorMsg] = useState("");

  const [headline, setHeadline] = useState();
  const [headlineIsValid, setHeadlineIsvalid] = useState(true);
  const [headlineEror, setHeadlineError] = useState(false);
  const [headlineErorMsg, setHeadlineErrorMsg] = useState("");

  const [link, setLink] = useState();
  const [linkIsValid, setLinkIsvalid] = useState(true);
  const [linkError, setLinkError] = useState(false);
  const [linkErrorMsg, setLinkErrorMsg] = useState("");

  const [message, setMessage] = useState();
  const [messageIsvalid, setMessageIsvalid] = useState(true);
  const [messageError, setMessageError] = useState(false);
  const [messageErrorMsg, setMessageErrorMsg] = useState("");
  const statusArr = [
    { option: "Active", value: true },
    { option: "Inactive", value: false },
  ];
  useEffect(() => {
  }, [dispatch]);

  useEffect(() => {
    setFirstName(`${_.get(details, "first_name")}`);
    setLastName(`${_.get(details, "last_name")}`);
    setDisplayName(_.get(details, "display_name"));
    setHeadline(_.get(details, "headline"));
    setLink(_.get(details, "linkedin"));
    setMessage(_.get(details, "bio"));
    setPosition(_.get(details, "position"));
    setStatus(_.get(details, "status"));
    setStatusOption(_.get(details, "status") ? "Active" : "Inactive");
  }, [details]);

  const onChangeFirstName = (e) => {
    const text = getText(e);
    let isValid = true;
    // const reg = EMAIL_REGEX;
    // if (text.trim().length > 3) {
    //     isValid = reg.test(text.trim());
    // }
    setFirstName(text);
    setFirstNameIsValid(isValid);
    setFirstNameError(false);
  };

  const onChangeLastName = (e) => {
    const text = getText(e);
    let isValid = true;
    // const reg = EMAIL_REGEX;
    // if (text.trim().length > 3) {
    //     isValid = reg.test(text.trim());
    // }
    setLastName(text);
    setLastNameIsValid(isValid);
    setLastNameError(false);
  };

  const onChangeDisplayName = (e) => {
    const text = getText(e);
    let isValid = true;
    // const reg = EMAIL_REGEX;
    // if (text.trim().length > 3) {
    //     isValid = reg.test(text.trim());
    // }
    setDisplayName(text);
    setDisplayNameValid(isValid);
    setDisplayNameError(false);
  };

  const onChangeMessage = (e) => {
    const text = getText(e);
    let isValid = true;
    // const reg = EMAIL_REGEX;
    // if (text.trim().length > 3) {
    //     isValid = reg.test(text.trim());
    // }
    setMessage(text);
    setMessageIsvalid(isValid);
    setMessageError(false);
  };

  const onChangePosition = (e) => {
    const text = getText(e);
    let isValid = true;
    // const reg = EMAIL_REGEX;
    // if (text.trim().length > 3) {
    //     isValid = reg.test(text.trim());
    // }
    setPosition(text);
    setPositionIsValid(isValid);
    setPositionError(false);
  };

  // const onChangeStatus = (value) => {
  //   console.log("kkkk", value);
  //   let currentStatus = _.get(value,'value');
  //   console.log('current status', currentStatus)
  //   // const text = getText(e);
  //   // let isValid = true;
   
  //   // setStatus(_.get(value, "id"));
  //   // setStatusOption(_.get(value, "name"));
  //   // setSatusValid(isValid);
  // };

  const onChangeHeadline = (e) => {
    const text = getText(e);
    let isValid = true;
    setHeadline(text);
    setHeadlineIsvalid(isValid);
    setHeadlineError(false);
  };

  const onChangeLink = (e) => {
    const text = getText(e);
    let isValid = false;
    // const reg = EMAIL_REGEX;
    // if (text.trim().length > 3) {
    //     isValid = reg.test(text.trim());
    // }
    setLink(text);
    setLinkIsvalid(isValid);
    setLinkError(false);
  };

  const onCheckEdit = () => {
    if (
      firstName &&
      lastName &&
      displayNameIsValid &&
      positionIsValid &&
      headlineIsValid &&
      messageIsvalid
    ) {
      dispatch(
        Actions.profile.updateCandidate({
          first_name: firstName,
          last_name: lastName,
          display_name: displayName,
          position: position,
          // availability: status,
          headline: headline,
          job_type: _.get(details, "candidateDetails.job_type"),
          bio: message,
        })
      );
    } else {
      Swal.fire({
        toast: true,
        text: "Please fill the empty Fields.",
        icon: "error",
      });
    }
  };
  return (
    <Card className="static bg-white py-4 px-8 " shadow={false}>
      <div className="w-full my-8  h-auto flex flex-col">
        <div className="two-col-inputs">
          <TextFieldComponent
            placeholder="Type here"
            error={firstNameError}
            errortxt={firstNameErrorMsg}
            label="First Name"
            onChange={(e) => onChangeFirstName(e)}
            value={firstName}
          />

          <TextFieldComponent
            placeholder="Type here"
            error={lastNameError}
            errortxt={lastNameErrorMsg}
            label="Last Name"
            onChange={(e) => onChangeLastName(e)}
            value={lastName}
          />
          <TextFieldComponent
            placeholder="Type here"
            isdisabled={false}
            error={displayName}
            errortxt={displayNameErrorMsg}
            label="Display Name"
            onChange={(e) => onChangeDisplayName(e)}
            value={displayName}
          />

          <TextFieldComponent
            placeholder="Type here"
            isdisabled={false}
            error={positionError}
            errortxt={positionErrorMsg}
            label="Postition"
            onChange={(e) => onChangePosition(e)}
            value={position}
          />

          {/* <DropdownComponentCandidate
            placeholder="Type here"
            isdisabled={false}
            selectedValue={status}
            defaultOption={statusOption}
            // error={messageError}
            // errortxt={messageErrorMsg}
            label="Status"
            dropdownOptions={statusArr}
            getSelectedOption={onChangeStatus}
          /> */}
        </div>
        <TextFieldComponent
          error={headlineEror}
          errortxt={headlineErorMsg}
          placeholder="Type here"
          isdisabled={false}
          label="Headline"
          onChange={(e) => onChangeHeadline(e)}
          value={headline}
        />
        {/* <TextFieldComponent
            error={linkError}
            errortxt={linkErrorMsg}
            placeholder="Type here"
            isdisabled={false}
            label="Link"
            onChange={(e) => onChangeLink(e)}
            value={link}
          /> */}
        <TextAreaComponent
          label="Message"
          error={messageError}
          errortxt={messageErrorMsg}
          placeholder="Type here"
          draggble={false}
          isdisabled={false}
          onChange={(e) => onChangeMessage(e)}
          value={message}
        />
      </div>

      <div className="w-1/3">
        <ButtonComponent text={"Edit Profile"} onClick={onCheckEdit} />
      </div>
    </Card>
  );
};
