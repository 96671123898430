import React, { useEffect, useRef, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { useDispatch, useSelector } from "react-redux";
import {
  MicIcon,
  RedDot,
  SquareIcon,
  StopIcon,
  VideoIcon,
} from "../../../assets/svg";
import { ButtonComponent, ProgressBar } from "../../../components/atoms";
import { Actions } from "../../../core/modules/Actions";
import { ResetButton } from "../../../assets/svg/ResetButton";
import Swal from "sweetalert2";
import { VideoModal } from "../../../components/molecule/Modals";
import _ from "lodash";

export default ({}) => {
  const [stream, setStream] = useState(null);
  const [timer, setTimer] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const videoRef = useRef(null);
  const timerRef = useRef(null);
  const [isMicClick, setIsMicClick] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const dispatch = useDispatch();
  const [isCamOn, setIsCamOn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const details = useSelector((state) => state.profile.get("candidateDetails"));

  useEffect(() => {
      dispatch(Actions.profile.getCandiateDetails());
  }, [dispatch]);

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    unMuteAudio,
    muteAudio,
  } = useReactMediaRecorder({ video: true, type: "video/mp4" });

  const getMediaStream = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (err) {
      console.error("Error accessing media devices:", err);
    }
  };

  const stopMediaStream = async () => {
    if (isCamOn) {
      try {
        const mediaStream = await navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((mediaStream) => {
            const stream = mediaStream;
            const tracks = stream.getTracks();
            tracks[0].stop();
          });
        setIsCamOn(false);
        setStream(mediaStream);
      } catch (err) {
        console.error("Error accessing media devices:", err);
      }
    } else {
      getMediaStream();
      setIsCamOn(true);
    }
  };

  useEffect(() => {
    if (isRecording) {
      // Start the timer
      timerRef.current = setInterval(() => {
        setTimer((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      // Stop the timer
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isRecording]);

  
  useEffect(() => {
    if (mediaBlobUrl && videoRef.current) {
      videoRef.current.pause();

      videoRef.current.srcObject = null;

      videoRef.current.src = mediaBlobUrl;

      videoRef.current.onloadeddata = () => {
        videoRef.current
          .play()
          .catch((err) => console.error("Playback error:", err));
      };
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);
  useEffect(() => {}, [dispatch]);

  const handleStartRecording = () => {
    startRecording();
    setIsRecording(true);
    setTimer(0);
    getMediaStream();
  };

  const handleStopRecording = () => {
    stopRecording();
    setIsRecording(false);
  };

  const handleVideobtn = () => {
    getMediaStream();
  };

  // const handleMicControl = () => {
  //   if (!isMicClick) {
  //     muteAudio();
  //     setIsMicClick(true);
  //   } else {
  //     unMuteAudio();
  //     setIsMicClick(false);
  //   }
  // };

  const onUploadVideo = async () => {
    const response = await fetch(mediaBlobUrl);
    const blob = await response.blob();
    
    if (mediaBlobUrl) {
      setUploadProgress(0);
      const data = new FormData();
      data.append("video_url", blob, "recorded-video.mp4");
      dispatch(
        Actions.profile.uploadVideo(data, "", (ProgressEvent) => {
          const { loaded, total } = ProgressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setUploadProgress(percent);
        })
      );
    }else{
      setUploadProgress(-1);
      Swal.fire({
        toast:true,
        text:'Please record a video before uploading ',
        icon: 'info',
      });
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
      setIsModalOpen(false);
  };

  return (
    <div className="subscreen-container">
      <div className="flex items-center flex-col justify-center">
      <div className="relative w-full flex flex-col items-center lg:flex-row lg:justify-between lg:items-start">
        <div className="text-center lg:text-left">
          <span className="text-lableColor font-DM text-[28px] lg:text-[36px] font-[700]">
          Record your 60s video
          </span>
          <br />
          <span className="text-ash font-DM text-[14px] lg:text-[16px] font-[400]">
            Introduce yourself briefly.
          </span>
        </div>
        <div className="mt-4 lg:mt-0 lg:ml-auto">
          <ButtonComponent width="w-auto" padding="p-2" onClick={handleOpenModal} text="Check your video" isPrimary={true} />
        </div>
      </div>
        <div className="max-w-[650px] mt-2  w-full">
          <div className="relative">
            <div className="absolute top-0 left-0 p-2 ">
              {isRecording ? (
                <div className="flex justify-center items-center gap-x-1 p-1.5 ps-2 pe-2 bg-[#0d0d0d77] rounded-full w-20">
                  <RedDot />
                  <span className="font-DM font-medium text-white">
                    {Math.floor(timer / 60)}:{("0" + (timer % 60)).slice(-2)}
                  </span>
                </div>
              ) : null}
            </div>
            <video
              ref={videoRef}
              controls
              autoPlay
              loop
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
              }}
            />
          </div>

          <div class="grid w-full mt-6 items-center grid-cols-5 gap-2 ">
            {/* <div class="col-span-1">
              <VideoControllerButton icon={<SettingIcon />} />
            </div> */}
            <div className="col-span-3 flex justify-center gap-2">
              {/* <button onClick={handleMicControl}>
                <MicIcon />
              </button> */}

              <button
                onClick={stopMediaStream}
                className="flex justify-center bg-[#EAF3F6] rounded-md items-center gap-x-0.5 p-1.5 ps-2 pe-2"
              >
                <VideoIcon isSelected={isCamOn} />
              </button>

              <button
                className="flex justify-center bg-[#EAF3F6] rounded-md items-center gap-x-0.5 p-1.5 ps-2 pe-2"
                onClick={handleVideobtn}
              >
                <ResetButton />
              </button>

              {!isRecording ? (
                <button onClick={handleStartRecording}>
                  <div className="flex justify-center bg-[#EAF3F6] rounded-md items-center gap-x-0.5 p-1.5 ps-2 pe-2">
                    <StopIcon />
                    <span className="font-DM font-medium text-darkBlue">
                      REC
                    </span>
                  </div>
                </button>
              ) : (
                <button onClick={handleStopRecording}>
                  <div className="flex justify-center bg-[#E56060] rounded-md items-center gap-x-1 p-1.5 ps-2 pe-2">
                    <SquareIcon />
                    <span className="font-DM font-medium text-white">
                      {Math.floor(timer / 60)}:{("0" + (timer % 60))?.slice(-2)}
                    </span>
                  </div>
                </button>
              )}
            </div>
            <div class="col-span-1">
              <ButtonComponent
                isPrimary={true}
                onClick={onUploadVideo}
                text="Upload Video"
              />
            </div>
          </div>

          {uploadProgress >= 0 ? (
            <ProgressBar progress={uploadProgress} />
          ) : null}
        </div>
            {isModalOpen && (
                <VideoModal
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  videoUrl={_.get(details, "candidateDetails.video_url")}
                />
            )}
      </div>
    </div>
  );
};
