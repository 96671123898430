import React, { Fragment, useEffect, useRef, useState } from "react";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { Dialog, Transition } from "@headlessui/react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { TicketReplyCardlet } from "../../atoms/Cardlet/TicketReplyCardlet";
import { ButtonComponent, TextAreaComponent } from "../../atoms";
import { Actions } from "../../../core/modules/Actions";

export const TicketDetailsModal = ({
  details,
  onClose,
  open = false,
}) => {
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setdescriptionError] = useState(false);
  const [isReply, setIsReply] = useState(false);

  useEffect(() => {
    console.log("details", details);
    setIsReply(_.get(details, "status") == 'OPEN' || _.get(details, "status") == 'REPLIED' ? true : false)
  }, [details]);

  const openReplyArea = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSave = () => {
      dispatch(
        Actions.profile.addTicketReply( {
          ticket_id: _.get(details, "id"),
          reply: description,
        })
      );
      if(description){
        setDescription("");
        setIsExpanded(!isExpanded);
      }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 flex items-center justify-center"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-full overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 sm:p-6">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
      
              <Dialog.Panel className="relative w-full max-w-lg sm:max-w-3xl bg-white rounded-lg shadow-xl transform transition-all">
                
                <div className="flex justify-between p-4 border-b">
                  <h2 className="text-2xl font-semibold">
                    {_.get(details, "title")}
                  </h2>
                  <CloseButton onClick={onClose} />
                </div>

                <div className="p-4 sm:p-6">
                  <p className="text-gray-600">{_.get(details, "description")}</p>
                  <div className="mt-4">
                    <h3 className="text-gray-700 mb-2">Replies:</h3>
                    <div className="space-y-2 max-h-60 overflow-y-auto">
                      {_.get(details, "support_ticket_replies").map((replies, index) => (
                        <TicketReplyCardlet key={index} replyData={replies} />
                      ))}
                    </div>
                    <div className="mt-4 space-y-2">
                    {!isExpanded && isReply &&(
                      <ButtonComponent
                        text="Add Reply"
                        isPrimary={true}
                        fontsize="text-sm"
                        onClick={openReplyArea}
                      />
                    )}
                    {isExpanded && isReply && (<>
                        <TextAreaComponent
                          placeholder="Type reply"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          rows={6}
                          error={descriptionError}
                          errortxt="Please enter a description"
                        />
                        <ButtonComponent
                          text="Send"
                          isPrimary
                          onClick={handleSave}
                        />
                     </>)}
                     </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
