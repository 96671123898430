import React from "react";

export const ButtonComponent = ({
  text = "text",
  isPrimary = true,
  isDisabled = false,
  padding = 'p-2',
  fontsize = 'text-md',
  background = 'bg-primaryColor',
  fontColor = 'text-white',
  width = 'w-full',
  borderColor = "border-primaryColor",
  // isIcon=false,
  icon,
  onClick = () => { },
}) => {
  return (
    <button
      disabled={isDisabled}
      className={
        isDisabled
          ? isPrimary
            ? "btn-disabled-primary"
            : "btn-disabled-secondary"
          : isPrimary
            ? `btn-primary flex flex-row items-center justify-center ${background} ${fontColor} ${width} ${padding} ${borderColor} ${fontsize}`
            : `btn-secondary flex flex-row items-center justify-center  ${width} ${padding}  ${borderColor} ${fontsize}`
      }
      onClick={onClick}
    >
      {text}
      {icon ?
        <div className="ml-4">
          {/* <WondIcon/> */}
          <DownloadIcon />
        </div> : <></>
      }
    </button>
  );
};

const WondIcon = () => {

  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_758_19845)">
        <path d="M8.53893 10.7227L0.56734 18.6942C0.452259 18.8083 0.360843 18.944 0.298342 19.0935C0.23584 19.243 0.203483 19.4034 0.203128 19.5654C0.202773 19.7275 0.234428 19.888 0.296274 20.0378C0.35812 20.1875 0.448941 20.3236 0.563521 20.4382C0.678101 20.5528 0.814185 20.6436 0.963959 20.7054C1.11373 20.7673 1.27425 20.7989 1.43629 20.7986C1.59833 20.7982 1.7587 20.7659 1.90821 20.7034C2.05771 20.6409 2.19339 20.5495 2.30747 20.4344L10.2939 12.4481L9.88789 11.1422L8.53893 10.7227Z" fill="white" />
        <path d="M16.5939 7.53228L17.8622 3.79219C17.8917 3.70522 17.8963 3.61173 17.8754 3.52229C17.8546 3.43286 17.8091 3.35105 17.7441 3.28611C17.6792 3.22117 17.5974 3.17569 17.508 3.15482C17.4185 3.13395 17.325 3.13852 17.2381 3.16802L13.4979 4.4363L10.3328 2.07429C10.2592 2.01937 10.1717 1.98613 10.0802 1.97834C9.98866 1.97055 9.89679 1.98852 9.81496 2.03021C9.73313 2.07191 9.6646 2.13567 9.61712 2.21428C9.56963 2.29289 9.54509 2.38322 9.54627 2.47506L9.59672 6.42404L6.37207 8.70439C6.29708 8.75741 6.23843 8.83037 6.20274 8.91499C6.16705 8.99961 6.15575 9.09254 6.17012 9.18325C6.18449 9.27396 6.22395 9.35884 6.28405 9.42829C6.34414 9.49774 6.42247 9.549 6.51017 9.57626L10.2815 10.7486L11.4539 14.5199C11.4812 14.6076 11.5324 14.6859 11.6019 14.746C11.6713 14.8061 11.7562 14.8455 11.8469 14.8599C11.9376 14.8743 12.0305 14.863 12.1151 14.8273C12.1998 14.7916 12.2727 14.733 12.3257 14.658L14.6062 11.4335L18.5552 11.484H18.5615C18.6528 11.484 18.7423 11.4586 18.82 11.4106C18.8977 11.3627 18.9605 11.2941 19.0014 11.2124C19.0424 11.1308 19.0598 11.0394 19.0517 10.9485C19.0437 10.8575 19.0105 10.7706 18.9559 10.6975L16.5939 7.53228Z" fill="white" />
        <path d="M20.4596 1.44661C19.7664 1.23161 19.6779 0.944909 19.6468 0.669613C19.64 0.609516 19.6113 0.554026 19.5662 0.513734C19.5211 0.473443 19.4628 0.451172 19.4023 0.451172C19.3418 0.451172 19.2835 0.473443 19.2383 0.513734C19.1932 0.554026 19.1646 0.609516 19.1578 0.669613C19.1266 0.944868 19.0383 1.23153 18.345 1.44661C18.2911 1.46335 18.2445 1.49814 18.2132 1.54513C18.1819 1.59213 18.1677 1.64847 18.173 1.7047C18.1782 1.76094 18.2027 1.81364 18.2423 1.85396C18.2818 1.89429 18.334 1.91978 18.3902 1.92617C18.6654 1.95726 18.9521 2.04565 19.1671 2.73889C19.1826 2.78908 19.2138 2.83297 19.2561 2.86414C19.2984 2.89531 19.3496 2.91213 19.4021 2.91213C19.4547 2.91213 19.5058 2.89531 19.5481 2.86414C19.5904 2.83297 19.6216 2.78908 19.6372 2.73889C19.8522 2.04573 20.1389 1.95726 20.4141 1.92617C20.4702 1.91978 20.5224 1.89429 20.562 1.85396C20.6015 1.81364 20.626 1.76094 20.6313 1.7047C20.6366 1.64847 20.6224 1.59213 20.591 1.54513C20.5597 1.49814 20.5132 1.46335 20.4592 1.44661H20.4596Z" fill="white" />
        <path d="M18.1197 14.6126C17.4265 14.3976 17.3381 14.1109 17.307 13.8356C17.3002 13.7755 17.2715 13.72 17.2264 13.6797C17.1813 13.6395 17.1229 13.6172 17.0625 13.6172C17.002 13.6172 16.9436 13.6395 16.8985 13.6797C16.8534 13.72 16.8247 13.7755 16.8179 13.8356C16.7868 14.1109 16.6984 14.3975 16.0052 14.6126C15.9512 14.6294 15.9047 14.6642 15.8734 14.7111C15.842 14.7581 15.8278 14.8145 15.8331 14.8707C15.8384 14.927 15.8629 14.9797 15.9024 15.02C15.942 15.0603 15.9942 15.0858 16.0503 15.0922C16.3256 15.1233 16.6122 15.2117 16.8272 15.9049C16.8428 15.9551 16.874 15.999 16.9163 16.0302C16.9586 16.0613 17.0097 16.0781 17.0623 16.0781C17.1148 16.0781 17.166 16.0613 17.2083 16.0302C17.2506 15.999 17.2818 15.9551 17.2973 15.9049C17.5124 15.2117 17.7991 15.1233 18.0743 15.0922C18.1304 15.0858 18.1826 15.0603 18.2222 15.02C18.2617 14.9797 18.2862 14.927 18.2915 14.8707C18.2968 14.8145 18.2825 14.7581 18.2512 14.7111C18.2199 14.6642 18.1733 14.6294 18.1194 14.6126H18.1197Z" fill="white" />
        <path d="M3.58189 4.83828C3.85714 4.86937 4.1438 4.95775 4.35881 5.651C4.37436 5.70119 4.40557 5.74508 4.44787 5.77625C4.49016 5.80742 4.54132 5.82423 4.59387 5.82423C4.64641 5.82423 4.69757 5.80742 4.73987 5.77625C4.78216 5.74508 4.81337 5.70119 4.82893 5.651C5.04393 4.95784 5.33063 4.86937 5.60584 4.83828C5.66196 4.83189 5.71418 4.8064 5.75373 4.76607C5.79328 4.72575 5.81775 4.67304 5.82304 4.61681C5.82833 4.56058 5.81412 4.50424 5.78278 4.45724C5.75145 4.41025 5.70491 4.37546 5.65096 4.35872C4.9578 4.14372 4.86933 3.85702 4.83824 3.58172C4.83144 3.52163 4.80275 3.46614 4.75765 3.42584C4.71254 3.38555 4.65418 3.36328 4.5937 3.36328C4.53322 3.36328 4.47486 3.38555 4.42976 3.42584C4.38465 3.46614 4.35596 3.52163 4.34917 3.58172C4.31804 3.85698 4.22969 4.14364 3.53644 4.35872C3.4825 4.37546 3.43595 4.41025 3.40462 4.45724C3.37329 4.50424 3.35907 4.56058 3.36436 4.61681C3.36965 4.67304 3.39413 4.72575 3.43368 4.76607C3.47322 4.8064 3.52544 4.83189 3.58156 4.83828H3.58189Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_758_19845">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )


}

const DownloadIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 8L9 13M9 13L14 8M9 13V1" stroke="#4283E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}
