import React, { useState, useRef, useEffect, Fragment } from "react";
import { Menu, Transition } from '@headlessui/react'
import _ from "lodash";

export const DropdownComponentCandidate = ({
  label,
  defaultOption = "Select an option",
  isdisabled,
  error,
  errortxt,
  dropdownOptions = [],
  getSelectedOption = () => { },
}) => {
  const [select, setSelect] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const dropdownRef = useRef(null);

  const onclickdropdown = () => {
    setSelect(!select);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSelect(false);
    }
  };

  const optionSelected = (value) => {
    setSelect(value.value);
    setSelectedOption(value.option);
    getSelectedOption(value);
  };

  useEffect(()=>{
    setSelectedOption(defaultOption);
  },[dropdownOptions])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="input-field-wrap items-start relative" ref={dropdownRef}>
      <label className="input-label-txt">{label}</label>
      <Menu>
        <Menu.Button
          onClick={onclickdropdown}
          className={
            isdisabled
              ? "input-field-disabled"
              : error
              ? "error-input-field"
              : "input-field-basic"
          }
        >
          <div className="flex justify-between opacity-55 font-normal w-full b">
            <p>{selectedOption}</p>
            {select ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        </Menu.Button>
       <div className="absolute w-full bottom-0 ">
       <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="dropdown-option-wrap min-h-20 w-full max-h-56 overflow-y-auto">
            {dropdownOptions?.map((item, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <p
                    className={`pt-2 pb-2 ps-2 pe-2 rounded-lg ${
                      active ? "bg-primaryColor text-white" : ""
                    }`}
                    onClick={() => optionSelected(item)}
                  >
                    {item?.option}
                  </p>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
       </div>
      </Menu>
      {error && !isdisabled ? (
        <p className="input-error-txt">{errortxt}</p>
      ) : null}
    </div>
  );
};
