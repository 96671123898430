import React, { useEffect, useState } from "react";
import { IMAGES } from "../../../assets";
import { dateFromNow, IMAGE_URL } from "../../../core/Constant";
import _ from "lodash";


export const ProfileCard = ({viewData = {}}) => {
  useEffect(()=> {
  },[viewData])
  return (
    <div class="max-w-md bg-white mx-auto rounded-[12px] flex flex-row items-center my-3 justify-between drop-shadow-md

    overflow-hidden w-[362px] p-[15px]">
      <div class="flex flex-row">
        <img className="w-12 h-12 rounded-full border-white border-2 ml-[-10px] object-cover" src={IMAGE_URL + _.get(viewData, 'User.profile_image')} />
        <div class="flex flex-col ml-4">
          <span class="text-lableColor font-DM font-[700] text-16px]">{_.get(viewData,'display_name')}</span>
          <span class="text-textlightColor font-DM font-[500] text-[14px]">{_.get(viewData,'position')}</span>
        </div>
      </div>
      <span class="text-primaryColor font-DM font-[400] text-[9px]">{dateFromNow(_.get(viewData, 'createdAt'))}</span>
    </div>

  );
};
