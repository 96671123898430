import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ButtonComponent,
  SkillCardlet,
  VideoPlayerComponent,
} from "../../atoms";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { IMAGES } from "../../../assets";

export const UploadDetailsModel = ({
  heading = "Account created successfully",
  subText = "",
  onClose,
  open = false,
  buttonText = "OK",
}) => {
  const [isUploading, setIsUploading] = useState(true);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-hidden">
          <div className="flex min-h-full  justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl  bg-white text-left shadow-xl transition-all 2xl:max-w-4xl xl:max-w-4xl lg:max-w-3xl md:max-w-2xl">
                <div className="p-2 grid grid-flow-row-dense grid-cols-6 gap-2">
                  <div className="col-span-full 2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4  w-full h-72">
                    <VideoPlayerComponent videoUrl="https://youtu.be/IUN664s7N-c" />
                  </div>

                  <div className="col-span-full 2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-2 p-1">
                    {isUploading ? (
                      <div className="inner-content-wrap flex flex-col gap-y-6">
                        <div className="close-button flex justify-end">
                          <button onClick={onClose}>
                            <img
                              src={IMAGES.COMMON.CLOSE_MODAL_BUTTON}
                              alt="logo"
                              className=" w-6 object-contain"
                            />
                          </button>
                        </div>
                        <div className="flex flex-col items-center gap-y-2 justify-center h-full">
                          <p className="text-lg text-center font-DM font-bold text-[#2B3674]">
                            Uploading Your Details
                          </p>
                          <p className="text-sm text-center font-DM font-normal text-[#A3AED0]">
                            Just a moment while we build your profile from your
                            CV.
                          </p>
                          <div className="loader-image-wrap">
                            <p className="text-white font-DM font-semibold text-2xl text-center absolute top-[50px] left-9 ">
                              74%
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="inner-content-success-wrap flex flex-col">
                        <div className="close-button flex justify-end">
                          <button onClick={onClose}>
                            <img
                              src={IMAGES.COMMON.CLOSE_MODAL_BUTTON}
                              alt="logo"
                              className=" w-6 object-contain"
                            />
                          </button>
                        </div>
                        <div className="flex flex-col items-center gap-y-2 justify-center h-full">
                          <img
                            src={IMAGES.ONBORADING.SUCCESS_ONBOARDING}
                            alt="success"
                          />
                          <p className="text-lg text-center font-DM font-bold text-[#2B3674]">
                            Account created
                          </p>
                          <p className="text-sm text-center font-DM font-normal text-[#A3AED0]">
                            Just a moment while we build your profile from your
                            CV.
                          </p>
                          <div className="button-wrapper w-2/3">
                            <ButtonComponent isPrimary={true} text="Done" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
