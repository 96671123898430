import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ButtonComponent, OutlineButtonComponent, TextAreaComponent, TextFieldComponent } from "../../atoms";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { AttachmentUploadCard } from "../Cards/AttachmentUploadCard";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { IMAGE_URL, onToastNotify } from "../../../core/Constant";

export const VideoModal = ({ isOpen, onClose, videoUrl }) => {
  const cancelButtonRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
  }, [videoUrl]);

  const handlePlayPause = () => {
    const video = document.getElementById(`screeningVideo`);
    if (isVideoPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsVideoPlaying(!isVideoPlaying);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 flex items-center justify-center" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-40" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Modal Panel */}
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full sm:max-w-lg sm:mx-auto mx-2">
                <div className="bg-white p-4">

                  <div className="flex flex-row w-full justify-between bg-bgColor rounded-lg items-center">
                    <h2 className="text-labelColor font-medium ml-5 text-[28px]">60s video</h2>
                    <CloseButton onClick={onClose} />
                  </div>


                  <div className="mt-4">
                    <div className="h-full col-span-12 lg:col-span-3 md:col-span-3 sm:col-span-12 xl:col-span-3 2xl:col-span-3 items-center flex relative rounded-xl bg-black">
                    {videoUrl != null && videoUrl != "" ? (<>
                      <video
                        id={`screeningVideo`}
                        className="h-full w-full rounded-xl"
                        onClick={handlePlayPause}
                        controls={isVideoPlaying ? "controls" : ""}
                      >
                        <source
                          src={videoUrl == null ? "": IMAGE_URL + videoUrl || ""}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                      {!isVideoPlaying && (
                        <button
                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-3"
                          onClick={handlePlayPause}
                        >
                          <svg
                            width="100"
                            height="100"
                            viewBox="0 0 125 125"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="125" height="125" rx="62.5" fill="#4283E5" />
                            <g clipPath="url(#clip0_758_13992)">
                              <path
                                d="M52.0612 38.1466C47.3731 35.4574 43.5723 37.6604 43.5723 43.0631V82.9331C43.5723 88.3412 47.3731 90.5413 52.0612 87.8547L86.9095 67.8695C91.5991 65.1793 91.5991 60.821 86.9095 58.1315L52.0612 38.1466Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_758_13992">
                                <rect
                                  width="52"
                                  height="52"
                                  fill="white"
                                  transform="translate(41 37)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>)}
                        </>
                    ) : (
                      <div className="h-full w-full flex items-center justify-center rounded-xl bg-white">
                        <p className="text-gray-500">No video available</p>
                      </div>)}
                    </div>
                  </div>


                  <div className="mt-4 flex justify-end space-x-4">
                    <OutlineButtonComponent text="Close" onClick={onClose} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

  );
};
