import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

export const SalaryChart = ({ text = "label", onClose, close = false }) => {

  const Chart = {

    series: [{
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100]
    }, {
      name: 'series2',
      data: [11, 32, 45, 32, 34, 52, 41]
    }],
    options: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: '5px',
      borderColor: '#BCBCBC',
      chart: {
        height: 200,
        type: 'area',
        background: '#EAF3F6',
      },
      grid: {
        show: true,
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        },
        row: {
          colors: undefined,
          opacity: 0.5
        },
        column: {
          colors: undefined,
          opacity: 0.5
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
      },
      yaxis: {
        show: false
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },


  };


  return (
    <div className="bg-liteGray2 p-4 rounded-lg">
      <div className="flex flex-col">
        <span class="text-lableColor font-DM text-[34px] font-[700]">$37.5K</span>
        <div className="flex flex-row">
          <span class="text-ash font-DM text-[12px] font-[700]">Average</span>
          <span class="text-lightGreen font-DM text-[12px] font-[700]">+2.45%</span>
        </div>
      </div>
      <ReactApexChart options={Chart.options} series={Chart.series} type="area" height={350} />
    </div>
  );

}
