import React from "react";
import { ModuleEvents } from "./Actions";
import { onToast } from "../../core/Constant";
import { Actions } from "../../core/modules/Actions";
import _ from "lodash";
import { navigateTo } from "../../core/services/NavigationServicd";


export default {
  [ModuleEvents.NETWORK_ERROR]: ({ dispatch, payload, appState }) => {
    // Logger.infor(JSON.stringify(payload))
  },

  [ModuleEvents.ADD_NOTE]: ({ dispatch, payload, appState }) => {
    dispatch(Actions.home.getAllNotes());
    dispatch(Actions.home.getDashboardDetails());
    onToast("Note Created", payload, false);
  },

  [ModuleEvents.UPDATE_NOTE]: ({ dispatch, payload, appState }) => {
    onToast("Note Updated", payload);
    if (payload && payload.status_code == 1) {
      dispatch(Actions.home.getNote(payload.data.data.id));
      dispatch(Actions.home.getDashboardDetails());
      dispatch(Actions.home.getAllNotes());
    }
  },

  [ModuleEvents.REMOVE_NOTE]: ({ dispatch, payload, appState }) => {
    onToast("Note Deleted", payload);
    if (payload && payload.status_code == 1) {
      dispatch(Actions.home.getDashboardDetails());
      dispatch(Actions.home.getAllNotes());
    }
  },

  [ModuleEvents.CREATE_CHAT]: ({ dispatch, payload, appState }) => {
    onToast("Conversation Creation", payload);
    if (payload && payload.status_code == 1) {
      dispatch(Actions.home.getDashboardDetails());
      dispatch(Actions.home.getAllNotes());
      navigateTo("/recruiter_messagescreen")
    }
  },

  [ModuleEvents.CREATE_MESSAGE_FOR_CHAT]: ({ dispatch, payload, appState }) => {
    // onToast('Conversation Created', payload);
    console.log("payloadnjnjnjn::: ", _.get(payload, "chatId"));
    console.log(payload,'payyyyy' ,payload.data.status_code)
    if (payload && payload.data.status_code == 1) {
      console.log("njnj")
      dispatch(
        Actions.home.getMessagesForAChat({
          conversation_id: _.get(payload, "chatId"),
        })
      );
      dispatch(Actions.home.getChats());
    }
  },

  [ModuleEvents.MARK_ALL_NOTIICATIONS]: ({ dispatch, payload, appState }) => {
    onToast("Notifications", payload);
    if (payload && payload.status_code == 1) {
      dispatch(Actions.home.getALLNotifications());
      dispatch(Actions.home.getUnreadNotifications());
      dispatch(Actions.home.getReadNotifications());
    }
  },

  [ModuleEvents.MARK_NOTIICATION]: ({ dispatch, payload, appState }) => {
    onToast("Notification", payload);
    if (payload && payload.status_code == 1) {
      dispatch(Actions.home.getALLNotifications());
      dispatch(Actions.home.getUnreadNotifications());
      dispatch(Actions.home.getReadNotifications());
    }
  },

  [ModuleEvents.ANSWER_SCREENING_QUESTIONS]: ({
    dispatch,
    payload,
    appState,
  }) => {
    onToast("Screening Questions", payload);
    dispatch(Actions.home.getScreeningQuestionsList());

    // if (payload && payload.status_code == 1) {
    //   dispatch(Actions.home.getALLNotifications());
    //   dispatch(Actions.home.getUnreadNotifications());
    //   dispatch(Actions.home.getReadNotifications());
    // }
  },

  [ModuleEvents.CREATE_JOB_POST]: ({ dispatch, payload, appState }) => {
    if (payload && payload.status_code == 1) {
      dispatch(Actions.home.resetCreateJob());
      navigateTo("/recruiter-managejobs")
    }
    onToast('Job Create', payload);
  },
  [ModuleEvents.ADD_INTERVIEW]: ({ dispatch, payload, appState }) => {
    if (payload.status_code == 0) {
      //onToast('Interview', payload);
    }
    onToast('Interview', payload);
  },
  [ModuleEvents.GET_INTERVIEW_LIST]: ({ dispatch, payload, appState }) => {
    if (payload.status_code == 0) {
      onToast('Interview', payload);
    }
  },
  [ModuleEvents.INITIATE_SCREENING]: ({ dispatch, payload, appState }) => {
    if (payload && payload.status_code == 1) {
      ///dispatch(Actions.home.storeCreateJob({}));
      dispatch(Actions.home.getAppliedCandidates(payload.data.job_id));
    }
    onToast('Initiate Screening', payload);
  },
  [ModuleEvents.MARK_AS_QUALIFIED]: ({ dispatch, payload, appState }) => {
    console.log(payload,'payloaadd')
    if (payload && payload.status_code == 1) {
      dispatch(Actions.home.getAppliedCandidates(payload.data.job.id));
      dispatch(Actions.home.getQualifiedCandidates(payload?.data.job?.id));
      dispatch(Actions.home.getRejectedCandidates(payload?.data.job?.id));
      dispatch(Actions.home.getScreeninginitiateCandidate(payload?.data.job?.id));
    }
    onToast('Qualified', payload);
  },
  [ModuleEvents.MARK_AS_REJECTED]: ({ dispatch, payload, appState }) => {
    console.log(payload,'payloaadd')
    if (payload && payload.status_code == 1) {
      dispatch(Actions.home.getAppliedCandidates(payload?.data.candidate?.jobcandidate?.job_id));
      dispatch(Actions.home.getQualifiedCandidates(payload?.data.candidate?.jobcandidate?.job_id));
      dispatch(Actions.home.getRejectedCandidates(payload?.data.candidate?.jobcandidate?.job_id));
      dispatch(Actions.home.getScreeninginitiateCandidate(payload?.data.candidate?.jobcandidate?.job_id));
    }
    onToast('Application Rejection', payload);
  },

  [ModuleEvents.OFFER_JOB]: ({ dispatch, payload, appState }) => {
    console.log(payload,'payloaadd')
    if (payload && payload.status_code == 1) {
      dispatch(Actions.home.getAppliedCandidates(payload?.data.job?.id));
      dispatch(Actions.home.getQualifiedCandidates(payload?.data.job?.id));
      dispatch(Actions.home.getRejectedCandidates(payload?.data.job?.id));
    }
    onToast('Job offer made', payload);
  },

  [ModuleEvents.CREATE_MCQ_QUESTIONS]: ({ dispatch, payload, appState }) => {
    console.log(payload,'payloaadd')
    if (payload && payload.status_code == 1) {
     // dispatch(Actions.home.getAppliedCandidates(payload?.data.job?.id));
    }
    onToast('Questions', payload);
  },
  [ModuleEvents.CREATE_VIDEO_QUESTIONS]: ({ dispatch, payload, appState }) => {
    console.log(payload,'payloaadd')
    if (payload && payload.status_code == 1) {
     // dispatch(Actions.home.getAppliedCandidates(payload?.data.job?.id));
    }
    onToast('Questions', payload);
  },
  [ModuleEvents.UPDATE_QUESTIONS]: ({ dispatch, payload, appState }) => {
    console.log(payload,'payloaadd')
    if (payload && payload.status_code == 1) {
     // dispatch(Actions.home.getAppliedCandidates(payload?.data.job?.id));
    }
    onToast('Questions', payload);
  },
  [ModuleEvents.DELETE_SCREENING_QUESTIONS]: ({ dispatch, payload, appState }) => {
    console.log(payload,'payloaadd')
    if (payload && payload.status_code == 1) {
     // dispatch(Actions.home.getAppliedCandidates(payload?.data.job?.id));
    }
    onToast('Delete', payload);
  },
  [ModuleEvents.CREATE_MESSAGE]: ({ dispatch, payload, appState }) => {
    console.log(payload,'payloaadd')
    if (payload && payload.status_code == 1) {
       dispatch(Actions.home.getMessages({conversation_id: payload?.data.message?.conversation_id}));
       dispatch(Actions.home.getConversationList());
    }
    onToast('', payload);
  },
  [ModuleEvents.MARK_AS_HIRED]: ({ dispatch, payload, appState }) => {
    if (payload && payload.status_code == 1) {
       dispatch(Actions.home.getOfferAcceptededCandidates(payload?.data.job?.id));
       dispatch(Actions.home.getOfferedCandidates(payload?.data.job?.id));
    }
    onToast('Hired', payload);
  },
};
