import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../../core/modules/Actions';
import { ResumeCard } from '../../../components';

export const ViewResumeScreen = () => {
    const dispatch = useDispatch();
    const resumesList = useSelector((state) =>
      state.profile.get("resumeTemplates")
    );
  
  
    useEffect(() => {
      dispatch(Actions.profile.getResumeTemplates());
    }, [dispatch]);
  
    useEffect(() => {
    }, [resumesList]);
  return (
    <div className="subscreen-container">
    <div className="flex flex-col ">
      <span class="text-lableColor font-DM text-3xl font-bold">
        All Resume Templates
      </span>
    </div>

    <div className="job-listing-container">
      {resumesList?.map((item, index) => {
        return (
          <div className="col-span-1" key={index + 1}>
            <ResumeCard details={item} />
          </div>
        );
      })}
    </div>
  </div>
  )
}
