import Repository from "./Repository";
import _ from "lodash";

const getPath = (path) => {
  return `coversation/${path}`;
};
class MessageRepository extends Repository {
  createChat = async (params) => {
    try {
      const data = await this.postData(getPath(`createconversation`), params);
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  creatMessage = async (params, chatId) => {
    try {
      const data = await this.postData(getPath(`createmessage`), params);
      return { data: _.get(data, `data`), chatId: chatId };
    } catch (error) {
      return { error };
    }
  };

  getChatList = async () => {
    try {
      const data = await this.getData(getPath(`getconversationsforuser`));
      return _.get(data, `data`);
    } catch (error) {
      return { error };
    }
  };

  getMessagesForChat = async (params, chatId) => {
    try {
      const data = await this.postData(
        getPath("getmessagesforconversation"),
        params
      );
      return _.get(data, `data`)
    } catch (error) {
      return { error };
    }
  };
}
export default new MessageRepository("Message");
