import React from 'react'

export const CloudIcon = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.8655 10.668C21.4959 6.81776 18.2903 3.79688 14.3438 3.79688C11.2901 3.79688 8.67516 5.60933 7.47013 8.20997C7.10728 8.09434 6.72923 8.0159 6.32844 8.0159C4.23158 8.0159 2.53136 9.71696 2.53136 11.813C2.53136 12.2298 2.61489 12.6239 2.73893 12.9977C1.1079 13.9487 0 15.6945 0 17.718C0 20.7472 2.45546 23.2027 5.48475 23.2027V23.2044H20.6723V23.2027C24.169 23.2027 27 20.3701 27 16.8742C27 13.7892 24.7892 11.2266 21.8655 10.668ZM16.6896 15.5258H15.1876V18.9111C15.1876 19.3777 14.8112 19.7566 14.3438 19.7566H12.6562C12.1905 19.7566 11.8124 19.3777 11.8124 18.9111V15.5258H10.3096C9.39321 15.5258 9.01941 14.914 9.47675 14.1681L12.6672 11.0233C13.2924 10.3989 13.6881 10.3778 14.3328 11.0233L17.5232 14.1681C17.9823 14.9149 17.6059 15.5258 16.6896 15.5258Z" fill="#2B3674"/>
    </svg>
    
  )
}
