import _ from "lodash";
import Swal from "sweetalert2";
import {
  AUTH_PROCESS_STEPS,
  IMAGE_URL,
  onToast,
  USER_ROLE,
} from "../../core/Constant";
import { Actions } from "../../core/modules/Actions";
import { navigateTo } from "../../core/services/NavigationServicd";
import {
  getToken,
  getUserType,
  setCurrentUserDetails,
  setLoginCount,
  setToken,
  setUserId,
  setUserType,
} from "../../core/UtilMethods";
import { ModuleEvents } from "./Actions";

export default {
  [ModuleEvents.NETWORK_ERROR]: ({ dispatch, payload, appState }) => {
    // Logger.infor(JSON.stringify(payload))
  },
  [ModuleEvents.RECRUITER_SIGN_UP]: async ({ dispatch, payload, appState }) => {
    if (payload && !payload.error && payload.status_code == 1) {
      const formData = _.get(payload, "params", {});
      const email = formData.get("email");
      const password = formData.get("password");
      console.log(payload,'payloaddd')
      if (email && password) {
        console.log(email, password, "dataaaaaa");
        dispatch(Actions.auth.logIn({ email: email, password: password }));
      }
      if (payload.data.access_token) {
        setToken(_.get(payload, "data.access_token", ""));
        setUserId(_.get(payload, "data.user.id", ""));
        setUserType(_.get(payload, "data.user.role_id", ""));
        setCurrentUserDetails(JSON.stringify(_.get(payload, "data.user", "")));
        setLoginCount(_.get(payload, "data.user.login_count", ""));
        dispatch(
          Actions.auth.storeProfilePic({
            path: IMAGE_URL + _.get(payload, "data.user.profile_image"),
          })
        );
        if (getUserType() == 1) {
          navigateTo("/dashboard");
        } else {
          navigateTo("/recruiter_dashboard");
        }
      }

      //  dispatch(Actions.auth.cleanVerifiedEmailData());
    }
    onToast("Sign up", payload, false);
  },
  [ModuleEvents.LOGIN_USER]: ({ dispatch, payload, appState }) => {
    if (
      payload &&
      !payload.error &&
      payload.status_code == USER_ROLE.candidate
    ) {
      setToken(_.get(payload, "data.access_token", ""));
      setUserId(_.get(payload, "data.user.id", ""));
      setUserType(_.get(payload, "data.user.role_id", ""));
      setCurrentUserDetails(JSON.stringify(_.get(payload, "data.user", "")));
      setLoginCount(_.get(payload, "data.user.login_count", ""));
      dispatch(
        Actions.auth.storeProfilePic({
          path: IMAGE_URL + _.get(payload, "data.user.profile_image"),
        })
      );

      if (getToken()) {
        if (getUserType() == 1) {
          navigateTo("/dashboard");
        } else {
          navigateTo("/recruiter_dashboard");
        }
      } else {
        let value = payload;
        let title = "Login Error";
        if (!payload) {
          title = "Network Error";
          value = {
            message: "Please check your internet connection",
            status_code: 0,
          };
        }
        onToast(title, value);
      }
    }else{
      onToast("Authentication", payload,false);
    }
  },
  [ModuleEvents.SIGN_OUT]: ({ dispatch, payload, appState }) => {
    onToast("Sign out", payload, false);
    if (payload && !payload.error && payload.status_code == 1) {
      localStorage.clear();
      navigateTo("/");
    }
  },

  [ModuleEvents.SIGN_UP_COMPLETE]: ({ dispatch, payload, appState }) => {
    onToast("Candidate Registration", payload, false);
    if (payload && !payload.error && payload.status_code == 1) {
      navigateTo("/dashboard");
    }
  },

  [ModuleEvents.REGISTER_CANDIDATE]: ({ dispatch, payload, appState }) => {
    onToast("Register Candidate", payload, false);
    if (payload && payload.status_code == 1) {
      setToken(_.get(payload, "data.access_token"));
      setUserType(_.get(payload, "data.user.role_id"));
      setCurrentUserDetails(_.get(payload, "data.user"));
      setLoginCount(_.get(payload, "data.user.login_count"));
      dispatch(
        Actions.auth.authStep({
          step: AUTH_PROCESS_STEPS.onboarding_skillNinterest_can,
        })
      );
    }
  },

  [ModuleEvents.FORGOT_PASSWORD]: ({ dispatch, payload, appState }) => {
    onToast("Forgot Password", payload, false);
    if (payload && payload.status_code == 1) {
      Swal.fire({
        title: "Reset Password",
        text: "You can reset your Password using the email we sent you",
        icon: "info",
      });
      dispatch(Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signIn }));
    }
  },

  [ModuleEvents.RESET_PASSWORD_COMMON]: ({ dispatch, payload, appState }) => {
    onToast("Reset Password", payload, false);
    if (payload && payload.status_code == 1) {
      console.log(payload);
      // navigateTo("/");
    }
  },

  
  [ModuleEvents.TOKEN_VERIFY]: ({ dispatch, payload, appState }) => {
    if (payload && payload.status_code == 0) {
      onToast("Login session timeout",payload, false);
      localStorage.clear();
      navigateTo("/")
    }
  },

  // [ModuleEvents.STORE_AUTH_STEP]: ({ dispatch, payload, appState }) => {
  //   // console.log("auth Step", appState)
  //   // dispatch(Actions.auth.authStep({ step: _.get(payload,'step') }));

  // },
};

// const onRecruiterLogin = ({ dispatch, payload, appState }) => {
//   navigateTo("/recruiter_dashboard");
//   // navigateTo('/admin-dashboard');
//   // dispatch(Actions.home.getAdminDashboardData());
//   // dispatch(Actions.profile.getUserData());
// };

// const onCadidateLogin = ({ dispatch, payload, appState }) => {
//   // navigateTo('/dashboard');
//   // dispatch(Actions.profile.getUserData());
// };
