import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ButtonComponent, TextFieldComponent } from "../../atoms";
import { CloseButton } from "../../atoms/Buttons/CloseButton";
import { IMAGE_URL } from "../../../core/Constant";

export const AnswerViewModal = ({ isOpen, onClose, onSubmit, data }) => {
  const cancelButtonRef = useRef(null);
  const [conversationTitle, setConversationTitle] = useState("");
  const [errorTitle, setErrorTitle] = useState(false);

  const onTitleChange = (e) => {
    const text = e.target.value;
    setConversationTitle(text);
    setErrorTitle(text.trim().length === 0);
  };

  const handleClear = () => {
    setConversationTitle("");
    setErrorTitle(false);
  };

  const handleSubmit = () => {
    if (conversationTitle.trim()) {
      onSubmit(conversationTitle);
      handleClear(); // Clear form after submission
      onClose(); // Close modal
    } else {
      setErrorTitle(true); // Show error if title is empty
    }
  };

  console.log(data, "dataaaaa");

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black w-full max-w-full md:max-w-lg lg:max-w-2xl xl:max-w-3xl text-left shadow-xl transition-all sm:my-8">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="flex flex-row w-full justify-between bg-bgColor rounded-lg items-center">
                        <span className="text-lableColor font-DM font-[500] ml-5 text-[28px]">
                          Answers
                        </span>
                        <CloseButton onClick={onClose} />
                      </div>

                      <div className="w-full my-8 flex flex-col items-start text-left">
                        {/* Check if data exists */}
                        {data && data.length > 0 ? (
                          // If data is available, map through it and display questions and answers
                          data.map((item, index) => {
                            const mcqAnswerId = item.mcq_answer ? item.mcq_answer[0] : null;

                            // Find the matching option in mcqAnswers
                            const selectedOption = item.question.mcqAnswers?.find(
                              (option) => option.id === mcqAnswerId
                            );

                            // Find the correct answer in mcqAnswers
                            const correctOption = item.question.mcqAnswers?.find(
                              (option) => option.is_correct === true
                            );

                            return (
                              <div key={index} className="mb-4">
                                <h3 className="text-lg font-bold text-gray-800">
                                  Question {index + 1}: {item.question.description}
                                </h3>

                                {/* Check if the question has a video */}
                                {item.question.video_url && (
                                  <div className="mb-4">
                                    <p className="text-gray-600 mb-2">Watch the video:</p>
                                    {/* You can replace this with an <iframe> for YouTube/Vimeo if needed */}
                                    <video
                                      controls
                                      src={IMAGE_URL + item.question.video_url}
                                      className="w-full rounded-lg"
                                    />
                                  </div>
                                )}

                                {/* Show MCQ answer if available, otherwise show text answer */}
                                {item.mcq_answer ? (
                                  <>
                                    <p className="text-gray-600">
                                      Answer:{" "}
                                      {selectedOption
                                        ? selectedOption.option_description
                                        : "No answer selected"}
                                    </p>
                                    <p className="text-gray-600">
                                      Status:{" "}
                                      {selectedOption && selectedOption.is_correct
                                        ? "Correct"
                                        : "Incorrect"}
                                    </p>
                                    <p className="text-gray-600">
                                      Correct Answer:{" "}
                                      {correctOption
                                        ? correctOption.option_description
                                        : "No correct answer available"}
                                    </p>
                                  </>
                                ) : (
                                  <p className="text-gray-600">
                                    Answer: {item.text_answer}
                                  </p>
                                )}

                                <p className="text-gray-500">
                                  Marks: {item.marks}
                                </p>
                              </div>
                            );
                          })
                        ) : (
                          <span className="text-lableColor font-DM font-[500] ml-5 text-[18px]">
                            Answers Not Found
                          </span>
                        )}
                      </div>

                      <div className="mt-2 w-full flex items-center justify-center flex-row gap-3 rounded-[16px]">
                        {/* <ButtonComponent
                          isPrimary={false}
                          text="Clear"
                          onClick={handleClear}
                        />
                        <ButtonComponent
                          text="Create"
                          onClick={handleSubmit}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
