import React, { useEffect, useState } from "react";
import {
  EditProfileForm,
  ProfileDetailCard,
  RecruiterProfileForm,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { getUserType } from "../../../core/UtilMethods";
import { USER_ROLE } from "../../../core/Constant";

export default ({}) => {
  const dispatch = useDispatch();
  const details = useSelector((state) => state.profile.get("candidateDetails"));
  const companydetails = useSelector((state) =>
    state.profile.get("companyDetails")
  );
  const [recruiter, setRecruiter] = useState(false);

  useEffect(() => {
    if (getUserType() == USER_ROLE.recruiter) {
      setRecruiter(true);
      dispatch(Actions.profile.getCompanyDetails());
    } else {
      dispatch(Actions.profile.getCandiateDetails());
    }
  }, [dispatch]);

  return (
    <div className="grid grid-flow-row-dense 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-5 md:grid-cols-6 sm:grid-cols-3 gap-2">
      <div className="xl:col-span-1 lg:col-span-2 md:col-span-3 ">
        <ProfileDetailCard
          recruiter={recruiter}
          details={recruiter ? companydetails?.data : details}
        />
      </div>
      <div className="xl:col-span-2 lg:col-span-3 md:col-span-3 ">
        {recruiter ? (
          <RecruiterProfileForm details={companydetails?.data} />
        ) : (
          <EditProfileForm details={details} />
        )}
      </div>
    </div>
  );
};
