import React, { useEffect, useState } from "react";
import {
  CalenderLayout,
  EditProfileForm,
  ProfileDetailCard,
  ResumeCard,
  SalaryChart,
} from "../../../components";
import { ButtonComponent, DropdownComponent } from "../../../components/atoms";
import {
  AverageSalaryCard,
  DashboardCard,
  InterviewCard,
  PdfUploadCard,
} from "../../../components/molecule/Cards";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import { EventDetailsModal } from "../../../components/molecule/Modals";

export default ({}) => {
  const dispatch = useDispatch();
  const interviews = useSelector((state) =>
    state.home.get("candidateInterviews")
  );
  const [event, setEvents] = useState();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  
  useEffect(() => {
    dispatch(Actions.home.getInterviewsCandidate());
  }, [dispatch]);

  useEffect(() => {
    if (interviews) {
      const events = interviews.map((interview) => ({
        event_date: new Date(interview.date + "T" + interview.time_from),
        event_title: interview.title,
        event_time: interview.time_from.slice(0, 5),
        data: interview,
      }));
      setEvents(events);
    }
  }, [interviews]);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };


  return (
    <div className="subscreen-container">
      <CalenderLayout newevents={event} onEventClick={handleEventClick} />

      {selectedEvent && (
          <EventDetailsModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            eventDetails={selectedEvent.data}
          />
        )}
    </div>
  );
};
