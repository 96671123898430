import { createAction } from "../../core/AppUtils";
import JobRepository from "../../core/repository/JobRepository";

export const ModuleEvents = {
  LOADING_STARTED: "LOADING_STARTED",
  LOADING_FINISHED: "LOADING_FINISHED",
  NETWORK_ERROR: "NETWORK_ERROR",

  SEARCH_RESULTS : 'SEARCH_RESULTS'
  
};

export default {
  networkError: createAction(ModuleEvents.NETWORK_ERROR, (error) => error),
  loadingStarted: createAction(
    ModuleEvents.LOADING_STARTED,
    (action) => action
  ),
  loadingFinished: createAction(
    ModuleEvents.LOADING_FINISHED,
    (action) => action
  ),

  searchResults: createAction(
    ModuleEvents.SEARCH_RESULTS,
    async (param) => {
      return await JobRepository.searchJobs(param);
    }
  ),

  
 
};
