

import Repository from "./Repository";
import _ from "lodash";

const getPath = (path)=>{
    return `recruiter/${path}`;
}
class CommonRepository extends Repository {
    jobRoles = async () => {
        try {
          const data = await this.getData(getPath(`filterinterestedjobroles`));
          return _.get(data, `data`);
        } catch (error) {
          return { error };
        }
      };

      industryList = async () => {
        try {
          const data = await this.getData('industry/activeList');
          return _.get(data, `data`);
        } catch (error) {
          return { error };
        }
      };

      skillsList = async () => {
        try {
          const data = await this.getData('skill/list');
          return _.get(data, `data`);
        } catch (error) {
          return { error };
        }
      };

      getAllJobTitlesList = async () => {
        try {
          const data = await this.getData('jobpost/getalljobtitlelist');
          return _.get(data, `data`);
        } catch (error) {
          return { error };
        }
      };

      getAllJobLocationList = async () => {
        try {
          const data = await this.getData(getPath(`getalljoblocationlist`));
          return _.get(data, `data`);
        } catch (error) {
          return { error };
        }
      };


      getindustryList = async (params) =>{
        try {
            const data = await this.getData(`industry/activeList`, params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }
    getskillList= async (params) =>{
        try {
            const data = await this.getData(`skill/list`, params);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }

    getjobTitleList = async () =>{
        try {
            const data = await this.getData(`recruiter/job-titles`);
            return _.get(data, `data`);
        } catch (error) {
            return { error }
        }
    }

    getJobRoleList = async () =>{
      try {
          const data = await this.getData(`jobpost/filterinterestedjobroles`);
          return _.get(data, `data`);
      } catch (error) {
          return { error }
      }
  }

}
export default new CommonRepository("common");
