import { Avatar } from "@material-tailwind/react";
import React, { useState, useEffect } from "react";

const CalendarLayout = ({newevents =[],onEventClick}) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [events, setEvents] = useState([
        {
            event_date: new Date(2024, 4, 27, 10), // Include time (hour)
            event_title: "April Fool's Day",
            event_time: "10.00"
        },
        {
            event_date: new Date(2020, 3, 10, 14), // Include time (hour)
            event_title: "Birthday",
            event_time: "10.00"
        },
        {
            event_date: new Date(2020, 3, 16, 16), // Include time (hour)
            event_title: "Upcoming Event",
            event_theme: "green"
        }
    ]);
    const [openEventModal, setOpenEventModal] = useState(false);

    const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const isToday = (date) => {
        const today = new Date();
        const d = new Date(date);
        return today.toDateString() === d.toDateString();
    };

    const showEventModal = (date) => {
        setOpenEventModal(true);
    };

    const addEvent = () => {
        // Implement your add event logic here
    };

    useEffect(() => {
        // Implement your week start date logic here
    }, [currentDate]);

    const formatAMPM = (hours) => {
        let ampm = hours >= 12 ? 'PM' : 'AM';
        let formattedHours = hours % 12;
        formattedHours = formattedHours ? formattedHours : 12; // the hour '0' should be '12'
        return formattedHours + ' ' + ampm;
    };

    const handlePreviousWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() - 7);
        setCurrentDate(newDate);
    };

    const handleNextWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() + 7);
        setCurrentDate(newDate);
    };

    const getDatesForWeek = () => {
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        const dates = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(startOfWeek);
            date.setDate(date.getDate() + i);
            dates.push(date);
        }
        return dates;
    };

    return (
        <div className="w-full">
        <div className="bg-white rounded-lg">
          <div className="flex items-center gap-1 p-3">
            <button
              onClick={handlePreviousWeek}
              className="bg-ash hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Previous Week
            </button>
  
            <button
              onClick={handleNextWeek}
              className="bg-ash hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Next Week
            </button>
          </div>
  
          <div className="w-full overflow-hidden">
            <table className="w-full table-fixed">
              <thead>
                <tr>
                  <th className="w-[70px]"></th>
                  {getDatesForWeek().map((date, index) => (
                    <th
                      key={index}
                      className={`w-[100px] border border-t-0 border-l-0 border-lightgray ${
                        isToday(date) ? "bg-liteGray2 text-white" : ""
                      } ${
                        date.getDay() === 0 || date.getDay() === 6
                          ? "bg-lighterGray"
                          : ""
                      } ${index === getDatesForWeek().length - 1 ? "border-r-0" : ""}`}
                    >
                      <div className="flex flex-col items-start pl-2">
                        <span className="text-gray-500 font-DM text-[9px] font-[700]">
                          {DAYS[date.getDay()]}
                        </span>
                        <span className="text-black font-DM text-[18px] font-[600]">
                          {date.getDate()}
                        </span>
                      </div>
                    </th>
                  ))}
                  <th className="w-[70px]"></th>
                </tr>
              </thead>
            </table>
          </div>
  
          <div className="overflow-y-scroll h-[500px]"> 
            <table className="w-full table-fixed"> 
              <tbody>
                {[...Array(24).keys()].map((hour) => (
                  <tr key={hour}>
                    <td className="w-[70px] text-right text-gray-500 font-DM text-[10px] font-[600] pr-2">
                      {formatAMPM(hour)}
                    </td>
                    {getDatesForWeek().map((date, index) => {
                      const event = newevents.find((event) => {
                        const eventDate = new Date(event.event_date);
                        return (
                          eventDate.toDateString() === date.toDateString() &&
                          eventDate.getHours() === hour
                        );
                      });
                      return (
                        <td
                          key={index}
                          className={`w-[100px] text-right h-[62px] ${
                            isToday(date) ? "bg-liteGray2 text-white" : ""
                          } border p-4 border-t-0 border-l-0 border-lightgray ${
                            date.getDay() === 0 || date.getDay() === 6
                              ? "bg-lighterGray"
                              : ""
                          } ${index === getDatesForWeek().length - 1 ? "border-r-0" : ""}`}
                          style={{ position: "relative" }}
                        >
                          {event && (
                            <div
                              className="absolute top-0 left-0 right-0 bottom-0 p-1 text-xs font-bold bg-primaryColor text-black flex flex-col items-start rounded-lg"
                              style={{ height: "100%" }}
                              onClick={() => onEventClick(event)}
                            >
                              <div className="flex flex-row items-center">
                                <span className="font-[500] font-DM text-white text-[8px]">
                                  {event.event_time}
                                </span>
                                <Avatar
                                  style={{
                                    width: "14px",
                                    height: "14px",
                                    marginLeft: "10px",
                                  }}
                                  src="https://docs.material-tailwind.com/img/face-5.jpg"
                                  alt="avatar"
                                />
                              </div>
                              <span className="font-[500] font-DM text-white text-[8px]">
                                {event.event_title}
                              </span>
                            </div>
                          )}
                        </td>
                      );
                    })}
                    <td className="w-[70px] text-gray-500 font-DM text-[10px] font-[600] pl-2">
                      {formatAMPM(hour)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
};

export default CalendarLayout;
