import React, { useEffect, useState } from "react";
import {
  ButtonComponent,
  HeadingComponent,
  TextFieldComponent,
} from "../../atoms";
import { useDispatch } from "react-redux";
import { Actions } from "../../../core/modules/Actions";
import {
  AUTH_PROCESS_STEPS,
  EMAIL_REGEX,
  getText,
} from "../../../core/Constant";
import { useNavigate } from "react-router-dom";

export const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {}, [dispatch]);

  const onEmailChange = (e) => {
    const text = getText(e);
    let isValid = false;
    const reg = EMAIL_REGEX;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setEmail(text);
    setIsEmailValid(isValid);
    setEmailError(isValid ? false : true);
  };

  const handleOnClick = () => {
    dispatch(Actions.auth.authStep({ step: AUTH_PROCESS_STEPS.signIn }));
  };

  const onLogin = () => {
    if (isEmailValid) {
      dispatch(Actions.auth.forgotPassword({ email: email }));
    } else {
      if (!isEmailValid) {
        setEmailError(true);
      }
    }
  };
  return (
    <div className="sign-in-form-container">
      <HeadingComponent
        headingText={"Forgot Password"}
        description={"Enter your email to reset Password"}
      />
      <div className="sign-in-content-wrapper">
        <TextFieldComponent
          type="text"
          label="Email"
          placeholder="Enter your email address"
          value={email}
          onChange={onEmailChange}
          error={emailError}
          errortxt="Please enter a valid email"
        />
      </div>
      <div className="submit-button">
        <ButtonComponent
          onClick={onLogin}
          text="Submit"
          width="w-1/3"
          isPrimary={true}
        />
      </div>
      <div>
        <p className="font-DM font-medium text-lableColor">
          Already have an Account?
          <span
            className="text-primaryColor font-semibold cursor-pointer ps-1"
            onClick={handleOnClick}
          >
            Login
          </span>
        </p>
      </div>
    </div>
  );
};
